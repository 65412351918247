import axios from 'axios';
import * as jsCookie from 'js-cookie';

import { Config } from '.';
import { getUserToken } from './utils/token-utils';

type LoginType = 'tokenLogin' | 'fbLogin' | 'emailLogin' | null;

interface Credentials {
    token?: string;
    facebook_token?: string;
    email?: string;
    password?: string;
}

const UserAuth = {
    isAuth: () => !!jsCookie.get('user'),
    login: async (credentials: Credentials) => {
        const nonTokenLogin = credentials.facebook_token ? 'fbLogin' : 'emailLogin';
        let loginType: LoginType = credentials.token ? 'tokenLogin' : null;
        loginType = loginType ? loginType : nonTokenLogin;
        try {
            return await UserAuth[loginType](credentials);
        } catch (e) {
            return Promise.reject(e);
        }
    },
    emailLogin: async (loginData: Credentials) => {
        if (loginData.email && loginData.password) {
            return axios.put(`${Config.get('api-gw')}login/email`, {
                client: `${Config.isMobile() ? 'mobile-' : ''}web`,
                email: loginData.email,
                password: loginData.password
            });
        }
    },
    tokenLogin: async ({ token }: Credentials) =>
        await axios.put(`${Config.get('api-gw')}login/token`, { token }),
    getToken: async () =>
        await axios.get(
            `${Config.get('apiroot2')}user/getToken/${Config.isMobile() ? 'mobile-' : ''}web`
        ),
    resetPassword: async (email: Credentials) =>
        await axios.post(
            `${Config.get('apiroot2')}user/resetPassword`,
            { email },
            { headers: { token: getUserToken() } }
        ),
    signup: async (credentials: Credentials) => {
        const loginType = credentials.facebook_token ? 'fbLogin' : 'emailSignup';
        try {
            return UserAuth[loginType](credentials);
        } catch (e) {
            return Promise.reject(e);
        }
    },
    emailSignup: async (credentials: Credentials) =>
        await axios.post(`${Config.get('api-gw')}signup/email`, credentials),
    fbLogin: async (credentials: Credentials) =>
        await axios.post(`${Config.get('api-gw')}login/facebook`, credentials)
};

export default UserAuth;
