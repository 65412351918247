import './style.scss';

import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { Loader, StepsProgressBar } from '../../components';
import { Question } from '.';
import { trackEvent, isMatchedStylist, MIXPANEL_EVENTS } from 'services/mixpanel';
import { useCommonStore } from 'store/common/reducer';
import { Client, Stylist } from 'types/user';
import { QuizQuestion } from 'types/utils';

interface IQuiz {
    user: Client;
    prevQuestion: () => void;
    questions: QuizQuestion[];
    stylist: Stylist;
    session_id: string;
    loading: boolean;
    price: number;
}

const Quiz: React.FC<IQuiz> = ({
    prevQuestion,
    questions,
    stylist,
    session_id,
    loading,
    user,
    price
}) => {
    const history = useHistory();
    const [question, setQuestion] = useState<QuizQuestion>();
    const matches = useCommonStore((store) => store.stylistsMatchs);

    useEffect(() => {
        if (!stylist?.uuid || !session_id) history.push('/');

        if (user) {
            trackEvent({
                name: MIXPANEL_EVENTS.STARTED_A_QUIZ,
                properties: {
                    'User UUID': user.user_uuid,
                    'User email': user.email,
                    'Stylist name': stylist.name,
                    'Stylist UUID': stylist.uuid,
                    'Is stylist match': isMatchedStylist(matches, stylist.uuid),
                    'Session count': user.bookings_count,
                    'Session status': 'Ongoing',
                    'Session UUID': session_id,
                    'Payment amount': price,
                    'Session amount': price
                }
            });
        }
    }, []);

    useEffect(() => {
        if (questions.length) {
            const question = [...questions].pop();
            setQuestion(question);
        }
    }, [questions]);

    return (
        <div className="quiz">
            {loading && <Loader />}
            {question?.type && (
                <StepsProgressBar
                    max={question.quiz_steps_count - 1}
                    current={question.current_quiz_step}
                    label={true}
                    onBack={() => {
                        if (question.type === 'client_reference') {
                            history.push('/stylistSearch');
                        } else {
                            prevQuestion();
                        }
                    }}
                />
            )}

            <Question />
        </div>
    );
};

export default Quiz;
