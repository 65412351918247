import './style.scss';

import { useIsMobile } from 'hooks';
import React, { useEffect } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { Elements, StripeProvider } from 'react-stripe-elements';

import { StripeForm } from '../../../components';
import { giftcards } from '../../../content';
import { Payments, Tracking } from '../../../services';
import Summary from './Summary';

const CheckoutForm = ({ giftcard, user, getUserCards, payGiftCard }) => {
    const isMobile = useIsMobile();
    useEffect(() => {
        Tracking.tag({
            event: 'eec.checkout',
            ecommerce: {
                checkout: {
                    actionField: { step: 1 },
                    products: [
                        {
                            name: 'Gift Card',
                            category: '/category/gift-card/',
                            variant: `Gift Card - ${giftcard.amount}`,
                            quantity: 1,
                            dimension3: 'Ecommerce'
                        }
                    ]
                }
            }
        });
    }, [giftcard]);

    useEffect(() => {
        getUserCards();
    }, [getUserCards]);

    return (
        <Container className="checkout-form" fluid>
            <Row>
                <Col>
                    <p className="title">{giftcards.checkout.details}</p>
                </Col>
            </Row>
            <Row className="d-flex d-sm-none">
                <Col>
                    <Summary giftcard={giftcard} />
                </Col>
            </Row>
            <Row>
                <Col>
                    <StripeProvider apiKey={Payments.getStripeKey()}>
                        <Elements>
                            <StripeForm
                                cardholder={{
                                    first_name: giftcard.senderName
                                        ? giftcard.senderName.split(' ')[0]
                                        : null,
                                    last_name: giftcard.senderName
                                        ? giftcard.senderName.split(' ')[1]
                                        : null
                                }}
                                onSubmit={(token, captchaToken) =>
                                    payGiftCard({ recaptcha_token: captchaToken })
                                }
                                bottomText=""
                                disclaimer={true}
                                disclaimerText={giftcards.checkout.disclaimer}
                                buttonText={giftcards.checkout.button}
                                showName={!isMobile}
                                stripeButton={false}
                                recaptcha={true}
                                checkCoupon={false}
                            />
                        </Elements>
                    </StripeProvider>
                </Col>
            </Row>
        </Container>
    );
};

export default CheckoutForm;
