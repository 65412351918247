import './style.scss';

import React, { useEffect, useRef, useState } from 'react';
import { Badge, Col, Container, Image, Row } from 'react-bootstrap';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useHistory, useLocation } from 'react-router-dom';

import { Filters, Loader, ToggleButton } from '../../../components';
import { profile as texts } from '../../../content';
import { Formatter } from '../../../services';
import Empty from './Empty';

export default ({
    loading,
    loadingFilters,
    user,
    looks,
    looksTotalItems,
    gridSize,
    totalItems,
    loadLooks,
    loadLooksFilters,
    filters,
    selectedFilters,
    updateFilters,
    error,
    toggleModal
}) => {
    const [subcategory, setSubcategory] = useState();
    const resultsRef = useRef();
    const history = useHistory();
    const location = useLocation();

    useEffect(() => {
        const type = Formatter.queryString(location.search).type;
        setSubcategory(type || 'look');
    }, [location]);

    useEffect(() => {
        if (user) {
            loadLooksFilters();
            fetchData({});
        }
    }, [subcategory, user]);

    useEffect(() => {
        if (error && error.profile)
            toggleModal({ type: 'Error', data: { error: texts.errors.looks } });
    }, [error]);

    const fetchData = (filters) => {
        const activeFilters = filters ? filters : selectedFilters;
        const params = Object.keys(activeFilters).reduce(
            (ac, a) =>
                activeFilters[a].length
                    ? {
                          ...ac,
                          [a]: activeFilters[a].map((selection) => selection.key).join(',')
                      }
                    : ac,
            {}
        );
        const pageSize = 50;
        const page = !filters && looks ? Math.floor(looks.length / pageSize) + 1 : 1;
        loadLooks({ ...params, from: page, type: subcategory });
    };

    const onFilterChange = (filter) => {
        const isSelected =
            selectedFilters[filter.key] &&
            selectedFilters[filter.key].findIndex((item) => item.key === filter.value.key) > -1;
        updateFilters(filter, isSelected, fetchData);
    };

    const onRemove = (look) =>
        toggleModal({ type: 'RemoveLook', data: { look, type: subcategory } });

    return (
        <Container className="profile-looks" fluid>
            <Row>
                <Col className="filters-column d-none d-md-block">
                    {loadingFilters && <Loader />}
                    <p className="total">{texts.looks.replace('%count%', looksTotalItems)}</p>
                    <Filters
                        className={
                            selectedFilters.category && !selectedFilters.category.length
                                ? 'categories-all'
                                : ''
                        }
                        filters={filters}
                        selected={selectedFilters}
                        setFilter={onFilterChange}
                        showSelection={true}
                    />
                </Col>

                <Col id="results" className="results" ref={resultsRef}>
                    {loading && <Loader />}
                    <div className="results-bar">
                        <ToggleButton
                            className="d-block d-md-none"
                            toggles={texts.subcategories.looks}
                            onChange={(category) =>
                                history.push({
                                    pathname: '/profile/looks',
                                    search: `?type=${category}`
                                })
                            }
                        />
                        <div className="subcategories d-none d-md-flex">
                            {texts.subcategories.looks.map((category) => (
                                <p
                                    key={category.value}
                                    className={`looks-${category.value} ${
                                        category.value === subcategory ? 'selected' : ''
                                    }`}
                                    onClick={() =>
                                        history.push({
                                            pathname: '/profile/looks',
                                            search: `?type=${category.value}`
                                        })
                                    }
                                >
                                    {category.text}
                                </p>
                            ))}
                        </div>

                        <div className="filter-badges">
                            {Object.keys(selectedFilters).map((key) =>
                                selectedFilters[key].map((filter) => (
                                    <Badge variant="light" key={filter.key}>
                                        <div
                                            className="close"
                                            onClick={() => onFilterChange({ key, value: filter })}
                                        />
                                        {filter.text}
                                    </Badge>
                                ))
                            )}
                        </div>
                    </div>
                    {looks && (
                        <span>
                            {looks.length ? (
                                <InfiniteScroll
                                    dataLength={looks.length}
                                    next={fetchData}
                                    hasMore={looks.length < looksTotalItems}
                                    scrollableTarget="results"
                                >
                                    {looks.map((look) => (
                                        <div key={look.uuid} className={`board ${gridSize}`}>
                                            <div
                                                className="remove"
                                                onClick={() => onRemove(look)}
                                            />
                                            <p>{look.outfit_name}</p>
                                            <Image
                                                src={look.picture}
                                                onClick={() =>
                                                    history.push({
                                                        pathname: `/feed/outfit/${look.uuid}`,
                                                        state: {
                                                            back: '/profile/looks',
                                                            text: texts.collectionBack
                                                        }
                                                    })
                                                }
                                                alt=""
                                            />
                                        </div>
                                    ))}
                                </InfiniteScroll>
                            ) : (
                                <Empty
                                    section={
                                        subcategory === 'look' ? 'style boards' : 'mood boards'
                                    }
                                />
                            )}
                        </span>
                    )}
                </Col>
            </Row>
        </Container>
    );
};
