import './style.scss';

import { useLDClient, withLDConsumer } from 'launchdarkly-react-client-sdk';
import React, { useEffect, useRef, useState } from 'react';
import { Badge, Button, Col, Form, Modal } from 'react-bootstrap';
import ReCAPTCHA from 'react-google-recaptcha';
import { useHistory } from 'react-router-dom';
import { ErrorState } from 'store/error/types';
import { Client } from 'types/user';
import { BodyType, Brand, OnBoarding, ValidationError } from 'types/utils';

import { modal } from '../../../content.json';
import { Validator } from '../../../services';
import { extractUserData } from '../../../services/utils/user-utils';
import { FacebookButton } from '../..';

interface ISignup {
    changeModal: (type: string) => void;
    signup: (credentials: any) => void;
    loading: boolean;
    error: ErrorState;
    modalType: string;
    user: Client;
    onboarding: OnBoarding;
    redirectUrl: string | null;
    isFacebookEnabled?: boolean;
}

const Signup: React.FC<ISignup> = ({
    changeModal,
    signup,
    loading,
    error,
    modalType,
    user,
    onboarding,
    isFacebookEnabled = false,
    redirectUrl
}) => {
    const history = useHistory<any>();
    const [domain, setDomain] = useState('');
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [errors, setErrors] = useState<ValidationError>({});
    const [captcha, setCaptcha] = useState<any>();
    const texts = modal.signup;
    let recaptchaRef = useRef<any>();
    const ldClient = useLDClient();

    useEffect(() => {
        if (domain) {
            const index = email.indexOf('@');
            setEmail(`${email.substr(0, index > -1 ? index : email.length)}${domain}`);
            setDomain('');
        }
        if (user && user.new_user && ldClient) {
            const previousLDUser = ldClient.getUser();
            const userDetails = extractUserData(user as Client);
            if (userDetails && ldClient) ldClient.alias(userDetails, previousLDUser);
            const path = redirectUrl ? redirectUrl : getDefaultRedirectUrl();

            if (
                history.location.state !== undefined &&
                history.location.state.from &&
                history.location.state.currentPath
            ) {
                if (
                    history.location.state.from !== path &&
                    history.location.state.currentPath !== path
                ) {
                    history.push({
                        pathname: path,
                        state: {
                            from: history.location.pathname,
                            currentPath: path
                        }
                    });
                }
            } else {
                history.push({
                    pathname: path,
                    state: {
                        from: history.location.pathname,
                        currentPath: path
                    }
                });
            }
        }
    }, [domain, email, user, redirectUrl, history, ldClient]);

    useEffect(() => {
        if (error) {
            setCaptcha(null);
            recaptchaRef.current.reset();
        }
    }, [error]);

    useEffect(() => {
        error.message = '';
        if (error.errors[0]?.field === 'email') error.errors[0] = {};
    }, []);

    const getDefaultRedirectUrl = () => {
        switch (history.location.pathname) {
            case '/onboarding/brands':
                return '/stylistSearch';
            case '/feed':
                return '/feed';
            case '/gifts/shopping':
                return '/gifts/shopping';
            default:
                return '/onboarding';
        }
    };

    const validate = () => {
        let errors = {};

        errors = {
            ...Validator.name(name),
            ...Validator.email(email),
            ...Validator.password(password)
        };

        if (Object.keys(errors).length) {
            setErrors(errors);
            return false;
        } else {
            setErrors({});
            return true;
        }
    };

    const validateOnBlur = (field: string, value: string) => {
        let errors = {};
        switch (field) {
            case 'name':
                errors = { ...Validator.name(value) };
                if (Object.keys(errors).length === 0) errors = { name: null, lastname: null };
                break;
            case 'email':
                errors = { ...Validator.email(value) };
                if (Object.keys(errors).length === 0) errors = { email: null };
                break;
            case 'password':
                errors = { ...Validator.password(value) };
                if (Object.keys(errors).length === 0) errors = { password: null };
                break;
        }

        setErrors((preErrors: ValidationError) => ({ ...preErrors, ...errors }));
    };

    const onCaptcha = (value: any) => setCaptcha(value);

    const submit = (fbData?: any) => {
        let credentials = fbData
            ? fbData
            : {
                  email,
                  full_name: name,
                  first_name: name.split(' ')[0],
                  last_name: name.split(' ')[1],
                  password,
                  recaptcha_token: captcha
              };

        if (modalType === 'Unlock') {
            const { gender, styles, brands, bodyTypes } = onboarding;
            credentials = {
                ...credentials,
                gender,
                styles,
                brands: brands.map((brand: Brand) => brand.uuid).join(','),
                tags: bodyTypes.map((type: BodyType) => type.tag_uuid).join(',')
            };
        }
        signup(credentials);
    };

    return (
        <span className="signup-modal">
            <Modal.Header closeButton={true}>
                <Modal.Title>{modalType === 'Unlock' ? texts.unlock : texts.title}</Modal.Title>
                {modalType === 'Unlock' && <p className="modal-subtitle">{texts.subtitle}</p>}
            </Modal.Header>

            <Modal.Body>
                <Form
                    onSubmit={(e: React.FormEvent<HTMLFormElement>) => {
                        e.preventDefault();
                        if (validate()) {
                            submit();
                        }
                    }}
                >
                    <Form.Row>
                        <Form.Group as={Col} controlId="name">
                            <Form.Label column={false}>{texts.name}</Form.Label>
                            <Form.Control
                                data-test-id="signup-name"
                                type="text"
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                    setName(e.target.value)
                                }
                                onBlur={(e: React.FocusEvent<HTMLInputElement>) =>
                                    validateOnBlur('name', e.target.value)
                                }
                                required
                            />
                            {(errors.name || errors.lastname) && (
                                <Form.Control.Feedback type="invalid">
                                    {errors.name || errors.lastname}
                                </Form.Control.Feedback>
                            )}

                            {error.errors[0]?.field === 'name' && (
                                <Form.Control.Feedback type="invalid">
                                    {error.errors[0]?.message}
                                </Form.Control.Feedback>
                            )}
                        </Form.Group>
                    </Form.Row>

                    <Form.Row>
                        <Form.Group as={Col} controlId="email">
                            <Form.Label column={false}>{texts.email}</Form.Label>
                            <Form.Control
                                data-test-id="signup-email"
                                type="email"
                                value={email}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                    setEmail(e.target.value)
                                }
                                onBlur={(e: React.FocusEvent<HTMLInputElement>) =>
                                    validateOnBlur('email', e.target.value)
                                }
                                required
                            />
                            {errors.email && (
                                <Form.Control.Feedback type="invalid">
                                    {errors.email}
                                </Form.Control.Feedback>
                            )}
                            {error.errors[0]?.field === 'email' && (
                                <Form.Control.Feedback type="invalid">
                                    {error.errors[0]?.message}
                                </Form.Control.Feedback>
                            )}
                        </Form.Group>
                    </Form.Row>

                    <Form.Row className="email-domains">
                        <Badge variant="secondary" onClick={() => setDomain('@gmail.com')}>
                            @gmail.com
                        </Badge>
                        <Badge variant="secondary" onClick={() => setDomain('@yahoo.com')}>
                            @yahoo.com
                        </Badge>
                        <Badge variant="secondary" onClick={() => setDomain('@hotmail.com')}>
                            @hotmail.com
                        </Badge>
                        <Badge variant="secondary" onClick={() => setDomain('@icloud.com')}>
                            @icloud.com
                        </Badge>
                    </Form.Row>

                    <Form.Row>
                        <Form.Group as={Col} controlId="password">
                            <Form.Label column={false}>{texts.password}</Form.Label>
                            <Form.Control
                                data-test-id="signup-password"
                                type="password"
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                    setPassword(e.target.value)
                                }
                                onBlur={(e: React.FocusEvent<HTMLInputElement>) =>
                                    validateOnBlur('password', e.target.value)
                                }
                                required
                            />
                            {errors.password && (
                                <Form.Control.Feedback type="invalid">
                                    {errors.password}
                                </Form.Control.Feedback>
                            )}
                            {error.errors[0]?.field === 'password' && (
                                <Form.Control.Feedback type="invalid">
                                    {error.errors[0]?.message}
                                </Form.Control.Feedback>
                            )}
                        </Form.Group>
                    </Form.Row>
                    {error && error.message && (
                        <Form.Control.Feedback
                            type="invalid"
                            style={{ marginTop: '-10px', marginBottom: 5 }}
                        >
                            {error.message}
                        </Form.Control.Feedback>
                    )}

                    <div className="recaptcha">
                        <ReCAPTCHA
                            ref={recaptchaRef}
                            sitekey={process.env.REACT_APP_GOOGLE_RECAPTCHA_KEY as string}
                            onChange={onCaptcha}
                        />
                    </div>

                    <Form.Row>
                        <p className="terms" dangerouslySetInnerHTML={{ __html: texts.terms }} />
                        <Button
                            variant="dark"
                            id="submit"
                            type="submit"
                            className="submit-btn"
                            data-test-id="signup-button"
                            disabled={
                                !name ||
                                !email ||
                                !password ||
                                (process.env.REACT_APP_ENV === 'production' && !captcha) ||
                                loading
                            }
                        >
                            {texts.signup}
                        </Button>
                    </Form.Row>
                    {isFacebookEnabled && (
                        <>
                            <Form.Row className="btns-divider">or</Form.Row>

                            <Form.Row>
                                <FacebookButton
                                    // @ts-ignore
                                    onDone={(data: any) => submit(data)}
                                />
                            </Form.Row>
                        </>
                    )}
                </Form>
            </Modal.Body>

            <Modal.Footer>
                <Form.Text className="join">
                    {texts.account}{' '}
                    <strong onClick={() => changeModal('Signin')}>{texts.signin}</strong>
                </Form.Text>
            </Modal.Footer>
        </span>
    );
};

export default withLDConsumer()(Signup);
