import { isEmpty } from 'lodash';
import { Cookies, User } from 'services';
import { MIXPANEL_EVENTS, trackEvent } from 'services/mixpanel';
import { sentryException } from 'services/SentryLogging';
import { toggleModal } from 'store/ui/actions';
import { logout } from 'store/user/actions';

export const START_LOADING = 'START_LOADING';
export const STOP_LOADING = 'STOP_LOADING';

export const LOGIN_START = 'LOGIN_START';
export const UPDATE_USER = 'UPDATE_USER';

export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS';

export const SIGNUP_START = 'SIGNUP_START';

export const REFRESH_USER_DATA = 'REFRESH_USER_DATA';

export const TOGGLE_MODAL = 'TOGGLE_MODAL';
export const CHANGE_MODAL = 'CHANGE_MODAL';
export const CHOSEN_ITEM = 'CHOSEN_ITEM';
export const LOAD_FAVORITE_LOOKS_START = 'LOAD_FAVORITE_LOOKS_START';
export const LOAD_FAVORITE_LOOKS_SUCCESS = 'LOAD_FAVORITE_LOOKS_SUCCESS';
export const ADD_FAVORITE_LOOK_SUCCESS = 'ADD_FAVORITE_LOOK_SUCCESS';
export const REMOVE_FAVORITE_LOOK_SUCCESS = 'REMOVE_FAVORITE_LOOK_SUCCESS';
export const CLEAR_FAVORITE_LOOKS = 'CLEAR_FAVORITE_LOOKS';

export const LOAD_FAVORITE_STYLISTS_START = 'LOAD_FAVORITE_STYLISTS_START';
export const LOAD_FAVORITE_STYLISTS_SUCCESS = 'LOAD_FAVORITE_STYLISTS_SUCCESS';
export const ADD_FAVORITE_STYLIST_SUCCESS = 'ADD_FAVORITE_STYLIST_SUCCESS';
export const REMOVE_FAVORITE_STYLIST_SUCCESS = 'REMOVE_FAVORITE_STYLIST_SUCCESS';

export const CLEAR_CART = 'CLEAR_CART';
export const LOAD_CART_START = 'LOAD_CART_START';
export const LOAD_CART_SUCCESS = 'LOAD_CART_SUCCESS';
export const ADD_TO_CART = 'ADD_TO_CART';
export const ADD_TO_CLOSET = 'ADD_TO_CLOSET';
export const REMOVE_FROM_CART = 'REMOVE_FROM_CART';
export const SET_CART_FILTER = 'SET_CART_FILTER';
export const SET_CART_FILTERS = 'SET_CART_FILTERS';
export const CLEAR_CART_FILTER = 'CLEAR_CART_FILTER';

export const SELECT_CHANNEL = 'SELECT_CHANNEL';

export const TOGGLE_BIRTHDAY_MESSAGE = 'TOGGLE_BIRTHDAY_MESSAGE';

export const UPDATE_BILLING_DETAILS = 'UPDATE_BILLING_DETAILS';

export const UPDATE_ORDERS = 'UPDATE_ORDERS';

export const UPDATE_USER_CLOSET = 'UPDATE_USER_CLOSET';
export const CLEAR_USER_CLOSET = 'CLEAR_USER_CLOSET';
export const UPDATE_PROFILE_FILTERS = 'UPDATE_PROFILE_FILTERS';
export const UPDATE_PROFILE_SELECTED_FILTERS = 'UPDATE_PROFILE_SELECTED_FILTERS';
export const CLEAR_PROFILE_SELECTED_FILTERS = 'CLEAR_PROFILE_SELECTED_FILTERS';
export const START_FILTERS_LOADING = 'START_FILTERS_LOADING';
export const UPDATE_USER_PHOTOS = 'UPDATE_USER_PHOTOS';
export const UPDATE_USER_LOOKS = 'UPDATE_USER_LOOKS';
export const UPDATE_USER_ORDERS = 'UPDATE_USER_ORDERS';
export const CLEAR_USER_PHOTOS = 'CLEAR_USER_PHOTOS';
export const UPDATE_CLOSET_TAGS = 'UPDATE_CLOSET_TAGS';
export const PAGE_LOADED = 'PAGE_LOADED';
export const SET_CURRENT_OUTFIT = 'SET_CURRENT_OUTFIT';
export const SET_CURRENT_ITEM = 'SET_CURRENT_ITEM';
export const startLoading = () => ({
    type: START_LOADING
});

export const startFiltersLoading = () => ({
    type: START_FILTERS_LOADING
});

export const stopLoading = (error) => ({
    type: STOP_LOADING,
    payload: { error }
});

export const refreshUserData = (user) => ({
    type: REFRESH_USER_DATA,
    payload: { user }
});

export const loadFavoriteLooksStart = () => ({
    type: LOAD_FAVORITE_LOOKS_START
});

export const loadFavoriteLooksSuccess = (favorites) => ({
    type: LOAD_FAVORITE_LOOKS_SUCCESS,
    payload: { favorites }
});

export const addFavoriteLookSuccess = (outfit) => ({
    type: ADD_FAVORITE_LOOK_SUCCESS,
    payload: { outfit }
});

export const removeFavoriteLookSuccess = (outfit) => ({
    type: REMOVE_FAVORITE_LOOK_SUCCESS,
    payload: { outfit }
});

export const clearFavoriteLooks = () => ({
    type: CLEAR_FAVORITE_LOOKS
});

export const removeFavoriteStylistSuccess = (stylist) => ({
    type: REMOVE_FAVORITE_STYLIST_SUCCESS,
    payload: { stylist }
});

export const addFavoriteStylistSuccess = (stylist) => ({
    type: ADD_FAVORITE_STYLIST_SUCCESS,
    payload: { stylist }
});

export const clearCart = () => ({
    type: CLEAR_CART
});

export const loadCartStart = () => ({
    type: LOAD_CART_START
});

export const loadCartSuccess = (payload) => ({
    type: LOAD_CART_SUCCESS,
    payload
});

export const setCartFilter = (filter) => ({
    type: SET_CART_FILTER,
    payload: { filter }
});

export const setCartFilters = (filters) => ({
    type: SET_CART_FILTERS,
    payload: { filters }
});

export const clearCartFilter = () => ({
    type: CLEAR_CART_FILTER
});

const addCart = (item) => ({
    type: ADD_TO_CART,
    payload: { item }
});

const removeCart = (item) => ({
    type: REMOVE_FROM_CART,
    payload: { item }
});

export const addCloset = (item) => ({
    type: ADD_TO_CLOSET,
    payload: { item }
});

export const toggleBirthdaMessage = () => ({
    type: TOGGLE_BIRTHDAY_MESSAGE
});

export const updateBillingDetails = (billingDetails) => ({
    type: UPDATE_BILLING_DETAILS,
    payload: { billingDetails }
});

export const updateOrders = ({ items, quota_max, nextPage }) => ({
    type: UPDATE_ORDERS,
    payload: { orders: items, total: quota_max, nextPage }
});

export const clearCloset = () => ({
    type: CLEAR_USER_CLOSET
});

export const updateCloset = (items, total) => ({
    type: UPDATE_USER_CLOSET,
    payload: { items, total }
});

export const updatePhotos = (photos, total) => ({
    type: UPDATE_USER_PHOTOS,
    payload: { photos, total }
});

export const clearPhotos = () => ({
    type: CLEAR_USER_PHOTOS
});

export const updateProfileFilters = (filters, type) => ({
    type: UPDATE_PROFILE_FILTERS,
    payload: { filters, type }
});

export const clearProfileSelectedFilters = () => ({
    type: CLEAR_PROFILE_SELECTED_FILTERS
});

export const updateLooks = (items, total) => ({
    type: UPDATE_USER_LOOKS,
    payload: { items, total }
});

export const updateClosetTags = (tags) => ({
    type: UPDATE_CLOSET_TAGS,
    payload: { tags }
});

export const pageLoaded = () => ({
    type: PAGE_LOADED
});

export const setCurrentOutfit = (outfit) => ({
    type: SET_CURRENT_OUTFIT,
    payload: { outfit }
});

export const setCurrentItem = (item) => ({
    type: SET_CURRENT_ITEM,
    payload: { item }
});

export const refreshUser = () => {
    return async (dispatch, getState) => {
        const { user_uuid } = getState().user.user;
        const { data } = await User.refresh(user_uuid);
        dispatch(refreshUserData(data));
    };
};

export const loadFavoriteLooks = (params = { from: 1, count: 30 }) => {
    return async (dispatch) => {
        dispatch(loadFavoriteLooksStart());
        try {
            const user = Cookies.get('user');
            if (!isEmpty(user)) {
                const {
                    data: { items }
                } = await User.favorites({ type: 'looks', uuid: user.uuid, params });
                dispatch(loadFavoriteLooksSuccess(items));
            }
        } catch (error) {
            sentryException(error, "Couldn't load favorite looks");
            dispatch(stopLoading(error));
        }
    };
};

export const updateFavoriteLook = (outfit, outfitSource) => {
    return async (dispatch, getState) => {
        try {
            const user = Cookies.get('user');
            const favoriteOutfit =
                outfitSource === 'feed' && outfit.outfit ? outfit.outfit : outfit;

            const attr = favoriteOutfit.hasOwnProperty('uuid') ? 'uuid' : 'unique';
            if (attr === 'unique') favoriteOutfit.uuid = favoriteOutfit[attr];

            if (user) {
                const { favoriteLooks } = getState().users;
                const isFavorite = favoriteLooks.find(
                    (favorite) => favorite.uuid === favoriteOutfit.uuid
                );

                dispatch(
                    isFavorite
                        ? removeFavoriteLookSuccess(favoriteOutfit)
                        : addFavoriteLookSuccess(favoriteOutfit)
                );

                await User[isFavorite ? 'unfavorite' : 'favorite']({
                    type: 'look',
                    user_uuid: user.uuid,
                    outfit_uuid: favoriteOutfit.unique ? favoriteOutfit.unique : favoriteOutfit.uuid
                });

                trackEvent({
                    name: MIXPANEL_EVENTS.LOOK_ADDED_TO_FAVORITE,
                    properties: {
                        'Look UUID': favoriteOutfit.uuid,
                        'Look source': outfitSource,
                        'Stylist name':
                            outfitSource == 'favorites'
                                ? outfit.owner.name
                                : outfit.owner.first_name.concat(' ', outfit.owner.last_name),
                        'Stylist UUID': outfit.owner.uuid
                    }
                });
            }
        } catch (e) {
            sentryException(e, "Couldn't update favorite looks");
            dispatch(stopLoading(e));
        }
    };
};

export const updateFavoriteStylist = (stylist) => {
    return async (dispatch, getState) => {
        try {
            const user = Cookies.get('user');
            if (user) {
                const favoriteStylists = getState().user.favoriteStylists;
                const isFavorite = favoriteStylists.find(
                    (favorite) => favorite.uuid === stylist.uuid
                );
                // TODO: enable this one...
                dispatch(
                    isFavorite
                        ? removeFavoriteStylistSuccess(stylist)
                        : addFavoriteStylistSuccess(stylist)
                );

                await User[isFavorite ? 'unfavorite' : 'favorite']({
                    user_uuid: user.uuid,
                    stylist_uuid: stylist.uuid
                });
            }
        } catch (e) {
            sentryException(e, "Couldn't update favorite stylists");
            dispatch(stopLoading(e));
        }
    };
};

export const loadCart = (params = {}) => {
    return async (dispatch, getState) => {
        try {
            const user = Cookies.get('user');

            if (user) {
                const { cart } = getState().users;
                params.from = params.from ?? 1; // Should this be allowed to be zero?
                params.count = params.count ?? 30;
                dispatch(loadCartStart());

                const {
                    data: { items, quota_max, total_price }
                } = await User.cart({
                    user_uuid: params.user_uuid ?? user.uuid,
                    params
                });

                dispatch(
                    loadCartSuccess({
                        cart: params.from > 1 ? [...cart, ...items] : items,
                        quota_max,
                        total_price
                    })
                );
            }
        } catch (error) {
            sentryException(error, "Couldn't load cart");
            dispatch(stopLoading(error));
        }
    };
};

export const addToCart = (item, source) => {
    return async (dispatch, getState) => {
        try {
            const { cart } = getState().users;
            const itemUUID = item.unique || item.uuid;
            const isInCart = cart.findIndex((i) => i.uuid === itemUUID) > -1;
            const user = Cookies.get('user');

            if (user && !isInCart) {
                if (!item.price) {
                    const { data } = await User.getItem(itemUUID);
                    item = (data && data[0]) || {};
                }

                item.price = parseFloat(item.price) || 0;

                await User.addToCart({
                    user_uuid: user.uuid,
                    item_uuid: itemUUID
                });

                dispatch(addCart(item));

                trackEvent({
                    name: MIXPANEL_EVENTS.ITEM_ADDED_TO_CART,
                    properties: {
                        'item UUID': itemUUID,
                        'item source': source,
                        'item brand': item.brand_name
                    }
                });
            }
        } catch (error) {
            sentryException(error, "Couldn't add to cart");
            dispatch(stopLoading(error));
        }
    };
};

export const removeFromCart = (item) => {
    return async (dispatch) => {
        try {
            const user = Cookies.get('user');

            if (user) {
                dispatch(removeCart(item));

                await User.removeFromCart({
                    user_uuid: user.uuid,
                    item_uuid: item.unique || item.uuid
                });
            }
        } catch (error) {
            sentryException(error, "Couldn't remove from cart");
            dispatch(stopLoading(error));
        }
    };
};

export const addToCloset = (item) => {
    return async (dispatch) => {
        try {
            const user = Cookies.get('user');
            if (user) {
                await User.addToCloset({
                    user_uuid: user.uuid,
                    item_uuid: item.unique ? item.unique : item.item_uuid ?? item
                });
                dispatch(addCloset(item));
            }
        } catch (error) {
            sentryException(error, "Couldn't add to closet");
            dispatch(stopLoading(error));
        }
    };
};

export const updateCartFilter = (filter) => {
    return async (dispatch, getState) => {
        dispatch(loadCartStart());
        try {
            const { cartFilter } = getState().users;
            dispatch(setCartFilter(filter));
            dispatch(loadCart({ ...cartFilter, ...filter.value }));
        } catch (error) {
            sentryException(error, "Couldn't update cart filters");
            dispatch(stopLoading(error));
        }
    };
};

export const getBillingDetails = () => {
    return async (dispatch) => {
        try {
            const user = Cookies.get('user');
            if (user) {
                const { data } = await User.billingDetails(user.uuid);
                dispatch(updateBillingDetails(data.billing_details));
            }
        } catch (error) {
            sentryException(error, "Couldn't get billing details");
            dispatch(stopLoading(error));
        }
    };
};

export const loadCloset = (params = {}) => {
    return async (dispatch, getState) => {
        dispatch(startLoading());
        try {
            const {
                users: { closet = [] },
                user: { user }
            } = getState();
            if (!params.from) params.from = 0;
            if (!params.count) params.count = 50;
            if (params.from === 0) dispatch(clearCloset());
            const {
                data: { items, quota_max }
            } = await User.closet({ user_uuid: user.user_uuid, params });
            dispatch(
                updateCloset(params.from === 0 ? items : [...closet, ...items], parseInt(quota_max))
            );
        } catch (error) {
            sentryException(error, "Couldn't load closet");
            dispatch(stopLoading({ profile: true, error }));
        }
    };
};

export const loadPhotos = (params = {}) => {
    return async (dispatch, getState) => {
        dispatch(startLoading());
        try {
            const {
                users: { photos = [] },
                user: { user }
            } = getState();
            if (!params.from) params.from = 0;
            // if (!params.count) params.count = 50;
            if (params.from === 0) dispatch(clearPhotos());
            const {
                data: { items, quota_max }
            } = await User.photos({
                user_uuid: user.user_uuid,
                params
            });
            dispatch(
                updatePhotos(params.from === 0 ? items : [...photos, ...items], parseInt(quota_max))
            );
        } catch (error) {
            sentryException(error, "Couldn't load photos");
            dispatch(stopLoading({ profile: true, error }));
        }
    };
};

export const loadClosetFilters = (params = {}) => {
    return async (dispatch, getState) => {
        dispatch(startFiltersLoading());
        try {
            const {
                user: { user }
            } = getState();
            if (!params.client) params.client = 'web';
            if (!params.gender) params.gender = user.gender;
            params.refinements = 'USER_SPECIFIC';
            const { data } = await User.closetFilters({
                user_uuid: user.user_uuid,
                params
            });
            dispatch(clearProfileSelectedFilters());
            dispatch(updateProfileFilters(data, 'closet'));
        } catch (error) {
            sentryException(error, "Couldn't load closet filters");
            dispatch(stopLoading({ profile: true, error }));
        }
    };
};

export const updateSelectedFilters = (update = {}, remove = false, callback) => {
    return async (dispatch, getState) => {
        const {
            users: { selectedFilters }
        } = getState();
        let selected = { ...selectedFilters };
        const SINGLE_SELECTION = ['category_uuid'];
        if (remove) {
            selected[update.key] = selected[update.key].filter(
                (item) => item.key !== update.value.key
            );
        } else {
            if (SINGLE_SELECTION.includes(update.key)) {
                selected[update.key] = [update.value];
            } else {
                if (!selected[update.key]) selected[update.key] = [];
                selected = {
                    ...selected,
                    [update.key]: [...selected[update.key], update.value]
                };
            }
        }
        dispatch({
            type: UPDATE_PROFILE_SELECTED_FILTERS,
            payload: { selected }
        });
        if (callback) callback(selected);
    };
};

export const removeItem = (uuid) => {
    return async (dispatch) => {
        dispatch(startLoading());
        try {
            await User.removeItem(uuid);
            dispatch(loadCloset());
            dispatch(loadClosetFilters());
        } catch (error) {
            sentryException(error, "Couldn't remove item");
            dispatch(stopLoading({ profile: true, error }));
        }
    };
};

export const loadLooks = (params = {}) => {
    return async (dispatch, getState) => {
        dispatch(startLoading());
        try {
            const {
                users: { looks = [] },
                user: { user }
            } = getState();
            if (!params.from) params.from = 1;
            if (!params.count) params.count = 50;
            const {
                data: { items, quota_max }
            } = await User.looks({
                user_uuid: user.user_uuid,
                params
            });
            dispatch(
                updateLooks(params.from === 1 ? items : [...looks, ...items], parseInt(quota_max))
            );
        } catch (error) {
            sentryException(error, "Couldn't load looks");
            dispatch(stopLoading({ profile: true, error }));
        }
    };
};

export const loadLooksFilters = (params = {}) => {
    return async (dispatch, getState) => {
        dispatch(startFiltersLoading());
        try {
            const {
                user: { user }
            } = getState();
            if (!params.client) params.client = 'web';
            if (!params.gender) params.gender = user.gender;
            const { data } = await User.looksFilters({
                user_uuid: user.user_uuid,
                params
            });
            dispatch(clearProfileSelectedFilters());
            dispatch(updateProfileFilters(data, 'looks'));
        } catch (error) {
            sentryException(error, "Couldn't load looks filters");
            dispatch(stopLoading({ profile: true, error }));
        }
    };
};

export const removeLook = (look_uuid, params) => {
    return async (dispatch, getState) => {
        dispatch(startLoading());
        try {
            const {
                user: { user }
            } = getState();
            await User.removeLook({
                user_uuid: user.user_uuid,
                look_uuid
            });
            dispatch(loadLooks(params));
        } catch (error) {
            sentryException(error, "Couldn't remove look");
            dispatch(stopLoading({ profile: true, error }));
        }
    };
};

export const removePhoto = (photo_uuid) => {
    return async (dispatch) => {
        dispatch(startLoading());
        try {
            const { data } = await User.removePhoto(photo_uuid);
            if (data.message === 'fail') {
                dispatch(stopLoading(data.message));
            } else {
                dispatch(loadPhotos());
            }
        } catch (error) {
            sentryException(error, "Couldn't remove photo");
            dispatch(stopLoading({ profile: true, error }));
        }
    };
};

export const loadOrders = (params = {}) => {
    return async (dispatch, getState) => {
        dispatch(startLoading());
        try {
            const {
                users: { orders = [] },
                user: { user }
            } = getState();
            if (!params.from) params.from = 1;
            if (!params.count) params.count = 30;
            const {
                data: { items, quota_max, nextPage }
            } = await User.orders({
                user_uuid: params.user_uuid || user.user_uuid,
                params
            });

            dispatch(
                updateOrders({
                    items: params.from === 1 ? items : orders.concat(items),
                    quota_max: parseInt(quota_max),
                    nextPage
                })
            );
        } catch (error) {
            sentryException(error, "Couldn't load orders");
            dispatch(stopLoading({ view: 'profile', error }));
        }
    };
};

export const loadClosetTags = (params = {}) => {
    return async (dispatch, getState) => {
        dispatch(startLoading());
        try {
            const {
                users: { closetTags },
                user: { user }
            } = getState();
            if (!params.from) params.from = 0;
            if (!params.count) params.count = 30;
            const { data } = await User.closetTags({
                user_uuid: user.user_uuid,
                params
            });
            dispatch(updateClosetTags(params.from === 0 ? data : [...closetTags, ...data]));
        } catch (error) {
            sentryException(error, "Couldn't load closet tags");
            dispatch(stopLoading({ profile: true, error }));
        }
    };
};

export const addClosetItem = (item) => {
    return async (dispatch) => {
        try {
            await User.addItem(item);
            dispatch(loadCloset());
            dispatch(loadClosetFilters());
            toggleModal(dispatch);
        } catch (error) {
            toggleModal(dispatch);
            sentryException(error, "Couldn't add closet item");
            dispatch(stopLoading({ profile: true, error }));
        }
    };
};

export const deactivate = () => {
    return async (dispatch) => {
        dispatch(startLoading());
        try {
            const user = Cookies.get('user');
            const token = user ? user.token : null;
            const { data } = await User.deactivate({
                user_uuid: user.uuid,
                token
            });
            if (data.error) {
                sentryException(data.error, "Couldn't deactivate subscription");
                dispatch(stopLoading(data.error));
            } else {
                toggleModal(dispatch);
                logout(dispatch);
            }
        } catch (error) {
            toggleModal(dispatch);
            sentryException(error, "Couldn't deactivate subscription");
            dispatch(stopLoading({ error }));
        }
    };
};
