import { connect } from 'react-redux';

import {
    pay,
    paymentButtonDisabled,
    updatePaymentToken
} from '../../redux/reducers/payments/actions';
import StripeForm from './StripeForm';

const mapStateToProps = ({
    user: { user },
    booking: { stylist, plan, goal },
    payments: { model, price, coupon, cards, error, buttonDisabled, loading }
}) => ({
    model,
    goal,
    price,
    stylist,
    user,
    coupon,
    plan,
    cards,
    error,
    buttonDisabled,
    loading,
    stylist
});

const mapDispatchToProps = (dispatch) => ({
    pay: (data) => dispatch(pay(data)),
    updatePaymentToken: (token) => dispatch(updatePaymentToken(token)),
    paymentButtonDisabled: (disabled) => dispatch(paymentButtonDisabled(disabled))
});

export default connect(mapStateToProps, mapDispatchToProps)(StripeForm);
