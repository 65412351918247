import './style.scss';

import { useIsMobile } from 'hooks';
import React, { useEffect, useState } from 'react';
import { Button, Col, Container, Form, InputGroup, Row } from 'react-bootstrap';

import { giftcards } from '../../../content';
import { useUserName } from '../../../hooks';
import { Tracking } from '../../../services';

const Details = ({ user, giftcard, updateGiftCard, giftCardError, error = {}, onSubmit }) => {
    const { fullname } = useUserName(user);
    const [custom, setCustom] = useState(false);
    const isMobile = useIsMobile();

    useEffect(() => {
        if (user) {
            updateGiftCard({
                senderName: fullname,
                senderEmail: user.email
            });
        }
    }, [user, updateGiftCard, fullname]);

    useEffect(() => {
        if (custom) updateGiftCard({ amount: 0 });
    }, [custom, updateGiftCard]);

    const validateCustom = () => {
        if (giftcard.amount < 40 || giftcard.amount > 1000) {
            updateGiftCard({ amount: giftcard.amount > 1000 ? 1000 : 40 });
            giftCardError({ custom: true });
        }
    };

    return (
        <Container className="details" fluid>
            <Row>
                <Col>
                    <p className="title">{giftcards.checkout.choose}</p>
                </Col>
            </Row>

            <Row className={`amounts ${error && error.amount ? 'error' : ''}`}>
                {giftcards.packages.map((pkg) => (
                    <Col key={pkg.price}>
                        <Button
                            variant="light"
                            className={!custom && giftcard.amount === pkg.price ? 'selected' : ''}
                            onClick={() => {
                                setCustom(false);
                                Tracking.tag({
                                    event: 'eec.add',
                                    ecommerce: {
                                        add: {
                                            actionField: { list: 'Shopping cart' },
                                            products: [
                                                {
                                                    name: 'Gift Card',
                                                    category: '/category/gift-card/',
                                                    variant: `Gift Card - ${giftcard.amount}`,
                                                    quantity: 1,
                                                    dimension3: 'Ecommerce'
                                                }
                                            ]
                                        }
                                    }
                                });
                                updateGiftCard({ amount: pkg.price });
                            }}
                        >
                            <span>${pkg.price}</span>
                            <label>{pkg.label}</label>
                        </Button>
                    </Col>
                ))}
                <Col key="custom" className="custom">
                    <InputGroup className={custom ? 'selected' : ''}>
                        <InputGroup.Prepend>
                            <InputGroup.Text>
                                <span className="d-none d-sm-flex">
                                    {giftcards.checkout.currency}
                                </span>
                            </InputGroup.Text>
                        </InputGroup.Prepend>
                        <Form.Control
                            as="input"
                            onChange={({ target: { value } }) => {
                                const amount = parseInt(value.replace(/[^0-9]/gi, ''));
                                updateGiftCard({ amount: amount > 0 ? amount : 0 });
                            }}
                            placeholder={
                                isMobile ? giftcards.checkout.currency : giftcards.checkout.custom
                            }
                            onBlur={validateCustom}
                            value={custom ? giftcard.amount : 'Custom'}
                            onClick={() => setCustom(true)}
                        />
                    </InputGroup>
                </Col>
                {error && error.custom && (
                    <p className="error custom">{giftcards.checkout.customError}</p>
                )}
            </Row>

            <Row>
                <Container className="input-fields" fluid>
                    <Row>
                        <Col>
                            <Form.Label>{giftcards.checkout.to}</Form.Label>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12} sm={6}>
                            <Form.Control
                                type="text"
                                value={giftcard.recipientName ? giftcard.recipientName : ''}
                                placeholder={giftcards.checkout.recipientNamePlaceholder}
                                onChange={(e) => updateGiftCard({ recipientName: e.target.value })}
                            />
                            {error && error.recipientName && (
                                <p className="error">
                                    {error.recipientName.name || error.recipientName.lastname}
                                </p>
                            )}
                        </Col>
                        <Col xs={12} sm={6}>
                            <Form.Control
                                type="email"
                                value={giftcard.recipientEmail ? giftcard.recipientEmail : ''}
                                placeholder={giftcards.checkout.recipientEmailPlaceholder}
                                onChange={(e) => updateGiftCard({ recipientEmail: e.target.value })}
                            />
                            {error && error.recipientEmail && (
                                <p className="error">{error.recipientEmail.email}</p>
                            )}
                        </Col>
                    </Row>
                </Container>
            </Row>

            <Row>
                <Container className="input-fields" fluid>
                    <Row>
                        <Col>
                            <Form.Label>{giftcards.checkout.from}</Form.Label>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12} sm={6}>
                            <Form.Control
                                type="text"
                                value={giftcard.senderName ? giftcard.senderName : ''}
                                placeholder={giftcards.checkout.senderNamePlaceholder}
                                onChange={(e) => {
                                    updateGiftCard({ senderName: e.target.value });
                                }}
                            />
                            {error && error.senderName && (
                                <p className="error">
                                    {error.senderName.name || error.senderName.lastname}
                                </p>
                            )}
                        </Col>
                        <Col xs={12} sm={6}>
                            <Form.Control
                                type="email"
                                value={giftcard.senderEmail ? giftcard.senderEmail : ''}
                                placeholder={giftcards.checkout.senderEmailPlaceholder}
                                onChange={(e) => updateGiftCard({ senderEmail: e.target.value })}
                            />
                            {error && error.senderEmail && (
                                <p className="error">{error.senderEmail.email}</p>
                            )}
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Form.Control
                                as="textarea"
                                placeholder={giftcards.checkout.messagePlaceholder}
                                onChange={(e) => updateGiftCard({ message: e.target.value })}
                            />
                            <p className="comment">{giftcards.checkout.messageComment}</p>
                        </Col>
                    </Row>
                    <Row className="d-none d-sm-flex">
                        <Col>
                            <Button variant="dark" onClick={onSubmit}>
                                {giftcards.checkout.continue}
                            </Button>
                        </Col>
                    </Row>
                </Container>
            </Row>
        </Container>
    );
};

export default Details;
