import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { changeModal, setLandingType } from 'store/ui/actions';
import { setMixpanelStore } from 'store/common/actions';
import { signup } from 'store/user/actions';

import Signup from './Signup';

const mapStateToProps = ({
    users: { loading, redirectUrl },
    ui: { modalType, landingType },
    user: { user },
    onboarding,
    error
}) => ({
    error,
    loading,
    user,
    redirectUrl,
    modalType,
    onboarding,
    landingType
});

const mapDispatchToProps = (dispatch) => ({
    changeModal: (type) => changeModal(dispatch, { type }),
    signup: (credentials) => signup(dispatch, credentials),
    setLandingType: (type) => setLandingType(dispatch, type),
    setMixpanelStore: (data) => setMixpanelStore(dispatch, data)
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Signup));
