import './style.scss';

import { useIsMobile } from 'hooks';
import React, { useEffect, useState } from 'react';
import {
    Badge,
    ButtonGroup,
    Col,
    Container,
    Dropdown,
    DropdownButton,
    Image,
    Row
} from 'react-bootstrap';
import InfiniteScroll from 'react-infinite-scroll-component';

import { valentine as texts } from '../../../campaigns';
import { Formatter } from '../../../services';
import { Loader } from '../..';
import { MobileSideCollapse } from '../../';
import { MobileFilters } from './';

const Valentine = ({
    loading,
    user,
    loadCampaignFilters,
    campaignFilters = {},
    loadCampaignItems,
    campaignItems,
    toggleMobileCollapse,
    toggleModal,
    mobileCollapse,
    updateItem,
    addToCart,
    removeFromCart,
    location,
    history
}) => {
    const isMobile = useIsMobile();
    const parseSearchParams = (param) => {
        return Formatter.queryString(location.search)[param]
            ? Formatter.queryString(location.search)[param].indexOf(',')
                ? Formatter.queryString(location.search)
                      [param].split(',')
                      .map((key) => ({ key }))
                : [{ key: Formatter.queryString(location.search)[param] }]
            : [];
    };

    const [selected, setSelected] = useState({
        stylist_uuid: parseSearchParams('stylist_uuid'),
        interest: parseSearchParams('interest'),
        receiver: parseSearchParams('receiver')
    });
    const [order, setOrder] = useState(Formatter.queryString(location.search).order || '');
    const [items, setItems] = useState([]);
    const [expendedFilters, setExpendedFilters] = useState([]);

    useEffect(() => {
        loadCampaignFilters();
        updateItem(); // clear previously shown item from store
    }, []);

    useEffect(() => {
        if (Object.keys(selected).length || !campaignItems.length) {
            refeshItems({
                ...selected,
                order: order.length ? [{ key: order }] : [],
                sort: order.length ? [{ key: 'price' }] : []
            });
        }
    }, [selected]);

    useEffect(() => {
        if (campaignItems.length) {
            setItems(campaignItems);
        } else {
            setItems([]);
        }
    }, [campaignItems]);

    useEffect(() => {
        if (order.length) {
            refeshItems({
                ...selected,
                order: [{ key: order }],
                sort: [{ key: 'price' }]
            });
        }
    }, [order]);

    useEffect(() => {
        if (
            (selected.stylist_uuid.length ||
                selected.interest.length ||
                selected.receiver.length) &&
            campaignFilters.stylist_uuid
        ) {
            setSelected({
                ...selected,
                stylist_uuid: selected.stylist_uuid
                    .map((stylist) =>
                        !stylist.text
                            ? campaignFilters.stylist_uuid.filter_options.find(
                                  (option) => option.key === stylist.key
                              )
                            : stylist
                    )
                    .filter((stylist) => !!stylist),
                interest: selected.interest
                    .map((interest) =>
                        !interest.text
                            ? campaignFilters.interest.filter_options.find(
                                  (option) => option.key === interest.key.split('%20').join(' ')
                              )
                            : interest
                    )
                    .filter((interest) => !!interest),
                receiver: selected.receiver
                    .map((receiver) =>
                        !receiver.text
                            ? campaignFilters.receiver.filter_options.find(
                                  (option) => option.key === receiver.key.split('%20').join(' ')
                              )
                            : receiver
                    )
                    .filter((interest) => !!interest)
            });
        }
    }, [campaignFilters]);

    const refeshItems = (activeFilters) => {
        const from = activeFilters.from ? activeFilters.from : 0;
        const params = Object.keys(activeFilters)
            .filter((key) => Array.isArray(activeFilters[key]) && activeFilters[key].length > 0)
            .reduce(
                (filters, type) => ({
                    ...filters,
                    [type]: activeFilters[type].map((filter) => filter.key).join(',')
                }),
                {}
            );
        history.push(
            `${location.pathname}?${Object.keys(params)
                .map((key) => `${key}=${params[key]}`)
                .join('&')}`
        );
        loadCampaignItems({ ...params, from });
    };

    const onFilterUpdate = (item, type) => {
        let index = -1;
        if (selected[type]) {
            index = selected[type].findIndex((filter) => filter.key === item.key);
        }
        if (index >= 0) {
            setSelected({
                ...selected,
                [type]: selected[type].filter((filter) => filter.key !== item.key)
            });
        } else {
            const prevSelections = selected[type] ? selected[type] : [];
            setSelected({
                ...selected,
                [type]: [...prevSelections, item]
            });
        }
    };

    const onCartClick = (item) => {
        if (!user) {
            toggleModal({
                type: 'Signin',
                url: `${location.pathname}${location.search}`
            });
        } else {
            const action = item.is_in_cart ? removeFromCart : addToCart;
            action(item);
            setItems(
                items.map((i) =>
                    i.uuid === item.uuid ? { ...i, is_in_cart: !item.is_in_cart } : i
                )
            );
        }
    };

    const onItemSelect = (item) => {
        updateItem(item);
        history.push(`/item/${item.uuid}?context=gifts`);
    };

    const renderFilter = (filterName) =>
        campaignFilters[filterName] ? (
            <div className="filter">
                <label>{campaignFilters[filterName].value}</label>
                {campaignFilters[filterName].filter_options
                    .slice(
                        0,
                        expendedFilters.includes(filterName)
                            ? campaignFilters[filterName].filter_options.length
                            : 4
                    )
                    .map((filter) => (
                        <div className="filter-option" key={filter.key}>
                            <input
                                type="checkbox"
                                id={filter.key}
                                onChange={() => onFilterUpdate(filter, filterName)}
                                checked={
                                    selected[filterName]
                                        ? selected[filterName].filter(
                                              (selection) => selection.key === filter.key
                                          ).length > 0
                                        : false
                                }
                            />
                            <label htmlFor={filter.key}>{filter.text}</label>
                        </div>
                    ))}
                <div className="filter-expand" onClick={() => toggleFilterExpand(filterName)}>
                    {!expendedFilters.includes(filterName)
                        ? texts.gifts.filters.more
                        : texts.gifts.filters.less}
                </div>
            </div>
        ) : (
            ''
        );

    const toggleFilterExpand = (filterName) => {
        setExpendedFilters(
            !expendedFilters.includes(filterName)
                ? [...expendedFilters, filterName]
                : expendedFilters.filter((filter) => filter !== filterName)
        );
    };

    return (
        <Container className={`valentine ${mobileCollapse ? 'mobile-collapse-open' : ''}`} fluid>
            {loading && <Loader />}
            {isMobile && (
                <MobileSideCollapse>
                    <MobileFilters
                        filters={{
                            receiver: { ...campaignFilters.receiver },
                            stylist_uuid: { ...campaignFilters.stylist_uuid },
                            interest: { ...campaignFilters.interest }
                        }}
                        onChange={onFilterUpdate}
                        onClear={() => {
                            setSelected({});
                            refeshItems({});
                        }}
                        selected={selected}
                    />
                </MobileSideCollapse>
            )}
            <Row className="title d-none d-sm-block">
                <Col>
                    <h1>{texts.gifts.title}</h1>
                    <h3>{texts.gifts.subtitle}</h3>
                </Col>
            </Row>
            <Row className="content">
                <Col className="filters d-none d-sm-block">
                    {renderFilter('receiver')}
                    {renderFilter('stylist_uuid')}
                    {renderFilter('interest')}
                </Col>

                <Col className="results">
                    <Container fluid>
                        {campaignFilters.receiver && (
                            <Row className="filters-bar mobile d-flex d-sm-none">
                                {campaignFilters.receiver.filter_options.map(({ key, text }) => (
                                    <Badge
                                        variant="light"
                                        className={
                                            selected.receiver &&
                                            selected.receiver.find((filter) => filter.key === key)
                                                ? 'selected'
                                                : ''
                                        }
                                        key={key}
                                        onClick={() => onFilterUpdate({ key, text }, 'receiver')}
                                    >
                                        {text}
                                    </Badge>
                                ))}
                            </Row>
                        )}
                        <Row className="filters-bar d-flex d-sm-none">
                            <Col>
                                <Image
                                    className="filter-icon"
                                    src={texts.gifts.filters.icon}
                                    onClick={toggleMobileCollapse}
                                />
                            </Col>
                            <Col className="sort">
                                <DropdownButton
                                    as={ButtonGroup}
                                    key="sort"
                                    variant="secondary"
                                    title={texts.gifts.filters.sort.title}
                                >
                                    {texts.gifts.filters.sort.options.map(({ key, text }) => (
                                        <Dropdown.Item
                                            eventKey={key}
                                            key={key}
                                            onSelect={(selection) => setOrder(selection)}
                                        >
                                            {text}
                                        </Dropdown.Item>
                                    ))}
                                </DropdownButton>
                            </Col>
                        </Row>

                        <Row className="filter-badges d-none d-sm-flex">
                            <Col>
                                {Object.keys(selected).map((type) =>
                                    selected[type].map(({ key, text }) => (
                                        <Badge variant="light" key={key}>
                                            <div
                                                className="close"
                                                onClick={() => onFilterUpdate({ key, text }, type)}
                                            />
                                            {text}
                                        </Badge>
                                    ))
                                )}
                            </Col>
                            <Col className="sort">
                                <DropdownButton
                                    as={ButtonGroup}
                                    key="sort"
                                    variant="secondary"
                                    title={texts.gifts.filters.sort.title}
                                >
                                    {texts.gifts.filters.sort.options.map(({ key, text }) => (
                                        <Dropdown.Item
                                            eventKey={key}
                                            key={key}
                                            onSelect={(selection) => setOrder(selection)}
                                        >
                                            {text}
                                        </Dropdown.Item>
                                    ))}
                                </DropdownButton>
                            </Col>
                        </Row>
                        <Row>
                            <InfiniteScroll
                                next={() => {
                                    if (items.length > 0) {
                                        refeshItems({
                                            ...selected,
                                            order: order.length ? [{ key: order }] : [],
                                            sort: order.length ? [{ key: 'price' }] : [],
                                            from: items.length > 0 ? items.length : 1
                                        });
                                    }
                                }}
                                hasMore={true}
                                dataLength={items.length}
                                style={{ overflow: 'hidden' }}
                            >
                                {items.map((item) => (
                                    <CampaignItem
                                        key={item.uuid}
                                        item={item}
                                        onClick={onItemSelect}
                                        onCartClick={onCartClick}
                                    />
                                ))}
                            </InfiniteScroll>
                        </Row>
                    </Container>
                </Col>
            </Row>
        </Container>
    );
};

const CampaignItem = ({ item, onClick, onCartClick }) => (
    <div className="campaign-item" data-product-id={item.product_id}>
        <div className="image-container">
            <div
                className={`cart-icon ${item.is_in_cart ? 'added' : 'add'}`}
                onClick={() => onCartClick(item)}
            />
            <div onClick={() => onClick(item)} className="image-overlay">
                <span>{texts.gifts.item.shop}</span>
            </div>
            <Image src={item.picture} />
        </div>
        <span onClick={() => onClick(item)}>
            <p className="brand">{item.brand_name}</p>
            <div className="prices">
                {item.retail_price && (
                    <p
                        className={`price${
                            parseInt(item.sale_price) < parseInt(item.retail_price)
                                ? ' line-through'
                                : ''
                        }`}
                    >
                        {Formatter.price(item.retail_price)}
                    </p>
                )}
                {item.sale_price && parseInt(item.sale_price) < parseInt(item.retail_price) && (
                    <p className="price bold">{Formatter.price(item.sale_price)}</p>
                )}
            </div>
            <p className="name d-none d-sm-block">{item.name}</p>
            <p className="picked">
                {texts.gifts.item.picked.replace('%stylist%', item.stylist_name)}
            </p>
            {!item.sizes.filter((size) => size.length).length &&
                item.merchant_name.toLowerCase() !== 'farfetch' && (
                    <p className="out-of-stock">{texts.gifts.item.outOfStock}</p>
                )}
        </span>
        {item.sizes.filter((size) => size.length).length > 0 && (
            <div className="cart-link" onClick={() => onCartClick(item)}>
                {item.is_in_cart ? texts.gifts.item.inCart : texts.gifts.item.addCart}
            </div>
        )}
    </div>
);

export default Valentine;
