import React, { useState } from 'react';
import { Accordion, Button, Col, Container, Image, Row } from 'react-bootstrap';

import { StickyButton } from '../../../components';

const Intro = ({ data, nextStep }) => {
    const [stepsOpen, setStepsOpen] = useState(false);

    return (
        <Row className="intro">
            <Col>
                <Image src={data.img} className="image" />
                <p className="text">{data.text}</p>

                <Button
                    id="intro-btn"
                    variant="warning"
                    className={data.steps ? 'd-flex' : 'd-none d-sm-flex'}
                    onClick={nextStep}
                >
                    {data.button.text}
                </Button>

                <StickyButton
                    id="intro-btn"
                    className={data.steps ? 'd-none' : 'd-flex d-sm-none'}
                    url=""
                    onClick={nextStep}
                    text={data.button.text}
                    type="warning"
                />

                {data.steps && (
                    <Accordion>
                        <Accordion.Toggle
                            as={Button}
                            variant="link"
                            onClick={() => setStepsOpen(!stepsOpen)}
                            className={stepsOpen ? 'open' : ''}
                            eventKey="0"
                        >
                            {data.stepsToggle}
                        </Accordion.Toggle>
                        <Accordion.Collapse eventKey="0">
                            <Container className="steps-container">
                                {data.steps.map((step) => (
                                    <Row>
                                        <Col xs={12} key={step.number} className="step">
                                            <div className="num">{step.number}</div>
                                            <p>{step.text}</p>
                                        </Col>
                                    </Row>
                                ))}
                            </Container>
                        </Accordion.Collapse>
                    </Accordion>
                )}
            </Col>
        </Row>
    );
};

export default Intro;
