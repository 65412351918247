import 'bootstrap/dist/css/bootstrap.min.css';
import './index.scss';

import React from 'react';
import ReactDOM from 'react-dom';
import TagManager from 'react-gtm-module';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { Provider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import { initMixpanel } from 'services/mixpanel';
import { store } from './store';
import SentryLogging from './services/SentryLogging';
import App from './App';

const getEnvFilePath = () => {
    if (process.env.NODE_ENV === 'production') return `.env.production`;
    return `.env.production`;
};

console.log('process.env.NODE_ENV', process.env.NODE_ENV);
require('dotenv').config({ path: getEnvFilePath() });
const queryClient = new QueryClient();
TagManager.initialize({ gtmId: 'GTM-PS7PQ25' });

SentryLogging();
initMixpanel();

ReactDOM.render(
    <Provider store={store}>
        <QueryClientProvider client={queryClient}>
            <ReactQueryDevtools initialIsOpen={false} />
            <Router>
                <App />
            </Router>
        </QueryClientProvider>
    </Provider>,
    document.getElementById('root')
);
