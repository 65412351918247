import { OutfitType } from 'types/item';
import { Client } from 'types/user';

export interface IMixpanelPeopleProperties {
    UUID?: string;
    Email: string | null;
    Phone: string;
    $first_name?: string;
    $last_name?: string;
    $name: string;
    Gender: string;
    Country: string | null;
    'Booking count'?: string;
    'Order count'?: number;
}

export const getOutfitProperties = (outfit: OutfitType, outfitSource: string) => {
    const outfitUuid = outfit.uuid || outfit.unique;
    return {
        'Look UUID': outfitUuid,
        'Look source': outfitSource,
        'Look URL': `${window.origin}/feed/outfit/${outfitUuid}`,
        'Stylist name':
            outfitSource === 'favorites'
                ? outfit.owner.name
                : outfit.owner.first_name.concat(' ', outfit.owner.last_name),
        'Stylist UUID': outfit.owner.uuid
    };
};

export const extractUserDataForMixpanelPeople = (data: Client): IMixpanelPeopleProperties => ({
    UUID: data.user_uuid,
    Email: data.email,
    Phone: data.phone_number,
    $first_name: data.first_name,
    $last_name: data.last_name,
    $name: data.first_name && data.last_name ? ''.concat(data.first_name, ' ', data.last_name) : '',
    Gender: data.gender,
    Country: data.country,
    'Booking count': data.bookings_count,
    'Order count': data.counters?.purchases
});
