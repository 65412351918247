import './style.scss';

import { useIsMobile } from 'hooks';
import React, { useEffect, useState } from 'react';
import { ButtonGroup, Col, Container, Dropdown, DropdownButton, Row } from 'react-bootstrap';
import InfiniteScroll from 'react-infinite-scroll-component';
import { Link, useHistory } from 'react-router-dom';
import Slider from 'react-slick/lib';

import { Loader, Page } from '../../components';
import Content from '../../content';
import Formatter from '../../services/Formatter';
import FeedItem from './FeedItem';
import FeedMatch from './FeedMatch';
import { trackEvent, MIXPANEL_EVENTS } from 'services/mixpanel';

const Feed = ({
    user,
    loading,
    loadFeed,
    updateFeedGender,
    loadUserMatches,
    loadFavoriteLooks,
    feed,
    feedTotal,
    feedGender,
    matches,
    favoriteLooks = [],
    updateFavoriteLook,
    clearOutfit,
    location,
    setBookingSource
}) => {
    const history = useHistory();
    const PAGE_ITEMS = 10;
    const [jump, setJump] = useState(false);
    const isMobile = useIsMobile();
    const [loader, setLoader] = useState(false);

    useEffect(() => {
        const gender = Formatter.queryString(location.search).gender;
        if (gender) updateFeedGender(gender);

        clearOutfit();
        loadFavoriteLooks();
        loadUserMatches();
        setBookingSource('feed');
    }, []);

    useEffect(() => {
        if (feed.length && location.hash) {
            setLoader(true);
            setTimeout(() => {
                const elm = document.getElementById(location.hash.substr(1));
                if (elm) {
                    elm.scrollIntoView();
                    location.hash = '';
                }
                setLoader(false);
            }, 500);
        }
    }, [feed.length]);

    useEffect(() => {
        if (user && user.gender && !feed.length) updateFeedGender(user.gender);
        loadUserMatches(user);
    }, [user]);

    useEffect(() => {
        if (!feed.length) {
            trackEvent({ name: MIXPANEL_EVENTS.FEED_PAGE_VIEWS, properties: {} });
            loadItems();
        }
    }, [feedGender]);

    const onOutfitSelect = (selected) => history.push(`/feed/outfit/${selected.uuid}`);

    const loadItems = (page = 0) => {
        return loadFeed({
            gender: feedGender,
            from: page,
            count: PAGE_ITEMS
        });
    };

    const onMessagesScroll = ({ target: { scrollTop } }) => setJump(scrollTop != 0);

    const scrollToTop = () => {
        const elm = document.getElementById('results-container');
        if (elm) elm.scrollTo(0, 0);
        setJump(false);
    };

    return (
        <Page footer={false}>
            <Container id="results-container" className="feed" onScroll={onMessagesScroll} fluid>
                {(loading || loader) && <Loader />}
                <Row>
                    <Col id="results" className="outfits">
                        <DropdownButton
                            className="d-md-block"
                            style={{ paddingTop: isMobile ? 15 : 0 }}
                            as={ButtonGroup}
                            key="feed-gender"
                            id="gender"
                            variant="secondary"
                            title={Content.feed.gender.toggle.replace('%gender%', feedGender)}
                        >
                            <p>{Content.feed.gender.switch}</p>
                            {Content.feed.gender.options.map(
                                ({ value, name }) =>
                                    value !== feedGender && (
                                        <Dropdown.Item
                                            eventKey={value}
                                            key={value}
                                            onSelect={(value) => updateFeedGender(value)}
                                        >
                                            {name}
                                        </Dropdown.Item>
                                    )
                            )}
                        </DropdownButton>
                        {feed.length ? (
                            <InfiniteScroll
                                dataLength={feed.length}
                                next={() => loadItems(feed.length)}
                                scrollableTarget="results-container"
                                hasMore={feed.length < feedTotal}
                            >
                                {feed.map(
                                    (outfit, index) =>
                                        outfit?.outfit?.uuid && (
                                            <React.Fragment key={outfit.outfit.uuid}>
                                                <FeedItem
                                                    key={outfit.outfit.uuid}
                                                    item={outfit}
                                                    onSelect={onOutfitSelect}
                                                    onFavorite={() =>
                                                        updateFavoriteLook(outfit, 'feed')
                                                    }
                                                    isFavorite={favoriteLooks.find(
                                                        (favorite) =>
                                                            favorite.uuid === outfit.outfit.uuid
                                                    )}
                                                />
                                                {index === 1 && (
                                                    <div className="d-block d-lg-none">
                                                        <Matches
                                                            matches={matches}
                                                            isDesktop={false}
                                                            user={user}
                                                        />
                                                    </div>
                                                )}
                                            </React.Fragment>
                                        )
                                )}
                            </InfiniteScroll>
                        ) : null}
                    </Col>

                    <Col className="feed-matches d-none d-lg-block">
                        {feed.length > 0 && (
                            <Matches matches={matches} show={feed.length} user={user} />
                        )}
                    </Col>
                </Row>
                <div
                    className={`top-btn d-none d-sm-flex ${jump ? 'show' : 'hide'}`}
                    onClick={scrollToTop}
                >
                    {Content.feed.top}
                </div>
            </Container>
        </Page>
    );
};

const Matches = ({ matches = [], isDesktop = true, user = undefined, mixpanelStore }) => {
    return (
        <div className="matches">
            <p className="title">{Content.feed.matches.title}</p>
            {matches.length > 0 ? (
                <Slider
                    className="feed-matches-carousel"
                    dots={isDesktop}
                    arrows={isDesktop}
                    infinite={false}
                    speed={500}
                    slidesToShow={isDesktop ? 1 : 2}
                    slidesToScroll={1}
                    autoplay={false}
                >
                    {matches.map((match) => (
                        <FeedMatch
                            key={match.uuid}
                            match={match}
                            additionalData={{ mixpanelStore: mixpanelStore, matches: matches }}
                        />
                    ))}
                </Slider>
            ) : (
                <Link className="take-btn" to="/onboarding">
                    {user ? Content.feed.matches.results : Content.feed.matches.take}
                </Link>
            )}
        </div>
    );
};

export default Feed;
