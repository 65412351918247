import React, { useEffect } from 'react';
import { Col, Container, Image, Row } from 'react-bootstrap';

import { StickyButton } from '../../../components';
import { Style } from '../../../services';
import { trackEvent, MIXPANEL_EVENTS } from 'services/mixpanel';

const Brands = ({
    gender,
    setBrandsList,
    styles,
    data: { question, button },
    brands,
    brandsList,
    selectBrand,
    onDone
}) => {
    useEffect(() => {
        loadBrands();
    }, []);

    const loadBrands = async () => {
        const { data } = await Style.getBrands(
            gender,
            styles
                .filter((style) => style.rate > 0)
                .map((style) => style.name)
                .join(',')
        );
        setBrandsList(data);
        trackEvent({ name: MIXPANEL_EVENTS.ONBOARDING_BRANDS_VIEWS, properties: {} });
    };

    const isSelected = (uuid) => brands.find((item) => item.uuid === uuid);

    return (
        <Row className="brands">
            <Col className="content">
                <p className="question">{question}</p>
                <Container className="brands-list">
                    <Row>
                        {brandsList.map((brand) => (
                            <Col
                                key={brand.uuid}
                                id={`brand_${brand.name}`}
                                className={`brand-item ${isSelected(brand.uuid) ? 'selected' : ''}`}
                                onClick={() => selectBrand(brand)}
                            >
                                <div className="check-icon" />
                                <Image src={brand.picture} alt={brand.name} />
                            </Col>
                        ))}
                    </Row>
                </Container>
                <StickyButton url="" onClick={onDone} id="meet" text={button.text} type="warning" />
            </Col>
        </Row>
    );
};

export default Brands;
