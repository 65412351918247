import './style.scss';

import _ from 'lodash';
import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import ImgWithFallback from 'components/ImgWithFallback/ImgWithFallback';

import { giftcards } from '../../../content';
import Summary from './Summary';

const Overview = ({ giftcard }) => {
    const imageSource = (type) =>
        type === 'src'
            ? _.find(giftcards.designs.cards, (card) => card.value === giftcard.design).image.src ||
              giftcards.designs.cards[0].image.src
            : _.find(giftcards.designs.cards, (card) => card.value === giftcard.design).image
                  .fallback || giftcards.designs.cards[0].image.fallback;

    return (
        <Container className="overview" fluid>
            <Row>
                <Col>
                    <p className="title">{giftcards.checkout.gift}</p>
                </Col>
            </Row>
            <Row>
                <Col className="card-design">
                    <p className={`${giftcards.designs.classNames[giftcard.design]}`}>
                        {giftcards.checkout.currency}
                        {giftcard.amount}
                    </p>
                    <ImgWithFallback
                        src={imageSource('src')}
                        fallback={imageSource('fallback')}
                        className="fluid-giftcard"
                    />
                </Col>
            </Row>
            <Summary giftcard={giftcard} />
        </Container>
    );
};

export default Overview;
