import './style.scss';

import React from 'react';
import { Col, Container, ProgressBar, Row } from 'react-bootstrap';

interface IStepsProgressBar {
    steps?: Array<any>;
    label: boolean;
    max: number;
    current: number;
    onClick?: (id: number) => void;
    onBack: () => void;
}

const StepsProgressBar: React.FC<IStepsProgressBar> = ({
    steps = [],
    label = false,
    max,
    current,
    onClick,
    onBack
}) => {
    return (
        <Container className="steps-progress-bar">
            {current > 1 && steps && <div className="back-btn" id="back" onClick={onBack} />}
            {steps && (
                <Row className={`steps ${steps.length > 3 ? 'female' : 'male'} d-none d-sm-flex`}>
                    {steps.map((step) => (
                        <Col key={step.id}>
                            <div
                                className={`step ${step.id < current ? 'done' : ''}`}
                                onClick={() => (onClick ? onClick(step.id) : null)}
                            >
                                <div className="round" />
                                <p>{step.text}</p>
                            </div>
                        </Col>
                    ))}
                </Row>
            )}

            <Row>
                <Col>
                    <ProgressBar
                        max={max ? max : steps.length - 1}
                        now={current ? current : steps.length - 1}
                        label={
                            label && Math.floor((current / max) * 100) > 0
                                ? `${Math.floor((current / max) * 100)}%`
                                : ''
                        }
                        className={label ? 'with-label' : ''}
                        srOnly={label}
                    />
                </Col>
            </Row>
        </Container>
    );
};

export default StepsProgressBar;
