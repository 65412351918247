import axios from 'axios';
import Config from './Config';
import { getUserToken } from './utils/token-utils';

const Stylist = {
    urls: {
        booking: (stylist_id: string, channel_sid: string | null | undefined) =>
            `${Config.get('apiroot2')}stylist/${stylist_id}/bookings${
                channel_sid ? `/${channel_sid}` : ''
            }`,
        archiveSession: (stylist_uuid: string, channel_id: string) =>
            `${Config.get('apiroot2')}user/${stylist_uuid}/channels/${channel_id}/moveToArchive`,
        clients: (stylist_id: string) => `${Config.get('apiroot2')}stylist/${stylist_id}/clients`
    },
    all: (params: Record<string, any>) =>
        axios.get(`${Config.get('apiroot2')}stylist/readAll`, {
            params,
            headers: { token: getUserToken() }
        }),
    get: (stylist_id: string, user_uuid: string | undefined = undefined) =>
        axios.get(
            `${Config.get('apiroot2')}stylist/${stylist_id}/read`,
            user_uuid ? { params: { user_uuid }, headers: { token: getUserToken() } } : {}
        ),
    reviews: (stylist_id: string, from: number, count: number) =>
        axios.get(`${Config.get('apiroot2')}stylist/${stylist_id}/reviews`, {
            params: { from, count }
        }),
    getLatestReviews: (limit: number) =>
        axios.get(`${process.env.REACT_APP_API_GW_PATH}review/latest`, {
            params: { limit }
        }),
    review: (stylist_id: string, review: string) =>
        axios.post(`${Config.get('apiroot2')}stylist/${stylist_id}/review`, review, {
            headers: { token: getUserToken() }
        }),
    looks: (stylist_id: string, params: Record<string, any>) =>
        axios.get(`${Config.get('apiroot2')}stylist/${stylist_id}/looks`, {
            params,
            headers: { token: getUserToken() }
        })
};

export default Stylist;
