import ImgWithFallback from 'components/ImgWithFallback/ImgWithFallback';
import React, { useEffect } from 'react';
import { Col, Container, Row } from 'react-bootstrap';

import { StylingButton } from '../../../components';
import { withLDConsumer, useFlags } from 'launchdarkly-react-client-sdk';
import { trackEvent, MIXPANEL_EVENTS } from 'services/mixpanel';

const Steps = ({ data }) => {
    const howItWorksFlag = useFlags().howItWorks;
    const howItWorksTracker = () =>
        trackEvent({
            name: MIXPANEL_EVENTS.HOW_IT_WORKS_PAGE_VIEW,
            properties: { 'How it works source': 'landing page' }
        });
    const registerLinkEvent = () => {
        const linkButton = document.querySelector('.steps .link');
        if (linkButton) linkButton.addEventListener('click', howItWorksTracker);
    };
    useEffect(() => {
        registerLinkEvent();
    }, []);
    return (
        <>
            {howItWorksFlag ? (
                <Row className="steps">
                    <Col xs={12}>
                        <Container className="stepsCon">
                            <div className="main-title">
                                <p className="section-title">{data.new.title}</p>
                            </div>

                            <Row className="steps-container">
                                {data.new.steps.map((step, index) => (
                                    <Col xs={12} md={4} xl={3} key={step.text} className="step">
                                        <Row
                                            className={'flex-nowrap text'.concat(
                                                (index + 1).toString()
                                            )}
                                        >
                                            <div className="num">{index + 1}</div>
                                            <p className={'text'.concat((index + 1).toString())}>
                                                {step.text}
                                            </p>
                                        </Row>
                                        <ImgWithFallback
                                            src={step.img.src}
                                            fallbackSrc={step.img.fallback}
                                            className={`image d-none d-sm-flex ${'image-'.concat(
                                                (index + 1).toString()
                                            )}`}
                                        />
                                    </Col>
                                ))}
                            </Row>
                        </Container>
                    </Col>
                    <StylingButton />
                    <a href={data.new.link.linkPath} className="steps link">
                        {data.new.link.linkText}
                    </a>
                </Row>
            ) : (
                <Row className="old-steps">
                    <Col xs={12}>
                        <Container>
                            <Row>
                                <p className="section-title">{data.old.title}</p>
                            </Row>
                            <Row className="steps-container">
                                {data.old.steps.map((step, index) => (
                                    <Col xs={12} md={4} key={step.text} className="step">
                                        <div className="num">{index + 1}</div>
                                        <p>{step.text}</p>
                                        <ImgWithFallback
                                            src={step.img.src}
                                            fallbackSrc={step.img.fallback}
                                            className="image d-none d-sm-flex"
                                        />
                                    </Col>
                                ))}
                            </Row>
                        </Container>
                    </Col>
                    <StylingButton />
                </Row>
            )}
        </>
    );
};

export default withLDConsumer()(Steps);
