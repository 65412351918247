import './style.scss';

import React, { useState } from 'react';
import { Col, Container, Image, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { FavoriteButton } from '../../components';
import { feed } from '../../content';
import { useUserName } from '../../hooks';
import { useIsMobile } from 'hooks';
import { trackEvent, MIXPANEL_EVENTS } from 'services/mixpanel';

const FeedItem = ({ item: { outfit, stylist }, isFavorite = false, onSelect, onFavorite }) => {
    const { fullname } = useUserName(stylist);
    const [hover, setHover] = useState(false);
    const isMobile = useIsMobile();
    const eventProperties = {
        'Look UUID': outfit.uuid,
        'Look source': 'feed',
        'Look URL': `${window.origin}/feed/outfit/${outfit.uuid}`,
        'Stylist name': stylist.first_name.concat(' ', stylist.last_name),
        'Stylist UUID': stylist.uuid
    };
    return (
        <Container className={`feed-item type-${outfit.type}`} id={outfit.uuid}>
            <Row>
                <Image className="profile-image" src={stylist.picture} />
            </Row>
            <Row>
                <Col className="title">
                    {feed.item[outfit.type === 'moodboard' ? 'moodboard' : 'look'].replace(
                        '%stylist%',
                        fullname
                    )}
                </Col>
            </Row>
            {outfit.outfit_name && (
                <Row>
                    <Col className="name">
                        {`${outfit.outfit_name.charAt(0).toUpperCase()}${outfit.outfit_name.slice(
                            1
                        )}`}
                    </Col>
                </Row>
            )}
            <Row>
                <Col>
                    <Image className="outfit-image" src={outfit.picture} />
                    <div
                        onMouseEnter={() => setHover(true)}
                        onMouseLeave={() => setHover(false)}
                        className={`outfit-hover ${hover ? 'hover' : ''}`}
                    >
                        <FavoriteButton
                            isFavorite={isFavorite}
                            onClick={() => {
                                if (!isFavorite)
                                    trackEvent({
                                        name: MIXPANEL_EVENTS.LOOK_ADDED_TO_FAVORITE,
                                        properties: eventProperties
                                    });
                                return onFavorite(outfit);
                            }}
                            filled={hover && !isMobile}
                        />
                        <div
                            className="hover-button"
                            onClick={() => {
                                trackEvent({
                                    name: MIXPANEL_EVENTS.LOOK_CLICKS,
                                    properties: eventProperties
                                });
                                return onSelect(outfit);
                            }}
                        />
                    </div>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Link
                        className="btn btn-warning"
                        to={{
                            pathname: `/stylist/${stylist.uuid}/profile`,
                            state: { from: window.location.pathname }
                        }}
                        onClick={() =>
                            trackEvent({
                                name: MIXPANEL_EVENTS.FEED_BOOK_STYLIST,
                                properties: eventProperties
                            })
                        }
                    >
                        {feed.item.button.replace('%stylist%', stylist.first_name)}
                    </Link>
                </Col>
            </Row>
        </Container>
    );
};

export default FeedItem;
