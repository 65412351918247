import './style.scss';

import { inbox } from 'content.json';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Button, Col, Image, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { Message as MessageType } from './store/types';
import { loadStylistData } from './store/actions';
import { StylistUser } from 'types/stylist';

const Message: React.FC<{
    message: MessageType;
    onClick: ({
        sid,
        stylist,
        current_session_status
    }: {
        sid: string;
        stylist: any;
        current_session_status: any;
    }) => void;
}> = ({ message, onClick }) => {
    const [stylistData, setStylistData] = useState<StylistUser | null>(null);
    const isActiveStylist = stylistData?.is_active && stylistData?.is_approved === '1';
    const isSubscriptionEnded =
        message.current_session_status === 'ended' && message.isSubscription;
    const canBookAgain =
        !message.current_session_status &&
        !message.stylist.is_bot &&
        !message.isSubscription &&
        isActiveStylist;

    useEffect(() => {
        getStylistData();
    }, [message]);

    const getStylistData = async () => {
        const data = await loadStylistData(message.stylist.uuid as string);
        if (data) setStylistData(data);
    };

    const getButtonText = () => {
        const { sender, type } = message.last_message;
        let text = inbox.button[sender === 'stylist' ? 'reply' : 'chat'];
        if (
            type === 'moodboard' ||
            type === 'check_out_your_outfit' ||
            type === 'items_collection'
        ) {
            text = inbox.button.review;
        }
        if (message.current_session_status === 'ended' && message?.stylist?.first_name) {
            text = inbox.button.again.replace('%stylist%', message.stylist.first_name);
        }
        return text;
    };

    const getMessageText = () => {
        const last_message = message.last_message;
        let _text;
        if (last_message.type === 'love') {
            _text = <Image src={inbox.icons.heart} />;
        }
        if (last_message.type === 'like') {
            _text = <Image src={inbox.icons.like} />;
        }
        if (last_message.type === 'media_image') {
            _text = <Image src={inbox.icons.photo} />;
        }
        if (!_text) {
            _text = last_message.text;
        }
        return _text;
    };

    return (
        <Row className="message">
            <Col className="stylist-info">
                <Link
                    to={
                        message.stylist.is_bot
                            ? '/inbox'
                            : `/stylist/${message.stylist.uuid}/profile`
                    }
                >
                    <Image src={message.stylist.picture} />
                </Link>
                {message.plan && inbox.plans[message.plan] && (
                    <p
                        className={`d-none d-sm-block ${
                            message.plan === 'cleanout' ? 'cleanout' : ''
                        }`}
                    >
                        {inbox.plans[message.plan]}
                    </p>
                )}
            </Col>
            <Col>
                <div className="text" onClick={() => onClick(message)}>
                    <p className="name">{message.stylist.first_name}</p>
                    <p className="content">{getMessageText()}</p>
                    <p className="date">
                        {message.last_message.created
                            ? moment(message.last_message.created).format('MMMM DD, YYYY')
                            : ''}
                    </p>
                </div>
                <div className="buttons">
                    {canBookAgain && (
                        <Button
                            variant="warning"
                            className="d-none d-md-block"
                            onClick={() =>
                                onClick({
                                    ...message,
                                    current_session_status: 'rebook'
                                })
                            }
                        >
                            {message?.stylist?.first_name &&
                                inbox.button.book.replace(
                                    '%stylist%',
                                    message?.stylist?.first_name
                                )}
                        </Button>
                    )}
                    {message.current_session_status === 'ended' && (
                        <Button
                            variant="warning"
                            className="d-none d-md-block"
                            onClick={() => onClick(message)}
                        >
                            {inbox.button.session}
                        </Button>
                    )}
                    {(!isSubscriptionEnded || !message.isSubscription) && (
                        <Button
                            variant="light"
                            onClick={() =>
                                onClick({
                                    ...message,
                                    current_session_status:
                                        message.current_session_status === 'ended'
                                            ? 'rebook'
                                            : message.current_session_status
                                })
                            }
                        >
                            {getButtonText()}
                        </Button>
                    )}
                </div>
            </Col>
        </Row>
    );
};

export default Message;
