import './style.scss';

import React from 'react';
import { Button, Col, Container, Image, Row } from 'react-bootstrap';

import { TestimonialsCarousel } from '../../../components';
import { giftcards } from '../../../content';

const Customers = ({ history }) => (
    <Row className="customers">
        <p className="title">{giftcards.customers.title}</p>
        {giftcards.customers.content.map((customer) => (
            <Col xs={12} md={4} key={customer.name} className="customer d-none d-md-flex">
                <Container>
                    <Row>
                        <Col className="image">
                            <div className="watermark" />
                            <Image src={customer.img} fluid />
                        </Col>
                    </Row>
                    <Row>
                        <Col className="desc">
                            <p className="text">{customer.text}</p>
                            <p className="name">{customer.name}</p>
                        </Col>
                    </Row>
                </Container>
            </Col>
        ))}
        <Col className="no-padding d-block d-md-none">
            <TestimonialsCarousel items={giftcards.customers.content} />
        </Col>
        <Button
            className="get-gift-btn"
            variant="dark"
            onClick={() => history.push('/gift-cards/checkout')}
        >
            {giftcards.customers.button}
        </Button>
    </Row>
);

export default Customers;
