import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './style.scss';

import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import Slider from 'react-slick';
import ImgWithFallback from 'components/ImgWithFallback/ImgWithFallback';
import { ContentItem } from 'types/utils';

interface ITestimonialsCarousel {
    items: ContentItem[];
}

const TestimonialsCarousel: React.FC<ITestimonialsCarousel> = ({ items }) => (
    <Slider
        className="testimonials-carousel"
        dots={false}
        infinite={true}
        centerMode={true}
        centerPadding="30px"
        slidesToShow={3}
        slidesToScroll={1}
        autoplay={false}
        responsive={[
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1
                }
            }
        ]}
    >
        {items.map((testimonial) => (
            <div key={testimonial.name} className="testimonial">
                <Container>
                    <Row>
                        <Col className="image">
                            <div className="watermark" />
                            <ImgWithFallback
                                src={testimonial.img.src}
                                fallbackSrc={testimonial.img.fallback}
                                alt="wishi"
                                fluid="true"
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col className="testimonial-text">
                            <p className="text">{testimonial.text}</p>
                            <p className="name">{testimonial.name}</p>
                        </Col>
                    </Row>
                </Container>
            </div>
        ))}
    </Slider>
);
export default TestimonialsCarousel;
