import { TypedUseSelectorHook, useSelector } from 'react-redux';
import { ActionTypes, UIState } from './types';
import { Actions } from '../actions';

const initialState: UIState = {
    indicators: {
        loading: false,
        error: null,
        showModal: false
    },
    redirectUrl: null,
    modalType: null,
    modalData: null,
    landingType: 'shoppers'
};

const uiReducer = (state = initialState, action: Actions): UIState => {
    switch (action.type) {
        case ActionTypes.SET_MODAL: {
            return {
                ...state,
                indicators: {
                    ...state.indicators,
                    showModal: action.payload
                }
            };
        }
        case ActionTypes.TOGGLE_MODAL: {
            const { type, url = null, data = null } = action.payload;
            return {
                ...state,
                indicators: {
                    ...state.indicators,
                    showModal: type !== null ? !state.indicators.showModal : false,
                    loading: false,
                    error: null
                },
                redirectUrl: url || state.redirectUrl,
                modalType: type ?? state.modalType,
                modalData: data
            };
        }
        case ActionTypes.CHANGE_MODAL: {
            const { type, data = {} } = action.payload;
            return {
                ...state,
                indicators: {
                    ...state.indicators,
                    error: null,
                    loading: false
                },
                modalType: type,
                modalData: data
            };
        }
        case ActionTypes.SET_LANDING_TYPE: {
            const landingType = action.payload;
            return {
                ...state,
                landingType
            };
        }
        default:
            return state;
    }
};
export const name = 'ui';
export const useUIStore: TypedUseSelectorHook<UIState> = (selector, ...args) =>
    useSelector((store: any) => selector(store[name]), ...args);
export default uiReducer;
