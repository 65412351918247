import './style.scss';

import React from 'react';
import { Carousel, Col, Container, Image, Row } from 'react-bootstrap';
import { trackEvent, MIXPANEL_EVENTS } from 'services/mixpanel';
import { RadioButton } from '../';

const translateSelection = { 0: 'hell no', 1: 'sometimes', 2: 'love' };
const StyleSelector = ({ styles, question, answers, onSelect, current, selections }) => (
    <Carousel
        className="style-selector"
        controls={false}
        indicators={false}
        fade={true}
        interval={null}
        activeIndex={current}
        onSelect={() => {}} // required by Carousel component if using activeIndex
    >
        {styles.map(({ style, picture }) => {
            return (
                <Carousel.Item key={style}>
                    <Container className="style" fluid>
                        <Row>
                            <Col>
                                <p className="question">
                                    {question.replace(
                                        '%style%',
                                        style.charAt(0).toUpperCase() + style.slice(1)
                                    )}
                                </p>
                                <Image src={picture} />
                            </Col>
                        </Row>
                        <Row className="answers">
                            {answers.map((answer) => {
                                return (
                                    <Col className="no-padding" key={`${style}_${answer.value}`}>
                                        <RadioButton
                                            direction="vertical"
                                            key={`${style}${answer.value}`}
                                            id={`${style}_${answer.value}`}
                                            value={`${style}${answer.value}`}
                                            isChecked={selections[style] === answer.value}
                                            onClick={() => {
                                                trackEvent({
                                                    name: MIXPANEL_EVENTS.ONBOARDING_STYLE_SELECTION,
                                                    properties: {
                                                        'Page name': style,
                                                        'Style selection': answer.value
                                                    }
                                                });
                                                return onSelect({
                                                    name: style,
                                                    rate: answer.value
                                                });
                                            }}
                                            label={answer.text}
                                        />
                                    </Col>
                                );
                            })}
                        </Row>
                    </Container>
                </Carousel.Item>
            );
        })}
    </Carousel>
);

export default StyleSelector;
