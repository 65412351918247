import { CoreUser, Client as ClientUser, Stylist as StylistUser, Stylist } from 'types/user';
import { IActionWithPayload, IAction } from '../types';

export enum ActionTypes {
    LOAD_FAVORITE_STYLISTS = 'user/LOAD_FAVORITE_STYLISTS',
    LOAD_FAVORITE_STYLISTS_REQUEST = 'user/LOAD_FAVORITE_STYLISTS_REQUEST',
    LOAD_FAVORITE_STYLISTS_SUCCESS = 'user/LOAD_FAVORITE_STYLISTS_SUCCESS',
    LOAD_FAVORITE_STYLISTS_FAILURE = 'user/LOAD_FAVORITE_STYLISTS_FAILURE',
    LOGOUT_USER = 'user/LOGOUT_USER',
    REFRESH_USER_DATA = 'user/REFRESH_USER_DATA',
    UPDATE_USER = 'user/UPDATE_USER',
    TOGGLE_STYLIST = 'user/TOGGLE_STYLIST',
    UPDATE_FAVORITE_SECTION = 'user/UPDATE_FAVORITE_SECTION'
}

export interface UserState {
    favoriteStylists: Stylist[];
    user: ClientUser | StylistUser | null;
    redirectUrl: string | null;
    favoriteLooks: any[];
    cart: any | null;
    twilioInit: boolean;
    favoriteSection: string | null;
}

export type Actions =
    | IActionWithPayload<ActionTypes.LOAD_FAVORITE_STYLISTS_SUCCESS, any[]>
    | IAction<ActionTypes.LOGOUT_USER>
    | IActionWithPayload<ActionTypes.REFRESH_USER_DATA, ClientUser | StylistUser | null>
    | IActionWithPayload<ActionTypes.UPDATE_USER, ClientUser | StylistUser | null>
    | IActionWithPayload<ActionTypes.TOGGLE_STYLIST, Stylist>
    | IActionWithPayload<ActionTypes.UPDATE_FAVORITE_SECTION, string>;
