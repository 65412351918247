import './style.scss';

import { useIsMobile } from 'hooks';
import React, { useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import InfiniteScroll from 'react-infinite-scroll-component';
import { Link, useHistory, useLocation, useParams } from 'react-router-dom';
import { MIXPANEL_EVENTS, trackEvent } from 'services/mixpanel';

import { Loader, Outfit as OutfitItem, Page } from '../../components';
import { feed } from '../../content';

const Outfit = ({
    loading,
    looksLoading,
    loadOutfit,
    loadStylistLooks,
    loadFavoriteLooks,
    outfit,
    looks,
    looksTotal,
    clearLooks,
    clearOutfit,
    updateStylistLook,
    updateItem,
    updateOutfit,
    favoriteLooks = [],
    cart = [],
    user
}) => {
    const location = useLocation();
    const history = useHistory();
    let { outfit_uuid } = useParams();
    const PAGE_ITEMS = 5;
    const [jump, setJump] = useState(false);
    const isMobile = useIsMobile();
    const isNotMainOutfit = (look) => look.uuid !== outfit_uuid && look.unique !== outfit_uuid;

    useEffect(() => {
        if (outfit_uuid) loadOutfit(outfit_uuid);
        updateItem(); // clear previously shown item from store

        return () => {
            clearOutfit();
            clearLooks();
        };
    }, []);

    useEffect(() => {
        if (outfit.owner && !looksLoading) {
            loadStylistLooks(outfit.owner.uuid, {
                gender: outfit.attributes.gender,
                from: 1,
                count: PAGE_ITEMS
            });
            loadFavoriteLooks();
        }
    }, [outfit]);

    return (
        <Page footer={false} header={!isMobile}>
            {loading && <Loader />}
            <Container className="outfit-page">
                <Row className="header">
                    <Col>
                        <div
                            className="back-btn"
                            onClick={() => {
                                const state = location.state;
                                history.push(
                                    state && state.back ? state.back : `/feed#${outfit.unique}`
                                );
                            }}
                        >
                            <label>
                                {location.state && location.state.text
                                    ? location.state.text
                                    : feed.item.back}
                            </label>
                        </div>
                    </Col>
                    <Col className="d-block d-sm-none">
                        {user && cart && (
                            <Link to="/shopping-list">
                                <div className="cart-icon">{cart.length > 0 && cart.length}</div>
                            </Link>
                        )}
                    </Col>
                </Row>
                <OutfitItem
                    key={outfit.unique}
                    outfit={outfit}
                    isFavorite={favoriteLooks.find((favorite) => favorite.uuid === outfit.unique)}
                    onChange={(changed) => updateOutfit(changed)}
                    onItemSelect={(item) => {
                        trackEvent({
                            name: MIXPANEL_EVENTS.ITEM_CLICKS,
                            properties: {
                                'item UUID': item.item_uuid,
                                'item source': 'feed',
                                'item brand': item.brand_name
                            }
                        });
                        return history.push(
                            `/feed/outfit/${outfit.uuid ?? outfit.unique}/item/${item.uuid}`
                        );
                    }}
                    isMainOutfit={true}
                    outfitSource={'feed'}
                />
                {outfit.owner && looks.length > 0 && (
                    <Row>
                        <p className="more">
                            {feed.item.more.replace('%stylist%', outfit.owner.first_name)}
                        </p>
                    </Row>
                )}
                <InfiniteScroll
                    style={{ overflow: 'visible' }}
                    dataLength={looks.length}
                    onScroll={() => setJump(window.pageYOffset > 0)}
                    next={() => {
                        loadStylistLooks(outfit.owner.uuid, {
                            gender: outfit.gender,
                            from: looks.length,
                            count: PAGE_ITEMS
                        });
                    }}
                    hasMore={looks.length < looksTotal}
                >
                    {looks.map(
                        (look) =>
                            isNotMainOutfit(look) && (
                                <OutfitItem
                                    key={look.uuid}
                                    outfit={look}
                                    isFavorite={favoriteLooks.find(
                                        (favorite) => favorite.uuid === look.uuid
                                    )}
                                    onChange={(changed) => updateStylistLook(look.uuid, changed)}
                                    onItemSelect={(item) => {
                                        trackEvent({
                                            name: MIXPANEL_EVENTS.ITEM_CLICKS,
                                            properties: {
                                                'item UUID': item.item_uuid,
                                                'item source': 'feed',
                                                'item brand': item.brand_name
                                            }
                                        });
                                        return history.push(
                                            `/feed/outfit/${look.uuid}/item/${item.uuid}`
                                        );
                                    }}
                                    isMainOutfit={false}
                                />
                            )
                    )}
                </InfiniteScroll>
                {jump && (
                    <div className="top-btn d-none d-sm-flex" onClick={() => window.scrollTo(0, 0)}>
                        {feed.top}
                    </div>
                )}
            </Container>
        </Page>
    );
};

export default Outfit;
