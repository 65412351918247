import './style.scss';

import { useFlags, withLDConsumer } from 'launchdarkly-react-client-sdk';
import React, { useEffect, useMemo, useState } from 'react';
import { Dropdown, Image, Nav, Navbar } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { MIXPANEL_EVENTS, trackEvent } from 'services/mixpanel';

import { logo, navbar } from '../../content';
import { Formatter } from '../../services';
import { ProfilePhoto } from '../';

const NavBar = ({
    onClick,
    user,
    messagesCount,
    logout,
    location,
    fixed = 'top',
    cartTotal,
    cartPrice,
    campaigns = [],
    landingType,
    loadCart
}) => {
    const [expended, setExpended] = useState(false);
    const [toggle, setToggle] = useState(false);
    const howItWorksFlag = useFlags().howItWorks;
    const formattedTotalPrice = useMemo(() => Formatter.price(cartPrice), [cartPrice]);
    const isLoggedIn = !!user?.email;

    const howItWorksTracker = () => {
        trackEvent({
            name: MIXPANEL_EVENTS.HOW_IT_WORKS_PAGE_VIEW,
            properties: { 'How it works source': 'top bar link' }
        });
    };

    useEffect(() => {
        user && loadCart();
    }, [user]);

    useEffect(() => {
        setToggle(false);
    }, [location]);

    return (
        <Navbar
            className={`nav-bar ${landingType !== 'shoppers' ? 'retailers-page' : ''}`}
            fixed={fixed}
            expand="sm"
            expanded={expended}
        >
            <Navbar.Brand className="logo">
                <Link to="/">
                    <Image src={logo.black} className="d-flex d-sm-none" />
                    <Image src={logo.text_black} className="d-none d-sm-flex" />
                </Link>
            </Navbar.Brand>

            {isLoggedIn ? (
                <>
                    <Navbar.Toggle onClick={() => setExpended(!expended)}>
                        <Image src={navbar.icons.hamburger} />
                    </Navbar.Toggle>

                    {/*Left bar*/}
                    <Nav className="mobile-nav d-flex d-sm-none">
                        <Link
                            className="stylistButton"
                            to="/stylistSearch"
                            data-test-id="stylists-button-mobile"
                        >
                            {navbar.stylistsMobile}
                        </Link>
                        <span>
                            <Link
                                to="/inbox"
                                className={`inbox ${messagesCount > 0 ? 'new-messages' : ''}`}
                            >
                                <Image className="inbox" src={navbar.icons.inbox} />
                            </Link>
                            <Link to="/shopping-list">
                                <div className="cart-icon">{cartTotal > 0 ? cartTotal : ''}</div>
                            </Link>
                        </span>
                    </Nav>

                    <Navbar.Collapse onClick={() => setExpended(false)}>
                        <Nav>
                            <span className="d-none d-sm-flex">
                                {!user.has_active_sessions && (
                                    <Link to="/pricing">{navbar.pricing}</Link>
                                )}
                                <Link to="/stylistSearch" data-test-id="stylists-button">
                                    {navbar.stylists}
                                </Link>
                                <Link to="/feed">{navbar.feed}</Link>
                                <Link to="/gifts">{navbar.gifts}</Link>
                                {campaigns.map(
                                    ({ navbar = {} }) =>
                                        navbar.left && (
                                            <Link
                                                key={navbar.left.text}
                                                to={navbar.left.link}
                                                className="campaign-link"
                                            >
                                                {navbar.left.text}
                                            </Link>
                                        )
                                )}
                            </span>
                            {howItWorksFlag && (
                                <Link
                                    className="d-none d-lg-flex"
                                    to="/how-it-works"
                                    onClick={() => howItWorksTracker()}
                                >
                                    {navbar.gettingStarted}
                                </Link>
                            )}
                            <span className="d-flex d-sm-none">
                                <Link to="/profile/closet">{navbar.profile}</Link>
                                <Link to="/favorites">{navbar.favorites}</Link>
                                <Link to="/feed">{navbar.feed}</Link>
                                <Link to="/gifts">{navbar.gifts}</Link>
                                {campaigns.map(
                                    ({ navbar = {} }) =>
                                        navbar.left && (
                                            <Link
                                                key={navbar.left.text}
                                                to={navbar.left.link}
                                                className="campaign-link"
                                            >
                                                {navbar.left.text}
                                            </Link>
                                        )
                                )}
                                {howItWorksFlag && (
                                    <Link to="/how-it-works" onClick={() => howItWorksTracker()}>
                                        {navbar.gettingStarted}
                                    </Link>
                                )}

                                <Link to="/orders">{navbar.orders}</Link>
                                <Link to="/settings">{navbar.settings}</Link>
                                <Link to="#" onClick={logout} data-test-id="logout-button">
                                    {navbar.logout}
                                </Link>
                            </span>
                        </Nav>

                        {/*Right bar*/}
                        <Nav onSelect={onClick} className="justify-content-end">
                            <Link
                                to="/inbox"
                                className={`d-none d-sm-flex ${
                                    messagesCount > 0 ? 'new-messages' : ''
                                }`}
                            >
                                {navbar.sessions}
                            </Link>
                            <Link to="/shopping-list" className="cart-link d-none d-sm-flex">
                                <div className="cart-icon">{cartTotal ? cartTotal : ''}</div>
                                {!!cartTotal && (
                                    <div className="cart-price">({formattedTotalPrice})</div>
                                )}
                            </Link>
                            <Dropdown
                                className="user-menu d-none d-sm-flex"
                                show={toggle}
                                onToggle={setToggle}
                                data-test-id="user-menu"
                            >
                                <Dropdown.Toggle>
                                    <ProfilePhoto user={user} />
                                </Dropdown.Toggle>
                                <Dropdown.Menu alignRight={true}>
                                    <Link to="/profile/closet">{navbar.profile}</Link>
                                    <Link to="/favorites">{navbar.favorites}</Link>
                                    <Link to="/orders">{navbar.orders}</Link>
                                    <Dropdown.Divider />
                                    <Link to="/settings">{navbar.settings}</Link>
                                    <Dropdown.Item onClick={logout} data-test-id="logout-button">
                                        {navbar.logout}
                                    </Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                        </Nav>
                    </Navbar.Collapse>
                </>
            ) : (
                <>
                    <Nav className="d-none d-sm-flex">
                        <Link to="/pricing">{navbar.pricing}</Link>
                        <Link to="/our-story">{navbar.story}</Link>
                        <Link to="/feed">{navbar.feed}</Link>
                        <Link to="/gifts">{navbar.gifts}</Link>
                        {campaigns.map(
                            ({ navbar = {} }) =>
                                navbar.left && (
                                    <Link
                                        key={navbar.left.text}
                                        to={navbar.left.link}
                                        className="campaign-link"
                                    >
                                        {navbar.left.text}
                                    </Link>
                                )
                        )}
                    </Nav>
                    <Nav onSelect={onClick} className="justify-content-end logged-out">
                        {location.pathname.includes('/gift-cards') ? (
                            <Link to="/gift-cards/checkout" className="gift-card">
                                {navbar.giftcard}
                            </Link>
                        ) : campaigns[0]?.navbar?.right &&
                          location.pathname === campaigns[0].navbar.right?.url ? (
                            <Link to={campaigns[0].navbar.right.link} className="campaign-button">
                                {campaigns[0].navbar.right.text}
                            </Link>
                        ) : (
                            <>
                                <Nav.Link
                                    eventKey="Signin"
                                    className="signin"
                                    data-test-id="nav-login-btn"
                                >
                                    {navbar.login}
                                </Nav.Link>
                                <Nav.Link
                                    eventKey="Signup"
                                    className="signup"
                                    data-test-id="nav-signup-btn"
                                >
                                    {navbar.signup}
                                </Nav.Link>
                            </>
                        )}
                    </Nav>
                </>
            )}
        </Navbar>
    );
};

export default withLDConsumer()(NavBar);
