import './style.scss';

import { useWithDispatch } from 'hooks';
import React, { useEffect, useState } from 'react';
import { Button, Col, Container, Row } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { isMatchedStylist, MIXPANEL_EVENTS, trackEvent } from 'services/mixpanel';
import { updateFavoriteSection, updateFavoriteStylist } from 'store/user/actions';

import { MatchItem, Outfit, Page, ToggleButton } from '../../components';
import { favorites as content } from '../../content';

const Favorites = ({
    loadFavoriteLooks,
    loadFavoriteStylists,
    favoriteLooks = [],
    favoriteStylists = [],
    loadFavoriteLooksSuccess,
    favoriteSection,
    setBookingSource,
    mixpanelStore,
    stylistsMatchs,
    loadUserMatches,
    user
}) => {
    const history = useHistory();
    const [section, setSection] = useState(
        favoriteSection ? favoriteSection : content.toggle[1].value
    );
    useEffect(() => {
        loadFavoriteLooks();
        loadFavoriteStylists();
        if (!favoriteStylists.length && !favoriteLooks.length) {
            setSection(content.toggle[1].value);
        }
    }, []);

    useEffect(() => {
        if (user) loadUserMatches(user);
    }, [user]);

    useEffect(() => {
        switch (section) {
            case 'looks':
                if (!favoriteLooks.length) {
                    loadFavoriteLooks();
                }
                break;
            case 'stylists':
                if (!favoriteStylists.length) {
                    loadFavoriteStylists();
                }
                break;
            default:
                loadFavoriteLooks();
        }
        updateUserFavoriteSection(section);
    }, [section]);

    const updateUserFavoriteSection = useWithDispatch(updateFavoriteSection);
    const updateFavoriteStylistAction = useWithDispatch(updateFavoriteStylist);
    const showToggleBtn = favoriteLooks.length > 0 || favoriteStylists.length > 0;

    return (
        <Page footer={false}>
            <Container className="favorites">
                <Row>
                    <Col>
                        <h2 className="title">{content.title}</h2>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <ToggleButton
                            toggles={content.toggle}
                            onChange={setSection}
                            selected={section}
                        />
                    </Col>
                </Row>
                {section === 'looks' && (
                    <span>
                        {favoriteLooks && favoriteLooks.length > 0 ? (
                            favoriteLooks.map((look, index) => (
                                <Outfit
                                    key={look.uuid}
                                    outfit={look}
                                    isFavorite={favoriteLooks.find(
                                        (favorite) => favorite.uuid === look.uuid
                                    )}
                                    onChange={(changed) => {
                                        let favList = [...favoriteLooks];
                                        favList[index] = changed;
                                        loadFavoriteLooksSuccess(favList);
                                    }}
                                    onItemSelect={(item) =>
                                        history.push(
                                            `/favorites/outfit/${look.uuid}/item/${item.uuid}?context=favorites`
                                        )
                                    }
                                    isMainOutfit={true}
                                    outfitSource={'favorites'}
                                />
                            ))
                        ) : (
                            <Col className="empty">
                                <p>{content.empty}</p>
                                <Button variant="dark" onClick={() => history.push('/feed')}>
                                    {content.browseLooks}
                                </Button>
                            </Col>
                        )}
                    </span>
                )}
                {section === 'stylists' && (
                    <Row className="stylists">
                        {!favoriteStylists.length ? (
                            <Col className="empty">
                                <p>{content.empty}</p>
                                <Button
                                    variant="dark"
                                    onClick={() => history.push('/stylistSearch')}
                                >
                                    {content.browseStylists}
                                </Button>
                            </Col>
                        ) : (
                            favoriteStylists.map((stylist) => (
                                <Col
                                    xs={12}
                                    md={4}
                                    key={stylist.uuid}
                                    className="stylist-item-container"
                                >
                                    <MatchItem
                                        key={stylist.uuid}
                                        match={stylist}
                                        onClick={() => {
                                            setBookingSource('favorites');
                                            trackEvent({
                                                name: MIXPANEL_EVENTS.MEET_STYLIST,
                                                properties: {
                                                    ...mixpanelStore.onboarding,
                                                    'Stylist name': stylist.first_name.concat(
                                                        ' ',
                                                        stylist.last_name
                                                    ),
                                                    'Stylist UUID': stylist.uuid,
                                                    'Is stylist match': isMatchedStylist(
                                                        stylistsMatchs,
                                                        stylist.uuid
                                                    ),
                                                    'Booking source': 'stylists'
                                                }
                                            });
                                            return history.push(`/stylist/${stylist.uuid}/profile`);
                                        }}
                                        isFavorite={favoriteStylists.find(
                                            (favorite) => favorite.uuid === stylist.uuid
                                        )}
                                        onFavorite={updateFavoriteStylistAction}
                                    />
                                </Col>
                            ))
                        )}
                    </Row>
                )}
            </Container>
        </Page>
    );
};

export default Favorites;
