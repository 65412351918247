import React, { useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';

import { billing as texts } from '../../../content';
import { User } from '../../../services';
import { BillingForm, StickyButton } from '../../';

const Billing = ({ loading, user, updateBillingDetails, toggle }) => {
    const [buttonDisabled, setButtonDisabled] = useState(false);
    const [billing, setBilling] = useState({});
    const [valid, setValid] = useState(false);

    const onConfirm = async () => {
        const { user_uuid } = user;
        await User.updateBillingDetails({
            user_uuid,
            data: { billing_details: billing }
        });
        updateBillingDetails(billing);
        toggle();
    };

    return (
        <Container className="billing">
            <Row>
                <Col>
                    <div className="back-btn no-text" onClick={toggle} />
                    <div className="title">{texts.mobile.title}</div>
                </Col>
            </Row>
            <Row>
                <Col>
                    <BillingForm
                        editMode={true}
                        onChange={(data) => setBilling(data)}
                        onValidate={setValid}
                    />
                </Col>
            </Row>
            <Row>
                <Col>
                    <StickyButton
                        onClick={() => {
                            setButtonDisabled(true);
                            onConfirm();
                        }}
                        text="confirm"
                        disabled={!valid || loading || buttonDisabled}
                    />
                </Col>
            </Row>
        </Container>
    );
};

export default Billing;
