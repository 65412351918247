import './style.scss';

import React, { useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';

import { QuizAnswers, StickyButton } from '../../components';
import { quiz } from '../../content.json';
import { trackEvent, isMatchedStylist, MIXPANEL_EVENTS } from 'services/mixpanel';
import { useCommonStore } from 'store/common/reducer';
import { Client, Stylist } from 'types/user';
import { ComponentType, Component, QuizQuestion } from 'types/utils';
import User from 'services/User';
import { useFlags } from 'launchdarkly-react-client-sdk';

interface IQuestion {
    done: boolean;
    questions: QuizQuestion[];
    replyQuestion: (answer: any) => void;
    nextQuestion: (bodyIssuesFlag: boolean) => void;
    stylist: Stylist;
    user: Client;
    loadInbox: () => void;
    selectChannel: (sid: string) => void;
    messages: any[];
    loading: boolean;
    session_id: string;
    price: number;
}

const Question: React.FC<IQuestion> = ({
    done,
    questions,
    replyQuestion,
    nextQuestion,
    stylist,
    user,
    loadInbox,
    selectChannel,
    messages,
    loading,
    session_id,
    price
}) => {
    const history = useHistory();
    const [component, setComponent] = useState<string>('');
    const [current, setCurrent] = useState<QuizQuestion>({
        type: '',
        content: '',
        title: '',
        note: '',
        quiz_steps_count: 0,
        current_quiz_step: 0,
        reply_options: []
    });
    const [answer, setAnswer] = useState<any>({});
    const [mandatory, setMandatory] = useState(false);
    const matches = useCommonStore((store) => store.stylistsMatchs);
    const [quizTypes, setQuizTypes] = useState<{ [key: string]: Component }>(quiz.types);
    const [bodyIssues, setBodyIssues] = useState<string>('');
    const bodyIssuesFlag = useFlags().bodyIssues;

    const mixPanelProps = {
        'User UUID': user.user_uuid ?? '',
        'User email': user.email ?? '',
        'Stylist name': stylist.name ?? '',
        'Stylist UUID': stylist.uuid ?? '',
        'Is stylist match': isMatchedStylist(matches, stylist.uuid ?? ''),
        'Session status': 'Ongoing',
        'Session UUID': session_id
    };

    useEffect(() => {
        nextQuestion(bodyIssuesFlag);
    }, []);

    useEffect(() => {
        if (done) {
            trackQuestionComplite();
            loadInbox();
        }
    }, [done]);

    useEffect(() => {
        if (done && messages.length) {
            const sid = messages.find((message) => message.stylist.uuid === stylist.uuid).sid;
            selectChannel(sid);
            history.push(`/chat/${stylist.uuid}/${user.user_uuid}`);
        }
    }, [messages]);

    useEffect(() => {
        const question = [...questions].pop();
        if (question) {
            const { component, mandatory } = quizTypes[question.type];
            setComponent(component);
            setAnswer(validateAnswer(component, question.type));
            setCurrent(question);
            setMandatory(mandatory);
        }
    }, [questions]);

    const validateAnswer = (component: string, type: string, reply: any = {}) => {
        const { value } = quizTypes[type];
        switch (component) {
            case 'Selectors':
            case 'Budget':
            case 'SingleSelect':
            case 'MultiSelect':
            case 'Grid':
                if (!reply.selected_reply_options || !reply.selected_reply_options.length) {
                    reply.selected_reply_options = value;
                }
                break;
            case 'Textarea':
            case 'Birthday':
            case 'Input':
            case 'Moodboards':
                if (Array.isArray(value)) {
                    if (!reply.selected_reply_options || !reply.selected_reply_options.length) {
                        reply.selected_reply_options = value;
                    }
                } else {
                    if ((!reply.reply || !reply.reply.length) && !reply.additional_information) {
                        reply.reply = value;
                    }
                }
                break;
        }
        return reply;
    };

    const onChange = (selection: any) => {
        setAnswer(selection);
    };

    const onSubmit = (reply: any) => {
        let quizAnswer = validateAnswer(component, current.type, reply ? reply : answer);

        if (current.type === 'body_issues') {
            const replyOption = quizAnswer.selected_reply_options.filter(
                (key: string) => key !== 'BODY_ISSUES_OTHER'
            );
            const issues = replyOption.map((key: string) => User.getStyleKey(key)).join(',');
            const hasNote = quizAnswer?.additional_information?.other_text.length > 0 ?? false;
            setBodyIssues(issues);
            trackQuestionView('body issues', issues.toLowerCase(), hasNote, 'Something else note');
        }

        if (current.type === 'body_issues_details') {
            const hasBodyNote = quizAnswer?.reply.length > 0 ?? false;
            trackQuestionView(
                'body issues follow up',
                bodyIssues.toLowerCase(),
                hasBodyNote,
                'Body issues note'
            );
        }

        quizAnswer = { ...quizAnswer, isBodyIssues: bodyIssuesFlag };

        replyQuestion(quizAnswer);
        setAnswer('');
    };

    const trackQuestionView = (
        questionName: string,
        issues: string,
        hasNote: boolean,
        noteField: string
    ) => {
        const properties = {
            'Question name': questionName,
            'Body issues': issues,
            [noteField]: hasNote,
            Action: buttonText()
        };
        trackEvent({
            name: MIXPANEL_EVENTS.QUIZ_QUESTION_VIEW,
            properties: { ...mixPanelProps, ...properties }
        });
    };

    const trackQuestionComplite = () => {
        const properties = {
            'Session count': user.bookings_count,
            'Payment amount': price,
            'Session amount': price
        };
        trackEvent({
            name: MIXPANEL_EVENTS.COMPLETED_A_QUIZ,
            properties: { ...mixPanelProps, ...properties }
        });
    };

    const buttonText = () => {
        if (current.current_quiz_step === current.quiz_steps_count - 1) {
            return quiz.questions.button.finish;
        }
        if (mandatory) {
            return quiz.questions.button.next;
        } else {
            let isNext = false;
            if (answer.length) isNext = true;
            if (answer.reply || answer.replyInstagram || answer.replyPinterest || answer.date)
                isNext = true;
            if (
                component === 'Textarea' &&
                answer.additional_information &&
                answer.additional_information.comments.length
            )
                isNext = true;
            if (answer.selected_reply_options && answer.selected_reply_options.length > 0)
                isNext = true;

            if (isNext) {
                if (
                    current.type === 'colors' &&
                    answer.selected_reply_options === quizTypes[current.type].value
                ) {
                    return quiz.questions.button.anything;
                } else {
                    return quiz.questions.button.next;
                }
            } else {
                if (current.type === 'colors') {
                    return quiz.questions.button.anything;
                } else {
                    return quiz.questions.button.skip;
                }
            }
        }
    };

    const Answers = QuizAnswers[component as ComponentType];
    const withButton = () => component !== 'SingleSelect' && component !== 'Moodboards';

    return (
        <Container className={`quiz-question ${withButton() ? 'with-button' : 'no-button'}`}>
            {current.type !== 'moodboards' && (
                <Row>
                    <Col>
                        <Col>
                            <p
                                className={`${
                                    current.type !== 'instagram' ? 'title' : 'titleInstagram'
                                }`}
                            >
                                {current.content}
                            </p>
                        </Col>
                    </Col>
                </Row>
            )}
            {current.type !== 'moodboards' && current.note && (
                <Row>
                    <Col>
                        <p className="note">{current.note}</p>
                    </Col>
                </Row>
            )}
            <Row>
                <Col>
                    {Answers && (
                        <Answers
                            type={current.type}
                            options={current.reply_options}
                            onChange={(data: any) => {
                                const reply = data.text ? { reply: data.text } : data;
                                component === 'SingleSelect' || component === 'Moodboards'
                                    ? onSubmit(reply)
                                    : current.type === 'instagram'
                                    ? setAnswer({ ...answer, replyInstagram: data.text })
                                    : onChange(reply);
                            }}
                            props={quizTypes[current.type].props || {}}
                            mandatory={quizTypes[current.type].mandatory}
                        />
                    )}
                </Col>
            </Row>
            {current.type === 'instagram' && (
                <Row>
                    <Col>
                        <Answers
                            type="pinterest"
                            options={[]}
                            onChange={(data: any) => {
                                setAnswer({ ...answer, replyPinterest: data.text });
                            }}
                            props={quiz.types['pinterest'].props as any}
                            mandatory={quiz.types['pinterest'].mandatory}
                        />
                    </Col>
                </Row>
            )}
            {withButton() && (
                <StickyButton
                    disabled={
                        mandatory
                            ? answer.length === 0 ||
                              answer.reply === '' ||
                              (answer.selected_reply_options &&
                                  answer.selected_reply_options.length === 0)
                            : !!loading
                    }
                    onClick={onSubmit}
                    text={buttonText()}
                    type="warning"
                />
            )}
        </Container>
    );
};

export default Question;
