import './style.scss';

import React, { useEffect } from 'react';
import { Container, Image, Nav, Navbar } from 'react-bootstrap';

import campaigns from '../../campaigns';
import { Page, StepsProgressBar } from '../../components';
import { logo } from '../../content';
import { Cookies, Style, Tracking } from '../../services';
import Formatter from '../../services/Formatter';
import Steps from './steps';
import { trackEvent, MIXPANEL_EVENTS } from 'services/mixpanel';
import { sentryException } from 'services/SentryLogging';
import { onboarding } from '../../content';

const OnBoarding = ({
    steps,
    current,
    bodyTypes,
    styles,
    brands,
    toggleModal,
    setMixpanelStore,
    history,
    location,
    setStep,
    gender,
    prevStep,
    styleIndex,
    prevStyle
}) => {
    const currentStep = steps[current];
    const campaign = Formatter.queryString(location.search).campaign;
    const campaignData = campaign && campaigns[campaign]?.onboarding[currentStep.name];
    const stepData = { ...onboarding[currentStep.name], ...campaignData };
    const StepComponent = Steps[currentStep.component];

    const updateStyle = async () => {
        try {
            const user = Cookies.get('user');

            Tracking.facebook('trackCustom', 'completeOnboardingt', {});
            Tracking.google({
                type: 'event',
                event: 'Funnel',
                data: { event_category: 'completeOnboarding' }
            });
            Tracking.google({
                type: 'event',
                event: 'conversion',
                data: { send_to: 'AW-870964131/griOCPewnKcBEKO3p58D' }
            });

            if (user.uuid) {
                await Style.updateStyle(user.uuid, {
                    gender,
                    tag_uuids: bodyTypes,
                    styles,
                    brands
                });
                const stylePreferredList = styles.reduce((acc, curr) => {
                    if (curr.rate === 2) acc.push(curr.name);
                    return acc;
                }, []);
                setMixpanelStore({ onboarding: { 'Preferred Styles': stylePreferredList } });
                trackEvent({
                    name: MIXPANEL_EVENTS.ONBOARDING_BRANDS_SELECTION,
                    properties: {
                        'Preferred Styles': stylePreferredList,
                        'Selected brand list': brands.map((brand) => brand.name)
                    }
                });
                trackEvent({
                    name: MIXPANEL_EVENTS.MEET_MY_MATCH,
                    properties: { 'Preferred Styles': stylePreferredList }
                });
                history.push({
                    pathname: '/stylistSearch'
                });
            } else {
                const stylePreferredList = styles.reduce((acc, curr) => {
                    if (curr.rate === 2) acc.push(curr.name);
                    return acc;
                }, []);
                setMixpanelStore({ onboarding: { 'Preferred Styles': stylePreferredList } });
                trackEvent({
                    name: MIXPANEL_EVENTS.MEET_MY_MATCH,
                    properties: { 'Preferred Styles': stylePreferredList }
                });
                trackEvent({
                    name: MIXPANEL_EVENTS.ONBOARDING_GENDER_SELECTION,
                    properties: {
                        'Preferred Styles': stylePreferredList,
                        'Selected brand list': brands.map((brand) => brand.name)
                    }
                });
                toggleModal({ type: 'Unlock' });
            }
        } catch (e) {
            sentryException(e, "Couldn't update user's quiz results");
        }
    };

    useEffect(() => {
        Tracking.tag({ event: 'ws.beginOnboardingQuiz' });
    }, []);

    useEffect(() => {
        history.replace(`/onboarding/${currentStep.name}`);
    }, [currentStep]);

    return (
        <Page header={false} footer={false} fixedHeader={false}>
            <Container className="onboarding no-padding" fluid>
                <Navbar className="nav-bar">
                    {current > 1 && (
                        <Nav.Item
                            className="mobile-back d-flex d-sm-none"
                            onClick={() =>
                                styleIndex > 0 && currentStep.name === 'style'
                                    ? prevStyle(styleIndex)
                                    : prevStep()
                            }
                        />
                    )}

                    <Navbar.Brand href="/" className="logo">
                        <Image src={logo.black} className="d-flex d-sm-none" />
                        <Image src={logo.text_black} className="d-none d-sm-flex" />
                    </Navbar.Brand>

                    <Nav.Item className="mobile-step-counter d-flex d-sm-none">
                        Step {current + 1} out of {steps.length}:{' '}
                        <strong>{steps[current].text}</strong>
                    </Nav.Item>
                </Navbar>

                <StepsProgressBar
                    steps={steps}
                    onClick={(step) => (step < current ? setStep(step) : null)}
                    onBack={() =>
                        styleIndex > 0 && currentStep.name === 'style'
                            ? prevStyle(styleIndex)
                            : prevStep()
                    }
                    current={current - 0.99}
                    animated={true}
                />

                <StepComponent data={stepData} onDone={updateStyle} />
            </Container>
        </Page>
    );
};

export default OnBoarding;
