import './style.scss';

import React, { useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useLocation } from 'react-router-dom';

import { Loader, Page } from 'components';
import { Stylist, Tracking } from 'services';
import Banner from './components/Banner';
import { useWithDispatch } from 'hooks';
import { useCommonStore } from 'store/common/reducer';
import { setCampaignBooking } from 'store/booking/actions';
import { loadStylistsNames, loadUserMatches } from 'store/common/actions';
import { loadFavoriteStylists } from 'store/user/actions';
import { loadFavoriteLooks } from 'redux/reducers/users/actions';
import { useDispatch } from 'react-redux';
import { Stylist as StylistType } from 'types/user';
import { useUserStore } from 'store/user/reducer';
import { trackEvent, MIXPANEL_EVENTS } from 'services/mixpanel';
import { Client } from 'types/user';
import { StylistsList } from './components/StylistsList';
import { sliceArrayByThree } from './utils/stylists-utils';

const StylistListPage: React.FC = ({}) => {
    const dispatch = useDispatch();
    const location = useLocation();
    const [showBanner, setShowBanner] = useState(true);
    const [stylistMatches, setStylistMatches] = useState<any[]>([]);
    const [moreStylists, setMoreStylists] = useState<any[]>([]);
    const [loading, setLoading] = useState(false);
    const mixpanelStore = useCommonStore((store) => store.mixpanelStore);
    const matches = useCommonStore((store) => store.stylistsMatchs);
    const user = useUserStore((store) => store.user);
    const loadStylistsNamesAction = useWithDispatch(loadStylistsNames);
    const loadUserMatchesAction = useWithDispatch(loadUserMatches);
    const loadFavoriteStylistsAction = useWithDispatch(loadFavoriteStylists);
    const setCampaignBookingAction = useWithDispatch(setCampaignBooking);

    const analyticsTrackMatchPageEvents = () => {
        Tracking.tag({
            event: 'eec.impressionView',
            ecommerce: {
                impressions: matches.map((match, index) => ({
                    id: match.uuid,
                    name: match.name,
                    category: '/category/stylist/',
                    list: 'Stylist Recommendations',
                    position: index
                }))
            }
        });
    };
    const mixpanelTrackMatchPageEvents = () => {
        trackEvent({
            name: MIXPANEL_EVENTS.STYLIST_MATCH_VIEWS,
            properties: {
                'Preferred Styles': mixpanelStore?.onboarding?.length
                    ? mixpanelStore.onboarding['Preferred Styles']
                    : '',
                'Stylist match names': matches.reduce((acc, curr) => {
                    if (curr?.name) {
                        acc.push(curr.name);
                    }
                    return acc;
                }, [] as string[]),
                'Stylist match UUIDs': matches.reduce((acc, curr) => {
                    if (curr?.uuid) acc.push(curr.uuid);
                    return acc;
                }, [] as string[])
            }
        });
    };

    useEffect(() => {
        dispatch(loadFavoriteLooks());
        loadFavoriteStylistsAction();
        setCampaignBookingAction();
    }, []);

    useEffect(() => {
        if (!matches.length) {
            loadUserMatchesAction(user as Client);
            loadStylistsNamesAction();
        }
    }, [user]);

    useEffect(() => {
        // @ts-ignore
        setCampaignBookingAction(location.state ? location.state.campaign : null);
    }, [location]);

    useEffect(() => {
        if (matches?.length) {
            setStylistMatches(matches);
            analyticsTrackMatchPageEvents();
            mixpanelTrackMatchPageEvents();
            loadMore(matches);
        }
    }, [matches]);

    const loadMore = async (initMatches: StylistType[]) => {
        try {
            setLoading(true);
            const { data } = await Stylist.all({ count: 15 });
            const stylistItemsArray = Array.isArray(data.items)
                ? data.items
                : Object.values(data.items);
            const stylistsList = [
                ...stylistItemsArray.filter(
                    (obj: StylistType) =>
                        initMatches.findIndex((match) => match.uuid === obj.uuid) < 0 &&
                        !obj.picture
                )
            ];
            const slicedArray = sliceArrayByThree(stylistsList);
            setMoreStylists(slicedArray);
            setLoading(false);
        } catch (e) {
            setLoading(false);
        }
    };
    return (
        <Page footer={false} className="no-padding">
            <Container className="match" fluid>
                {loading && <Loader />}
                {showBanner && <Banner onClose={() => setShowBanner(false)} />}
                <Row>
                    <Col className="results">
                        <Container>
                            <StylistsList
                                matchedStylists={stylistMatches}
                                moreStylists={moreStylists}
                            />
                        </Container>
                    </Col>
                </Row>
            </Container>
        </Page>
    );
};

export default StylistListPage;
