import './style.scss';

import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Container } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { MIXPANEL_EVENTS, trackEvent } from 'services/mixpanel';

import { Loader, Page } from '../../components';
import { getSDKAccessToken } from '../../services/sdk';

interface IClientChat {
    match: any;
    messages: [];
    cart: any | null;
    selectChannel: (channel: string | null) => void;
    updateItem: () => void;
    isTwilioInitialized: boolean;
    addToCart: (item: any, source: string) => void;
    removeFromCart: (item: any) => void;
    loadInbox: () => void;
}

const Chat: React.FC<IClientChat> = ({
    match,
    messages = [],
    cart,
    selectChannel,
    isTwilioInitialized,
    addToCart,
    removeFromCart,
    loadInbox
}) => {
    const history = useHistory();
    const chatRootElementRef = useRef<HTMLDivElement>(null);
    const pluginRef = useRef<any>(null);
    const [cartItems, setCartItems] = useState<string[]>(
        () => cart?.map(({ originalItemUnique, uuid }: any) => originalItemUnique || uuid) || []
    );

    const chatChannelProps = useMemo(() => {
        if (messages?.length && match?.params) {
            const { stylist_id: stylistId, user_id: userId } = match.params;
            const filteredChannel = messages.find(
                (message) => message['stylist']['uuid'] === stylistId
            );
            if (filteredChannel) {
                const {
                    sid,
                    stylist: { first_name, last_name }
                } = filteredChannel;
                return {
                    userId,
                    stylistId,
                    chatSid: sid,
                    stylistName: `${first_name} ${last_name}`
                };
            }
        }
    }, [messages, match]);

    const loaded = isTwilioInitialized && chatChannelProps;

    const addCartItem = useCallback(
        (itemId: string) => {
            setCartItems((cartItems) => {
                addToCart({ uuid: itemId }, 'session');

                return [...cartItems, itemId];
            });
        },
        [setCartItems, addToCart]
    );

    const removeCartItem = useCallback(
        (itemId: string) => {
            setCartItems((cartItems) => {
                const filtered = cartItems.filter((id) => id !== itemId);
                removeFromCart({ uuid: itemId });

                return [...filtered];
            });
        },
        [setCartItems, removeFromCart]
    );

    useEffect(() => {
        if (isTwilioInitialized && chatChannelProps) {
            const { userId, stylistId, chatSid, stylistName } = chatChannelProps;

            pluginRef.current && pluginRef.current.unmount();

            const plugin = new window.ClientChatPlugin({
                element: chatRootElementRef.current as HTMLElement,
                partnerToken: getSDKAccessToken(),
                partnerUserId: userId || '',
                cartItems,
                selectedSid: chatSid
            });

            plugin.on('cart-item-added', async (item: string) => {
                addCartItem(item);
            });
            plugin.on('cart-item-removed', async (item: string) => {
                removeCartItem(item);
            });
            plugin.on('item_clicked', async (item: string) => {
                history.push(`/item/${item}?context=session`);
            });
            plugin.on('end_session_response', (response: { [key: string]: string }) => {
                trackEvent({
                    name: MIXPANEL_EVENTS.END_SESSION_RESPONSE,
                    properties: { 'End session response': response.reply }
                });
            });
            plugin.on('session_ended', () => {
                trackEvent({ name: MIXPANEL_EVENTS.SESSION_ENDED, properties: {} });
            });
            plugin.on('look_views', (response: { [key: string]: string }) => {
                trackEvent({
                    name: MIXPANEL_EVENTS.LOOK_VIEWS,
                    properties: {
                        'Look UUID': response.collectionId,
                        'Look source': 'session',
                        'Look URL': `${window.origin}/feed/outfit/${response.collectionId}`,
                        'Stylist UUID': stylistId,
                        'Stylist name': stylistName
                    }
                });
            });

            plugin.mountApp();
            pluginRef.current = plugin;
        }
    }, [
        isTwilioInitialized,
        chatChannelProps,
        history,
        cartItems,
        addCartItem,
        removeCartItem,
        pluginRef
    ]);

    useEffect(() => {
        loadInbox();
        return () => selectChannel(null);
    }, [loadInbox, selectChannel]);

    return (
        <Page className="chat" footer={false}>
            {!loaded && <Loader />}
            <Container className={loaded ? 'show' : 'hide'}>
                <div className="chat-container" ref={chatRootElementRef} />
            </Container>
        </Page>
    );
};

export default Chat;
