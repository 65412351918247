import './style.scss';

import { useIsMobile } from 'hooks';
import React, { useEffect } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';

import { Loader, Page, StickyButton } from '../../components';
import { giftcards } from '../../content';
import { Validator } from '../../services';
import Sections from './Sections';

const Checkout = ({
    giftcard,
    giftcardState,
    updateGiftCardState,
    giftCardError,
    loading,
    result,
    refreshUser,
    user
}) => {
    const history = useHistory();
    const isMobile = useIsMobile();
    useEffect(() => {
        updateGiftCardState(isMobile && giftcardState > 1 ? 1 : 0);
        if (user) refreshUser();
    }, []);

    useEffect(() => {
        if (result) history.push('/gift-cards/confirmation');
    }, [result]);

    const onSubmit = () => {
        if (!isMobile || (isMobile && giftcardState === 1)) {
            const { amount, recipientName, recipientEmail, senderName, senderEmail } = giftcard;
            const errors = {
                recipientName: Validator.name(recipientName, false),
                senderName: Validator.name(senderName, false),
                recipientEmail: Validator.email(recipientEmail),
                senderEmail: Validator.email(senderEmail),
                amount: Validator.number(amount)
            };
            const filtered = Object.keys(errors)
                .filter((key) => !!errors[key])
                .reduce((obj, key) => {
                    obj[key] = errors[key];
                    return obj;
                }, {});

            if (!!amount && !Object.keys(filtered).length) {
                updateGiftCardState(isMobile ? giftcardState + 1 : 1);
            } else {
                giftCardError(filtered);
            }
        } else {
            updateGiftCardState(isMobile ? giftcardState + 1 : 1);
        }
    };

    return (
        <Page footer={false}>
            <Container className="gift-cards-checkout">
                {loading && <Loader />}
                {giftcardState > 0 && (
                    <div
                        className="back-btn"
                        id="back"
                        onClick={() =>
                            updateGiftCardState(giftcardState > 0 ? giftcardState - 1 : 0)
                        }
                    />
                )}
                <Row className="d-flex d-sm-none">
                    <Col>
                        {giftcardState === 0 && <Sections.Designs />}
                        {giftcardState === 1 && <Sections.Details />}
                        {giftcardState === 2 && <Sections.CheckoutForm />}
                    </Col>
                </Row>
                <Row className="d-none d-sm-flex">
                    <Col>{giftcardState ? <Sections.Overview /> : <Sections.Designs />}</Col>
                    <Col>
                        {giftcardState ? (
                            <Sections.CheckoutForm />
                        ) : (
                            <Sections.Details onSubmit={onSubmit} />
                        )}
                    </Col>
                </Row>
                {giftcardState < 2 && (
                    <StickyButton
                        className="d-block d-sm-none"
                        onClick={onSubmit}
                        text={giftcards.checkout.continue}
                    />
                )}
            </Container>
        </Page>
    );
};

export default Checkout;
