import React, { useEffect } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';

import { MatchItem } from '../../../components';
import Content from '../../../content';
import { Tracking } from '../../../services';

const Matches = ({
    user,
    matches = [],
    stylist,
    favoriteStylists = [],
    updateFavoriteStylist,
    stylistsMatchs = [],
    loadUserMatches
}) => {
    const history = useHistory();

    useEffect(() => {
        if (stylistsMatchs.length && stylist.uuid) {
            document.addEventListener('scroll', onPageBottom);
        }
        return () => {
            document.removeEventListener('scroll', onPageBottom);
        };
    }, [stylistsMatchs, stylist]);

    useEffect(() => {
        if (user) loadUserMatches(user);
    }, [user]);

    const onPageBottom = () => {
        if (window.innerHeight + window.scrollY >= document.body.offsetHeight) {
            Tracking.tag({
                event: 'eec.impressionView',
                ecommerce: {
                    impressions: stylistsMatchs
                        .filter((match) => match.uuid !== stylist.uuid)
                        .map((match, index) => ({
                            id: match.uuid,
                            name: match.name,
                            category: '/category/stylist/',
                            list: 'Other Recommended Stylists',
                            position: index
                        }))
                }
            });
        }
    };

    const selectStylist = ({ uuid, name }) => {
        Tracking.tag({
            event: 'eec.impressionClick',
            ecommerce: {
                click: {
                    actionField: { list: 'Other Recommended Stylists' },
                    products: [
                        {
                            id: uuid,
                            name,
                            category: '/category/stylist/'
                        }
                    ]
                }
            }
        });
        history.push(`/stylist/${uuid}/profile`);
    };

    return (
        <>
            <div className="title">{Content.stylist.matches}</div>
            <Row className="matches">
                {stylistsMatchs
                    .filter((item) => item.uuid !== stylist.uuid)
                    .map((match) => (
                        <Col xs={12} md={4} key={match.uuid}>
                            <MatchItem
                                match={match}
                                onClick={selectStylist}
                                onFavorite={updateFavoriteStylist}
                                isFavorite={favoriteStylists.find(
                                    (favorite) => favorite.uuid === match.uuid
                                )}
                            />
                        </Col>
                    ))}
            </Row>
        </>
    );
};

export default Matches;
