import { IActionWithPayload } from '../types';
import { Option } from 'types/utils';
import { Stylist } from 'types/user';

export enum ActionTypes {
    LOAD_STYLISTS = 'LOAD_STYLISTS',
    LOAD_STYLISTS_SUCCESS = 'LOAD_STYLISTS_SUCCESS',
    LOAD_STYLISTS_FAILED = 'LOAD_STYLISTS_FAILED',
    CLEAR_FILTERS = 'CLEAR_FILTERS',
    LOAD_MATCHES = 'LOAD_MATCHES',
    LOAD_MATCHES_SUCCESS = 'LOAD_MATCHES_SUCCESS',
    LOAD_MATCHES_FAILED = 'LOAD_MATCHES_FAILED',
    SET_ZE_ON = 'SET_ZE_ON',
    SET_ZE_OFF = 'SET_ZE_OFF',
    SET_BOOKING_SOURCE = 'SET_BOOKING_SOURCE',
    SET_MIXPANEL_STORE = 'SET_MIXPANEL_STORE'
}

export interface MixpanelStruct {
    [index: string]: { [key: string]: string | string[] | boolean | number };
}

export interface CommonState {
    stylists: Option[];
    stylistsMatchs: Stylist[];
    bookingSource: string | undefined;
    zeStatus: boolean;
    mixpanelStore: MixpanelStruct;
}

export type Actions =
    | IActionWithPayload<ActionTypes.LOAD_STYLISTS_SUCCESS, Option[]>
    | IActionWithPayload<ActionTypes.LOAD_STYLISTS_FAILED, unknown>
    | IActionWithPayload<ActionTypes.LOAD_MATCHES_SUCCESS, Stylist[]>
    | IActionWithPayload<ActionTypes.SET_ZE_ON, boolean>
    | IActionWithPayload<ActionTypes.SET_ZE_OFF, boolean>
    | IActionWithPayload<ActionTypes.SET_MIXPANEL_STORE, MixpanelStruct>
    | IActionWithPayload<ActionTypes.SET_BOOKING_SOURCE, string>;
