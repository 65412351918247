import './style.scss';

import { useIsMobile } from 'hooks';
import React, { useEffect } from 'react';
import { Card, Col, Container, Row } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';

import { payment } from '../../content';
import { Tracking } from '../../services';
import { Loader } from '../';
import Sections from './sections';
import {
    trackEvent,
    isMatchedStylist,
    registerProperties,
    MIXPANEL_EVENTS,
    MIXPANEL_PROPERTIES
} from 'services/mixpanel';
import { useCommonStore } from 'store/common/reducer';

const Payment = ({
    plan,
    goal,
    setModel,
    campaign,
    setPlanModel,
    setPrice,
    submitCoupon,
    loading,
    result,
    clearResult,
    stylist,
    getUserCards,
    setSessionId,
    resetQuiz,
    user,
    mixpanelStore
}) => {
    const history = useHistory();
    const isMobile = useIsMobile();
    const matches = useCommonStore((store) => store.stylistsMatchs);

    const paymentMixpanelEvents = (result) => {
        const date = new Date().toISOString();
        const bookingsCount =
            typeof user?.bookings_count === 'string'
                ? parseInt(user?.bookings_count)
                : user?.bookings_count;

        registerProperties({
            [MIXPANEL_PROPERTIES.LAST_BOOKING_DATE]: date,
            [MIXPANEL_PROPERTIES.LAST_BOOKING_AMOUNT]: result.price
        });
        if (result.subscription !== null)
            registerProperties({
                [MIXPANEL_PROPERTIES.ACTIVE_SUBSCRIPTION]: 'Yes'
            });
        if (bookingsCount === 0) {
            registerProperties({ [MIXPANEL_PROPERTIES.FIRST_BOOKING_DATE]: date });
        }
        trackEvent({
            name: MIXPANEL_EVENTS.PAYMENT_COMPLETED,
            properties: {
                ...mixpanelStore.onboarding,
                [MIXPANEL_PROPERTIES.PAYMENT_TYPE]: 'order',
                [MIXPANEL_PROPERTIES.PLAN_CYCLE]:
                    result.subscription === null ? 'single' : 'subscription',
                [MIXPANEL_PROPERTIES.USED_COUPON]: result.coupon === null ? 'No' : 'yes',
                [MIXPANEL_PROPERTIES.COUPON_NAME]: result.coupon === null ? '' : result.coupon,
                [MIXPANEL_PROPERTIES.PAYMENT_METHOD]: 'stripe',
                [MIXPANEL_PROPERTIES.PAYMENT_AMOUNT]: result.price
            }
        });
        trackEvent({
            name: MIXPANEL_EVENTS.SESSION_STARTED,
            properties: {
                [MIXPANEL_PROPERTIES.SESSION_UUID]: result.session_uuid,
                [MIXPANEL_PROPERTIES.USER_UUID]: user.user_uuid,
                [MIXPANEL_PROPERTIES.USER_EMAIL]: user.email,
                [MIXPANEL_PROPERTIES.SESSION_COUNT]: bookingsCount + 1,
                [MIXPANEL_PROPERTIES.SESSION_STATUS]: 'Ongoing',
                [MIXPANEL_PROPERTIES.SESSION_AMOUNT]: result.price,
                [MIXPANEL_PROPERTIES.IS_TRIAL]: result.status === 'trailing',
                [MIXPANEL_PROPERTIES.PLAN_TYPE]: result.plan_name,
                [MIXPANEL_PROPERTIES.STYLIST_NAME]: stylist.name,
                [MIXPANEL_PROPERTIES.STYLIST_UUID]: result.stylist_uuid,
                [MIXPANEL_PROPERTIES.IS_SESSION_STYLIST_MATCH]: isMatchedStylist(
                    matches,
                    stylist.uuid
                )
            }
        });
    };

    const paymentGAEvents = (result) => {
        Tracking.google({
            type: 'event',
            event: 'booking_completed',
            data: {
                booking_amount: result.price,
                plan_type: result.plan_name,
                plan_model: result.subscription === null ? 'single' : 'subscription',
                client_full_name: ''.concat(user.firstName, ' ', user.lastName),
                client_uuid: user.uuid,
                client_email: user.email,
                stylist_name: stylist.name,
                stylist_uuid: result.stylist_uuid
            }
        });
    };

    const trackingPaymntsEvent = () => {
        Tracking.tag({
            event: 'eec.checkout',
            ecommerce: {
                checkout: {
                    actionField: { step: 1 },
                    products: [
                        {
                            id: stylist.uuid,
                            name: stylist.name,
                            category: '/category/stylist/',
                            variant: plan.value,
                            quantity: 1,
                            dimension3: 'Ecommerce'
                        }
                    ]
                }
            }
        });
    };

    useEffect(() => {
        const initModel =
            goal.value === 'closet_cleanout' ||
            plan.value === 'lux' ||
            stylist.is_celebrity ||
            campaign
                ? 'onetime'
                : 'monthly';
        setPlanModel(initModel);
        setModel(initModel);
        trackEvent({
            name: MIXPANEL_EVENTS.CHECKOUT_VIEWS,
            properties: {
                ...mixpanelStore.onboarding
            }
        });

        setPrice(plan.price[initModel]);
        getUserCards();
    }, []);

    useEffect(() => {
        clearResult();
        if (result) {
            trackingPaymntsEvent();
            paymentMixpanelEvents(result);
            paymentGAEvents(result);
            resetQuiz();
            setSessionId(result.session_uuid);
            history.push('/thank-you');
        }
    }, [result]);

    const onSelect = (selection) => {
        if (selection === 'monthly') {
            submitCoupon('monthly-session');
        } else {
            submitCoupon();
        }
        setPrice(plan.price[selection]);
        setPlanModel(selection);
        setModel(selection);
    };
    return (
        <Container className="payment">
            {loading && <Loader />}
            <Row>
                <Col md={6} className="d-none d-sm-flex">
                    <Card>
                        <Card.Header>{payment.summary.title}</Card.Header>
                        <Card.Body>
                            <Sections.Summary onSelect={onSelect} stylist={stylist} />
                        </Card.Body>
                    </Card>
                </Col>

                <Col md={6} className="d-none d-sm-flex">
                    <Card>
                        <Card.Header>{payment.details.title}</Card.Header>
                        <Card.Body>
                            <Sections.Details />
                        </Card.Body>
                    </Card>
                </Col>

                {isMobile && (
                    <Col xs={12} className="d-flex d-sm-none">
                        <Card>
                            <Card.Body>
                                <Sections.MobileCheckout onSelect={onSelect} stylist={stylist} />
                            </Card.Body>
                        </Card>
                    </Col>
                )}
            </Row>
        </Container>
    );
};

export default Payment;
