import './style.scss';

import PrivateRoute from 'components/PrivateRoute';
import { useWithDispatch } from 'hooks';
import { useInitCommonReducer } from 'hooks/app';
import { useFlags, useLDClient, withLDProvider } from 'launchdarkly-react-client-sdk';
import React, { useEffect, useState } from 'react';
import { Redirect, Route, Switch, useLocation } from 'react-router-dom';
import { ICredentials } from 'services/auth-service';
import { toggleZEWidget } from 'store/common/actions';
import { initalizedTwilio } from 'store/services/actions';
import { useServicesStore } from 'store/services/reducer';
import { login, logout } from 'store/user/actions';
import { useUserStore } from 'store/user/reducer';
import { Client } from 'types/user';

import Routes from './routes';
import { Cookies, Tracking } from './services';
import { extractUserData } from './services/utils/user-utils';
import * as Views from './views';

const cookie = Cookies.get('user');
const ngUser = localStorage.getItem('user') ?? '';

const App: React.FC = () => {
    const [path, setPath] = useState<string | null>(null);
    const [isLoaded, setIsLoaded] = useState<boolean>(false);
    const location = useLocation();
    const user = useUserStore((store) => store?.user);
    const isTwilioInitialized = useServicesStore((store) => store?.isTwilioInitialized);
    const flags = useFlags();
    const loginAction = useWithDispatch(login);
    const logoutAction = useWithDispatch(logout);
    const initalizedTwilioAction = useWithDispatch(initalizedTwilio);
    const toggaleZEWidgetAction = useWithDispatch(toggleZEWidget);
    const howItWorksFlag = flags.howItWorks;
    const consumerStylistMatch = flags.consumerStylistMatch;
    const haveStoredUserDetails = !user && ((cookie && cookie.token) || ngUser);
    const ldClient = useLDClient();
    const ldFlags = useFlags();
    useInitCommonReducer();

    const loginWithToken = async (token: Partial<ICredentials>) => {
        const loggedInUser = await loginAction(token);
        if (loggedInUser) {
            const userDetails = extractUserData(loggedInUser as Client);
            if (ldClient && userDetails) {
                await ldClient.identify(userDetails, undefined);
                setIsLoaded(true);
            }
        } else setIsLoaded(true);
    };

    useEffect(() => {
        if (!user && cookie && !cookie.token && !ngUser) {
            logoutAction();
            setIsLoaded(true);
        }
    }, []);

    useEffect(() => {
        if (haveStoredUserDetails) {
            const token = cookie.token ? cookie.token : JSON.parse(ngUser)?.token;
            loginWithToken({ token });
        }
    }, [ldClient]);

    useEffect(() => {
        if (user) {
            !isTwilioInitialized && initalizedTwilioAction();
        }
    }, [user]);

    useEffect(() => {
        if (location.pathname !== path) {
            window.scrollTo(0, 0);
            Tracking.tag({
                event: 'ws.virtualPageView',
                pagePath: location.pathname,
                pageTitle: document.title
            });
            setPath(location.pathname);
            toggaleZEWidgetAction(location.pathname);
        }
    }, [location]);

    return (
        <div className="app">
            {isLoaded && ldClient && (
                <Switch>
                    <Route exact path="/" children={<Views.Landing />} />
                    {howItWorksFlag && (
                        <Route exact path="/how-it-works" component={Routes.HowItWorks} />
                    )}
                    {ldFlags.customerUseLegacyOnboarding ? (
                        <Route path="/onboarding/:step?" children={<Views.OnBoarding_legacy />} />
                    ) : (
                        <Route path="/onboarding/">
                            <Route
                                path="/onboarding/"
                                exact={true}
                                children={<Views.OnBoardingIntro />}
                            />
                            <Route path="/onboarding/:step" children={<Views.OnBoarding />} />
                        </Route>
                    )}

                    {consumerStylistMatch ? (
                        <PrivateRoute
                            exact
                            path="/stylistSearch"
                            component={Routes.StylistListPage}
                        />
                    ) : (
                        <PrivateRoute exact path="/stylistSearch" component={Routes.StylistMatch} />
                    )}
                    <Route path="/stylist/:id/profile" children={<Views.Stylist />} />
                    <Route exact path="/terms" children={<Views.Terms />} />
                    <Route exact path="/privacy" children={<Views.Privacy />} />
                    <Route exact path="/pricing" children={<Routes.Pricing />} />
                    <Route exact path="/our-story" children={<Views.OurStory />} />
                    <PrivateRoute exact path="/goals" component={Views.GoalsSelect} />
                    <PrivateRoute exact path="/plans" component={Views.GoalsPlans} />
                    <PrivateRoute exact path="/checkout" component={Views.GoalsCheckout} />
                    <PrivateRoute exact path="/quiz" component={Views.Quiz} />
                    <PrivateRoute exact path="/thank-you" component={Views.QuizWelcome} />
                    <Route exact path="/gift-cards" children={<Views.GiftCardsMain />} />
                    <Route
                        exact
                        path="/gift-cards/checkout"
                        children={<Views.GiftCardsCheckout />}
                    />
                    <Route
                        exact
                        path="/gift-cards/confirmation"
                        children={<Views.GiftCardConfirmation />}
                    />
                    <Route exact path="/feed" children={<Views.FeedPage />} />
                    <Route exact path="/feed/outfit/:outfit_uuid" children={<Views.OutfitPage />} />
                    <Route
                        exact
                        path="/feed/outfit/:outfit_uuid/item/:item_uuid"
                        children={<Views.Item />}
                    />
                    <PrivateRoute
                        exact
                        path="/feed/outfit/:outfit_uuid/item/:item_uuid/checkout"
                        component={Views.ItemCheckout}
                    />
                    <PrivateRoute
                        exact
                        path="/feed/outfit/:outfit_uuid/item/:item_uuid/confirmation"
                        component={Views.ItemConfirmation}
                    />
                    <Route exact path="/item/:item_uuid" children={<Views.Item />} />
                    <PrivateRoute
                        exact
                        path="/item/:item_uuid/checkout"
                        component={Views.ItemCheckout}
                    />
                    <PrivateRoute
                        exact
                        path="/item/:item_uuid/confirmation"
                        component={Views.ItemConfirmation}
                    />
                    <PrivateRoute exact path="/favorites" component={Views.Favorites} />
                    <Route exact path="/favorites/outfit/:outfit_uuid">
                        <Redirect to="/favorites" />
                    </Route>
                    <PrivateRoute
                        exact
                        path="/favorites/outfit/:outfit_uuid/item/:item_uuid"
                        component={Views.Item}
                    />
                    <PrivateRoute
                        exact
                        path="/favorites/outfit/:outfit_uuid/item/:item_uuid/checkout"
                        component={Views.ItemCheckout}
                    />
                    <PrivateRoute
                        exact
                        path="/favorites/outfit/:outfit_uuid/item/:item_uuid/confirmation"
                        component={Views.ItemConfirmation}
                    />
                    <PrivateRoute exact path="/shopping-list" component={Views.ShoppingList} />
                    <Route exact path="/shopping-list/outfit/:outfit_uuid">
                        <Redirect to="/shopping-list" />
                    </Route>
                    <PrivateRoute
                        exact
                        path="/shopping-list/item/:item_uuid"
                        component={Views.Item}
                    />
                    <PrivateRoute
                        exact
                        path="/shopping-list/item/:item_uuid/checkout"
                        component={Views.ItemCheckout}
                    />
                    <PrivateRoute
                        exact
                        path="/shopping-list/item/:item_uuid/confirmation"
                        component={Views.ItemConfirmation}
                    />
                    <PrivateRoute exact path="/inbox" component={Routes.Inbox} />
                    <PrivateRoute exact path="/chat/:stylist_id/:user_id" component={Views.Chat} />
                    <Route
                        path="/gifts"
                        render={(props) => <Routes.Campaign {...props} campaign="gifts" />}
                    />
                    <Route
                        path="/wedding"
                        render={(props) => <Routes.Campaign {...props} campaign="wedding" />}
                    />
                    <PrivateRoute path="/orders" component={Views.Orders} />
                    <PrivateRoute path="/profile/:tab?" component={Views.Profile} />
                    <PrivateRoute path="/settings/:tab?" component={Views.Settings} />
                    <Route>
                        <Redirect to="/" />
                    </Route>
                </Switch>
            )}
        </div>
    );
};

export default withLDProvider({
    clientSideID: process.env.REACT_APP_LAUNCHDARKLEY_SDK_KEY ?? '',
    user: {
        key: '00000000-0000-0000-a'
    }
})(App);
