import './style.scss';

import React, { Component } from 'react';
import { Container, Image, Nav, Navbar } from 'react-bootstrap';
import { MIXPANEL_EVENTS, trackEvent } from 'services/mixpanel';
import { sentryException } from 'services/SentryLogging';

import campaigns from '../../campaigns';
import { Page, StepsProgressBar } from '../../components';
import { logo, onboarding } from '../../content';
import { Cookies, Session, Style, Tracking } from '../../services';
import Formatter from '../../services/Formatter';
import Steps from './steps';

class OnBoarding extends Component {
    componentWillMount() {
        const {
            resetOnboarding,
            location: { pathname }
        } = this.props;

        if (pathname === '/onboarding/intro') {
            trackEvent({
                name: MIXPANEL_EVENTS.ONBOARDING_INTRO_VIEWS,
                properties: { Platform: 'web' }
            });
            Session.remove('onboarding_legacy');
            resetOnboarding();
        }

        Tracking.tag({ event: 'ws.beginOnboardingQuiz' });
    }

    updateStyle = async () => {
        try {
            const { gender, bodyTypes, styles, brands, history, toggleModal, setMixpanelStore } =
                this.props;
            const user = Cookies.get('user');

            Tracking.facebook('trackCustom', 'completeOnboardingt', {});
            Tracking.google({
                type: 'event',
                event: 'Funnel',
                data: { event_category: 'completeOnboarding' }
            });
            Tracking.google({
                type: 'event',
                event: 'conversion',
                data: { send_to: 'AW-870964131/griOCPewnKcBEKO3p58D' }
            });

            if (user.uuid) {
                await Style.updateStyle(user.uuid, {
                    gender,
                    tag_uuids: bodyTypes,
                    styles,
                    brands
                });
                const stylePreferredList = styles.reduce((acc, curr) => {
                    if (curr.rate === 2) acc.push(curr.name);
                    return acc;
                }, []);
                setMixpanelStore({ onboarding: { 'Preferred Styles': stylePreferredList } });
                trackEvent({
                    name: MIXPANEL_EVENTS.ONBOARDING_BRANDS_SELECTION,
                    properties: {
                        'Preferred Styles': stylePreferredList,
                        'Selected brand list': brands.map((brand) => brand.name)
                    }
                });
                trackEvent({
                    name: MIXPANEL_EVENTS.MEET_MY_MATCH,
                    properties: { 'Preferred Styles': stylePreferredList }
                });
                history.push({
                    pathname: '/stylistSearch'
                });
            } else {
                const stylePreferredList = styles.reduce((acc, curr) => {
                    if (curr.rate === 2) acc.push(curr.name);
                    return acc;
                }, []);
                setMixpanelStore({ onboarding: { 'Preferred Styles': stylePreferredList } });
                trackEvent({
                    name: MIXPANEL_EVENTS.MEET_MY_MATCH,
                    properties: { 'Preferred Styles': stylePreferredList }
                });
                trackEvent({
                    name: MIXPANEL_EVENTS.ONBOARDING_GENDER_SELECTION,
                    properties: {
                        'Preferred Styles': stylePreferredList,
                        'Selected brand list': brands.map((brand) => brand.name)
                    }
                });
                toggleModal({ type: 'Unlock' });
            }
        } catch (e) {
            sentryException(e, "Couldn't update user's quiz results");
        }
    };

    render() {
        let {
            steps,
            current,
            history,
            location,
            setStep,
            gender,
            prevStep,
            styleIndex,
            prevStyle
        } = this.props;

        const currentStep = steps.find((item) => item.id === current);
        const pathname = `/onboarding/${currentStep.name}`;
        if (history.location.pathname !== pathname) history.replace({ pathname });
        steps = steps.filter((step) => step.id !== 0);
        if (gender === 'MALE') steps = steps.filter((step) => step.id !== 2);
        const StepComponent = Steps[currentStep.component];
        let stepData = onboarding[currentStep.name];
        if (Formatter.queryString(location.search).campaign) {
            const campaign = campaigns[Formatter.queryString(location.search).campaign].onboarding;
            stepData = { ...stepData, ...campaign[currentStep.name] };
        }

        return (
            <Page header={false} footer={false} fixedHeader={false}>
                <Container className="onboarding no-padding" fluid>
                    <Navbar className="nav-bar">
                        {current > 1 && (
                            <Nav.Item
                                className="mobile-back d-flex d-sm-none"
                                onClick={() =>
                                    styleIndex > 0 && currentStep.name === 'style'
                                        ? prevStyle(styleIndex)
                                        : prevStep()
                                }
                            />
                        )}

                        <Navbar.Brand href="/" className="logo">
                            <Image src={logo.black} className="d-flex d-sm-none" />
                            <Image src={logo.text_black} className="d-none d-sm-flex" />
                        </Navbar.Brand>

                        {current > 0 && (
                            <Nav.Item className="mobile-step-counter d-flex d-sm-none">
                                Step {current} out of {steps.length}:{' '}
                                <strong>{steps[current - 1].text}</strong>
                            </Nav.Item>
                        )}
                    </Navbar>

                    {current > 0 && (
                        <StepsProgressBar
                            steps={steps}
                            onClick={(step) => (step < current ? setStep(step) : null)}
                            onBack={() =>
                                styleIndex > 0 && currentStep.name === 'style'
                                    ? prevStyle(styleIndex)
                                    : prevStep()
                            }
                            current={current - 0.99}
                            animated={true}
                        />
                    )}

                    <StepComponent data={stepData} onDone={this.updateStyle} />
                </Container>
            </Page>
        );
    }
}

export default OnBoarding;
