import React, { useEffect } from 'react';
import { Button, Col, Row } from 'react-bootstrap';

import { StickyButton, StyleSelector } from '../../../components';
import { Style as StyleSrv } from '../../../services';
import { trackEvent, MIXPANEL_EVENTS } from 'services/mixpanel';

const Style = ({
    gender,
    setStylesList,
    data: { question, answers, button },
    selectStyle,
    styleIndex,
    stylesList,
    styles,
    nextStep,
    nextStyle
}) => {
    useEffect(() => {
        loadBoards();
    }, []);

    const loadBoards = async () => {
        const { data } = await StyleSrv.getMoodBoards(gender === 'neutral' ? 'female' : gender);
        setStylesList(data);
    };

    if (stylesList[styleIndex])
        trackEvent({
            name: MIXPANEL_EVENTS.ONBOARDING_STYLE_VIEWS,
            properties: { 'Page name': stylesList[styleIndex].style }
        });

    return (
        <Row className="style">
            <Col className="content">
                <StyleSelector
                    styles={stylesList}
                    question={question}
                    answers={answers}
                    onSelect={selectStyle}
                    current={styleIndex}
                    selections={styles.reduce((ac, a) => ({ ...ac, [a.name]: a.rate }), {})}
                />
                {styles.length > 5 && (
                    <span>
                        <Button
                            variant="warning"
                            id="next"
                            className="d-none d-sm-flex"
                            onClick={() =>
                                styleIndex < stylesList.length - 1
                                    ? nextStyle(styleIndex)
                                    : nextStep()
                            }
                        >
                            {button.skip}
                        </Button>
                        <StickyButton
                            className="d-flex d-sm-none"
                            id="next"
                            onClick={() => {
                                trackEvent({
                                    name: MIXPANEL_EVENTS.ONBOARDING_STYLE_SELECTION,
                                    properties: {
                                        'Page name': stylesList[styleIndex].style,
                                        'Style selection': 'skip'
                                    }
                                });
                                styleIndex < stylesList.length - 1
                                    ? nextStyle(styleIndex)
                                    : nextStep();
                            }}
                            text={button.skip}
                            type="warning"
                        />
                    </span>
                )}
            </Col>
        </Row>
    );
};

export default Style;
