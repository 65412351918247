import { onboarding } from '../../../content';
import { Session } from '../../../services';
import {
    NEXT_STYLE,
    PREV_STYLE,
    RESET_ONBOARDING,
    SET_BODY_TYPES,
    SET_BODY_TYPES_LIST,
    SET_BRANDS,
    SET_BRANDS_LIST,
    SET_GENDER,
    SET_STEP,
    SET_STYLES,
    SET_STYLES_LIST
} from './actions';

const initialState = {
    steps: onboarding.steps[Session.get('onboarding').gender || 'female'],
    current: Session.get('onboarding').step || 0,
    gender: Session.get('onboarding').gender || '',
    styleIndex: 0,
    stylesList: [],
    styles: Session.get('onboarding').styles || [],
    bodyTypeList: [],
    bodyTypes: Session.get('onboarding').bodyTypes || [],
    brandsList: [],
    brands: Session.get('onboarding').brands || []
};

export default function (state = initialState, action) {
    switch (action.type) {
        case SET_STEP: {
            const { step } = action.payload;
            return {
                ...state,
                current: step
            };
        }
        case SET_GENDER: {
            const { gender } = action.payload;
            return {
                ...state,
                gender,
                steps: onboarding.steps[gender],
                styles: [],
                stylesList: [],
                styleIndex: 0,
                bodyTypeList: [],
                bodyTypes: [],
                brandsList: [],
                brands: []
            };
        }
        case SET_STYLES_LIST: {
            const { stylesList } = action.payload;
            return {
                ...state,
                stylesList
            };
        }
        case SET_STYLES: {
            const { styles } = action.payload;
            return {
                ...state,
                styles
            };
        }
        case NEXT_STYLE: {
            const { current } = action.payload;
            return {
                ...state,
                styleIndex: current + 1
            };
        }
        case PREV_STYLE: {
            const { current } = action.payload;
            return {
                ...state,
                styleIndex: current > 0 ? current - 1 : current
            };
        }
        case SET_BRANDS_LIST: {
            const { brands } = action.payload;
            return {
                ...state,
                brandsList: brands
            };
        }
        case SET_BRANDS: {
            const { brands } = action.payload;
            return {
                ...state,
                brands
            };
        }
        case SET_BODY_TYPES_LIST: {
            const { bodyTypeList } = action.payload;
            return {
                ...state,
                bodyTypeList
            };
        }
        case SET_BODY_TYPES: {
            const { bodyTypes } = action.payload;
            return {
                ...state,
                bodyTypes
            };
        }
        case RESET_ONBOARDING: {
            return {
                ...state,
                gender: '',
                styles: [],
                bodyTypes: [],
                current: 0
            };
        }

        default:
            return state;
    }
}
