import './style.scss';

import React, { useEffect, useState } from 'react';
import { Carousel, Col, Container, Image, Row } from 'react-bootstrap';

import RadioButton from '../RadioButton';

const Moodboards = ({ options, onChange, props }) => {
    const [current, setCurrent] = useState(0);
    const [reply, setReply] = useState({});

    useEffect(() => {
        setReply({});
    }, [options]);

    const onSelect = (selection) => {
        setReply({
            ...reply,
            [options[current].key]: selection
        });
        if (current === options.length - 1) {
            setTimeout(
                () =>
                    onChange({
                        selected_reply_options: Object.keys(reply).map((key) => ({
                            key,
                            selected_reply_options: [reply[key]]
                        }))
                    }),
                500
            );
        } else {
            setCurrent(current + 1);
        }
    };

    return (
        <Container className="moodboards">
            <Row>
                <Col>
                    <Carousel
                        className="moodboards-selector"
                        controls={false}
                        indicators={false}
                        fade={true}
                        interval={null}
                        activeIndex={current}
                        onSelect={() => {}} // required by Carousel component if using activeIndex
                    >
                        {options.map(({ text, picture, key, reply_options }) => (
                            <Carousel.Item key={key}>
                                <Container className="moodboard" fluid>
                                    <Row>
                                        <Col>
                                            <p className="question">
                                                {props.text.replace(
                                                    '%style%',
                                                    text.charAt(0).toUpperCase() + text.slice(1)
                                                )}
                                            </p>
                                            <Image src={picture} />
                                        </Col>
                                    </Row>
                                    <Row className="answers">
                                        {reply_options.map((answer) => (
                                            <Col
                                                className="no-padding"
                                                key={`${key}_${answer.key}`}
                                            >
                                                <RadioButton
                                                    direction="vertical"
                                                    id={`${key}_${answer.key}`}
                                                    value={answer.key}
                                                    isChecked={reply[key] === answer.key}
                                                    onClick={() => onSelect(answer.key)}
                                                    label={answer.text}
                                                />
                                            </Col>
                                        ))}
                                    </Row>
                                </Container>
                            </Carousel.Item>
                        ))}
                    </Carousel>
                </Col>
            </Row>
        </Container>
    );
};

export default Moodboards;
