import './style.scss';

import React, { useEffect, useState } from 'react';
import { Badge, Button, Col, Form, Modal } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';

import { modal } from 'content.json';
import { Validator } from 'services';
import { useUIStore } from 'store/ui/reducer';
import { useUserStore } from 'store/user/reducer';
import { useErrorStore } from 'store/error/reducer';
import { login } from 'store/user/actions';
import { changeModal } from 'store/ui/actions';
import { useWithDispatch, useIsMobile } from 'hooks';
import { useLDClient, withLDConsumer } from 'launchdarkly-react-client-sdk';
import { extractUserData } from '../../../services/utils/user-utils';
import { resetError } from 'store/error/actions';
import { Client } from 'types/user';
import { setMixpanelPeopleData } from 'services/mixpanel';

const Signin: React.FC = () => {
    const history = useHistory();
    const [domain, setDomain] = useState('');
    const [remember, setRemember] = useState(false);
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [errors, setErrors] = useState<{ email?: string; password?: string } | null>(null);
    const texts = modal.signin;
    const user = useUserStore((store) => store.user);
    const redirectUrl = useUIStore((store) => store.redirectUrl);
    const errorsStore = useErrorStore((store) => store.errors);
    const loading = useUIStore((store) => store.indicators.loading);
    const isMobile = useIsMobile();

    const loginAction = useWithDispatch(login);
    const changeModalAction = useWithDispatch(changeModal);
    const resetErrorAction = useWithDispatch(resetError);
    const ldClient = useLDClient();

    useEffect(() => {
        resetErrorAction();
        const previousLDUser = ldClient?.getUser();
        if (domain) {
            const index = email.indexOf('@');
            setEmail(`${email.substr(0, index > -1 ? index : email.length)}${domain}`);
            setDomain('');
        }
        if (
            user &&
            previousLDUser &&
            Object.keys(user)?.length > 0 &&
            user.hasOwnProperty('email')
        ) {
            const userDetails = extractUserData(user as Client);
            if (userDetails && ldClient) ldClient.alias(userDetails, previousLDUser);
            history.push(redirectUrl ? redirectUrl : getDefaultRedirectUrl());
        }
    }, [domain, email, user, redirectUrl, ldClient]);

    const getDefaultRedirectUrl = () => {
        switch (history.location.pathname) {
            case '/feed':
                return '/feed';
            default:
                return isMobile && user?.type === 'stylist' ? '/' : '/inbox';
        }
    };

    const validate = () => {
        let errors = {
            ...Validator.email(email),
            ...Validator.password(password)
        };
        if (Object.keys(errors).length) {
            setErrors(errors);
            return false;
        } else {
            setErrors({});
            return true;
        }
    };
    return (
        <span className="signin-modal">
            <Modal.Header closeButton />

            <Modal.Body>
                <Form
                    noValidate
                    onSubmit={async (e: React.FormEvent<HTMLFormElement>) => {
                        e.preventDefault();
                        if (validate()) {
                            const loggedInUser = await loginAction(
                                {
                                    email,
                                    password,
                                    remember
                                },
                                'form'
                            );
                            setMixpanelPeopleData(loggedInUser);
                            const userDetails = extractUserData(loggedInUser as Client);
                            if (ldClient && userDetails)
                                await ldClient.identify(userDetails, undefined);
                        }
                    }}
                >
                    <Form.Row>
                        <Form.Group as={Col} controlId="email">
                            <Form.Label column={false}>{texts.email}</Form.Label>
                            <Form.Control
                                data-test-id="login-email"
                                type="email"
                                value={email}
                                onChange={(e: React.FormEvent<HTMLInputElement>) => {
                                    // @ts-ignore
                                    setEmail(e.target.value);
                                }}
                                required
                            />
                            {errors?.email && (
                                <Form.Control.Feedback type="invalid">
                                    {errors.email}
                                </Form.Control.Feedback>
                            )}
                            {errorsStore[0]?.field === 'email' && (
                                <Form.Control.Feedback type="invalid">
                                    {errorsStore[0]?.message}
                                </Form.Control.Feedback>
                            )}
                        </Form.Group>
                    </Form.Row>

                    <Form.Row className="email-domains">
                        <Badge variant="secondary" onClick={() => setDomain('@gmail.com')}>
                            @gmail.com
                        </Badge>
                        <Badge variant="secondary" onClick={() => setDomain('@yahoo.com')}>
                            @yahoo.com
                        </Badge>
                        <Badge variant="secondary" onClick={() => setDomain('@hotmail.com')}>
                            @hotmail.com
                        </Badge>
                        <Badge variant="secondary" onClick={() => setDomain('@icloud.com')}>
                            @icloud.com
                        </Badge>
                    </Form.Row>

                    <Form.Row>
                        <Form.Group as={Col} controlId="password">
                            <Form.Label column={false}>{texts.password}</Form.Label>
                            <Form.Control
                                data-test-id="login-password"
                                type="password"
                                // @ts-ignore
                                onChange={(e) => setPassword(e.target.value)}
                                required
                            />
                            {errors?.password && (
                                <Form.Control.Feedback type="invalid">
                                    {errors.password}
                                </Form.Control.Feedback>
                            )}
                            {errorsStore && errorsStore[0]?.field === 'password' && (
                                <Form.Control.Feedback type="invalid">
                                    {errorsStore[0]?.message}
                                </Form.Control.Feedback>
                            )}
                        </Form.Group>
                    </Form.Row>

                    <Form.Row>
                        <Form.Group as={Col} controlId="rememberMe">
                            <Form.Check
                                label={texts.remember}
                                // @ts-ignore
                                onChange={(e) => setRemember(e.target.checked)}
                            />
                        </Form.Group>

                        <Form.Group as={Col} controlId="forgot" className="forgot-pass">
                            <strong
                                onClick={() =>
                                    changeModalAction({ type: 'ResetPassword', data: {} })
                                }
                            >
                                {texts.forgot}
                            </strong>
                        </Form.Group>
                    </Form.Row>

                    <Form.Row>
                        <Button
                            variant="dark"
                            id="submit"
                            type="submit"
                            className="submit-btn"
                            disabled={loading}
                            data-test-id="login-button"
                        >
                            {texts.signin}
                        </Button>
                    </Form.Row>
                </Form>
            </Modal.Body>

            <Modal.Footer>
                <Form.Text className="join">
                    {texts.account}{' '}
                    <strong onClick={() => changeModalAction({ type: 'Signup', data: {} })}>
                        {texts.join}
                    </strong>
                </Form.Text>
            </Modal.Footer>
        </span>
    );
};

export default withLDConsumer()(Signin);
