import '../style.scss';

import { Elements, useStripe } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import React, { useEffect, useState } from 'react';
import { Button, Col, Container, Form, Image, Row } from 'react-bootstrap';

import { payment } from '../../../content';
import { Payments } from '../../../services';
import { PaymentRequestButton, RadioButton, StickyButton } from '../../';

const stripePromise = loadStripe(Payments.getStripeKey());

const MobileCheckout = ({
    stylist,
    plan,
    model,
    goal,
    coupon,
    cards,
    onSelect,
    pay,
    updatePaymentToken,
    price,
    campaign,
    submitCoupon,
    clearCoupon,
    toggleMobileCollapse,
    mobileCollapse,
    error,
    loading
}) => {
    const stripe = useStripe();
    const [showCode, setShowCode] = useState(false);
    const [cardError, setCardError] = useState(null);
    const [code, setCode] = useState('');
    const [couponError, setCouponError] = useState(null);
    const [showStripeButton, setShowStripeButton] = useState(false);

    useEffect(() => {
        window.scrollTo(0, 0);
        if (
            goal.value !== 'closet_cleanout' &&
            plan.value !== 'lux' &&
            stylist &&
            !stylist.is_celebrity &&
            !campaign
        ) {
            submitCoupon('monthly-session');
        } else {
            clearCoupon();
        }
    }, []);

    useEffect(() => {
        if (showStripeButton) {
            setShowStripeButton(!cards.length);
        }
    }, [cards]);

    useEffect(() => {
        setCardError(
            error && error.payment
                ? error.payment.message
                    ? error.payment.message
                    : payment.details.errors.invalid
                : null
        );
        setCouponError(error && error.coupon ? payment.summary.errors.invalidCode : null);

        if (coupon.percent_off || coupon.discount) {
            setCode('');
            setShowCode(false);
        }
        if (coupon.total === 0) {
            setShowStripeButton(false);
        }
    }, [error, coupon]);

    const submitCode = async () => {
        submitCoupon(code);
    };

    const toggleCode = () => {
        if (coupon.percent_off || coupon.discount) {
            setCouponError(null);
            clearCoupon();
            setCode('');
            setShowCode(false);
        } else {
            if (
                model === 'monthly' &&
                goal.value !== 'closet_cleanout' &&
                !stylist.is_celebrity &&
                !campaign
            ) {
                setCouponError(payment.summary.errors.onetimeOnly);
            } else {
                window.scrollTo(0, 0);
                setCouponError(null);
                clearCoupon();
                setCode('');
                toggleMobileCollapse('coupon');
            }
        }
    };

    const onModelChange = ({ target: { name } }) => {
        if (name === 'monthly') setShowCode(false);
        onSelect(name);
    };
    return (
        <Container className={`mobile-checkout ${mobileCollapse ? 'hide' : ''}`} fluid>
            {goal.value !== 'closet_cleanout' &&
                plan.value !== 'lux' &&
                !stylist.is_celebrity &&
                !campaign && (
                    <Row>
                        <Col>
                            <p className="package-title">
                                {payment.summary.package.replace('%plan%', plan.title)}
                            </p>
                            <RadioButton
                                type="dark"
                                fill={false}
                                key="monthly"
                                id="monthly"
                                value={plan.price.monthly}
                                isChecked={model === 'monthly'}
                                onClick={onModelChange}
                                label={
                                    <span
                                        dangerouslySetInnerHTML={{
                                            __html: payment.summary.subscriptions.monthly.replace(
                                                '%plan_charge%',
                                                plan.price.monthly
                                            )
                                        }}
                                    />
                                }
                            />
                            <RadioButton
                                type="dark"
                                fill={false}
                                key="onetime"
                                id="onetime"
                                value={plan.price.onetime}
                                isChecked={model === 'onetime'}
                                onClick={onModelChange}
                                label={
                                    <span
                                        dangerouslySetInnerHTML={{
                                            __html: payment.summary.subscriptions.onetime
                                                .replace('%plan_charge%', plan.price.onetime)
                                                .replace('%plan%', plan.title)
                                        }}
                                    />
                                }
                            />
                        </Col>
                    </Row>
                )}

            <hr />

            <Row className="price-summary">
                <Col>
                    {payment.summary.package.replace(
                        '%plan%',
                        `${
                            goal.value === 'closet_cleanout'
                                ? payment.summary.cleanout
                                : `Wishi ${plan.title}`
                        }`
                    )}
                </Col>
                <Col xs={3}>${coupon.subtotal || plan.price[model]}</Col>
            </Row>
            <Row className={`price-summary ${coupon.discount ? 'show' : 'hide'}`}>
                <Col>{coupon.description}</Col>
                <Col xs={4}>-${coupon.discount}</Col>
            </Row>
            <Row className="price-summary bold">
                <Col>
                    {model === 'monthly' &&
                    goal.value !== 'closet_cleanout' &&
                    !stylist.is_celebrity &&
                    !campaign
                        ? payment.summary.totalMonthly
                        : payment.summary.total}
                </Col>
                <Col xs={3}>${coupon.total !== undefined ? coupon.total : plan.price[model]}</Col>
            </Row>
            {model === 'monthly' &&
                goal.value !== 'closet_cleanout' &&
                !stylist.is_celebrity &&
                !campaign && (
                    <Row className="price-summary bold">
                        <Col>{payment.summary.due}</Col>
                        <Col xs={3}>$0</Col>
                    </Row>
                )}

            <hr />

            <Row
                className="payment-card"
                onClick={() => {
                    if (cards.length && showStripeButton) {
                        setShowStripeButton(false);
                    }
                    window.scrollTo(0, 0);
                    toggleMobileCollapse('card');
                }}
            >
                {!showStripeButton ? (
                    cards.length ? (
                        <>
                            <Col xs={6}>{payment.mobile.paymentCard}</Col>
                            <Col xs={6} className="card-number">
                                <Image src={Payments.paymentCardType(payment, cards)} />
                                {cards[0].last4}
                            </Col>
                        </>
                    ) : (
                        <Col>{payment.mobile.add}</Col>
                    )
                ) : (
                    <Col>{payment.mobile.creditcard}</Col>
                )}
            </Row>
            {cardError && (
                <Row>
                    <Col className="error">
                        {cardError ? cardError : payment.details.errors.invalid}
                    </Col>
                </Row>
            )}

            <hr />

            <a className="add-code" onClick={toggleCode}>
                {coupon.percent_off || coupon.discount
                    ? payment.summary.remove
                    : payment.summary.add}
            </a>
            <Row className={`promotion-code ${showCode ? 'show' : 'hide'}`}>
                <Col>
                    <Form.Control
                        type="text"
                        value={code}
                        onChange={(e) => setCode(e.target.value)}
                    />
                    <Button onClick={submitCode}>{payment.summary.submit}</Button>
                </Col>
            </Row>
            <Row>
                <Col className="error">{couponError}</Col>
            </Row>

            {!showStripeButton && (
                <StickyButton
                    onClick={
                        cards.length || coupon.percent_off === 100
                            ? pay
                            : () => {
                                  window.scrollTo(0, 0);
                                  toggleMobileCollapse('card');
                              }
                    }
                    text={
                        cards.length || coupon.percent_off === 100
                            ? payment.details.form.buttonComplete
                            : payment.mobile.add
                    }
                />
            )}
            {!loading && (
                <PaymentRequestButton
                    show={showStripeButton}
                    stripe={stripe}
                    price={coupon.total !== undefined ? coupon.total : price}
                    onShow={() => setShowStripeButton(!cards.length)}
                    onSuccess={(token) => {
                        updatePaymentToken(token);
                        pay();
                    }}
                />
            )}
        </Container>
    );
};

export default (props) => (
    <Elements stripe={stripePromise}>
        <MobileCheckout {...props} />
    </Elements>
);
