import './style.scss';

import { landing } from 'content.json';
import React from 'react';
import { Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
interface IStylingButton {
    user?: any;
    text?: string;
    className?: string;
}
const StylingButton: React.FC<IStylingButton> = ({ user, text, className }) => (
    <Link
        to={{
            pathname:
                user && user.hasOwnProperty('email') == true ? '/stylistSearch' : '/onboarding',
            state: { fromHome: true }
        }}
        className={`styling-button ${className}`}
    >
        <Button variant="dark" id="styling-button" className={className}>
            {text ? text : landing.button.text}
        </Button>
    </Link>
);

export default StylingButton;
