import { connect } from 'react-redux';
import { toggleModal } from 'store/ui/actions';
import { updateGeneralData, updateProfilePicture, updateUser } from 'store/user/actions';

import {
    getUserCards,
    updatePaymentMethod,
    getPaymentHistory
} from '../../../redux/reducers/payments/actions';
import History from './History';
import Info from './Info';
import Loyalty from './Loyalty';
import Main from './Main';
import Membership from './Membership';
import Method from './Method';
import Orders from './Orders';
import Password from './Password';
import Style from './Style';

const mapStateToProps = ({
    users: { loading, orders },
    payments: { cards, error, paymentHistory },
    user: { user }
}) => ({
    loading,
    user,
    orders,
    cards,
    error,
    paymentHistory
});

const mapDispatchToProps = (dispatch) => ({
    toggleModal: (data) => toggleModal(dispatch, data),
    getCards: () => dispatch(getUserCards()),
    updatePaymentMethod: (token) => dispatch(updatePaymentMethod(token)),
    updateUser: (user) => dispatch(updateUser(user)),
    getPaymentHistory: () => dispatch(getPaymentHistory()),
    updateProfilePicture: (files, { user_uuid, token }) =>
        updateProfilePicture(dispatch, files, { user_uuid, token }),
    updateGeneralData: (data) => updateGeneralData(dispatch, data)
});

export default {
    main: connect(mapStateToProps, mapDispatchToProps)(Main),
    info: connect(mapStateToProps, mapDispatchToProps)(Info),
    membership: connect(mapStateToProps, mapDispatchToProps)(Membership),
    password: connect(mapStateToProps, mapDispatchToProps)(Password),
    history: connect(mapStateToProps, mapDispatchToProps)(History),
    method: connect(mapStateToProps, mapDispatchToProps)(Method),
    orders: connect(mapStateToProps, mapDispatchToProps)(Orders),
    loyalty: connect(mapStateToProps, mapDispatchToProps)(Loyalty),
    style: connect(mapStateToProps, mapDispatchToProps)(Style)
};
