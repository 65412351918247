import './style.scss';

import React from 'react';
import { Container } from 'react-bootstrap';

import { BirthdayMessage, Page } from '../../components';
import { landing, logo } from '../../content';
import { Brands, Clients, Faq, Header, Karla, Plans, Press, Steps, Testimonials } from './sections';

const Landing = ({ setLandingType }) => {
    setLandingType('shoppers');
    return (
        <Page messageText={landing.covid}>
            <BirthdayMessage />
            <Container className="landing" fluid>
                <Header data={{ ...landing.header, logo }} />
                <Karla data={landing.karla} />
                <Press data={landing.press} />
                <Steps data={landing.steps} />
                <Plans data={landing.plans} />
                <Brands data={landing.brands} />
                <Testimonials data={landing.testimonials} />
                <Clients data={landing.clients} />
                <Faq data={landing.faq} />
            </Container>
        </Page>
    );
};

export default Landing;
