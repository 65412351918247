import './style.scss';

import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';

import { giftcards } from '../../../content';

const Summary = ({ giftcard }) => (
    <Container className="summary">
        <Row>
            <Col>
                <p>
                    {giftcards.checkout.for
                        .replace('%name%', giftcard.recipientName)
                        .replace('%email%', giftcard.recipientEmail)}
                </p>
                <p>
                    {giftcards.checkout.currency}
                    {giftcard.amount}
                </p>
            </Col>
        </Row>
        <Row className="total">
            <Col>
                <p>{giftcards.checkout.total}</p>
                <p>
                    {giftcards.checkout.currency}
                    {giftcard.amount}
                </p>
            </Col>
        </Row>
    </Container>
);

export default Summary;
