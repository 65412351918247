import axios from 'axios';
import Config from './Config';
import { getUserToken } from './utils/token-utils';

const Style = {
    urls: {
        getMatches: (user_id) => `${Config.get('apiroot2')}user/${user_id}/bestMatchesNew`
    },
    getMoodBoards: (gender) =>
        axios.get(`${Config.get('apiroot2')}user/onboarding/moodboards`, {
            params: {
                type: 'body',
                gender
            },
            headers: { token: getUserToken() }
        }),
    getBrands: (gender, styles) =>
        axios.get(`${Config.get('apiroot2')}user/onboarding/brands`, {
            params: {
                gender,
                styles
            },
            headers: { token: getUserToken() }
        }),
    getBodyTypes: (gender) =>
        axios.get(`${Config.get('apiroot2')}tag/active`, {
            params: {
                type: 'body',
                gender
            },
            headers: { token: getUserToken() }
        }),
    updateStyle: (user_id, style) =>
        axios.put(
            `${Config.get('apiroot2')}user/${user_id}/stylingPreferences`,
            { ...style },
            { headers: { token: getUserToken() } }
        ),
    getMatches: (user_id) =>
        axios.get(`${Config.get('apiroot2')}user/${user_id}/bestMatchesNew`, {
            headers: { token: getUserToken() }
        }),
    // getResultsCount: params => axios.get(`${Config.get('apiroot2')}stylist/filters`, { params }),
    getFeed: (params) =>
        axios.get(`${Config.get('apiroot2')}feed/all`, {
            params: { ...params, types: 'styled_you' }
        }),
    getOutfit: (outfit_uuid, uuid = 'null') =>
        axios.get(`${Config.get('apiroot2')}outfit/web/${outfit_uuid}/${uuid}`),
    getOutfits: (user_uuid, stylist_uuid) =>
        axios.get(`${Config.get('apiroot2')}outfit/list/web/paging/${user_uuid}/${stylist_uuid}`, {
            params: { outfits: 1, from: 1, count: 50 },
            headers: { token: getUserToken() }
        }),
    getItem: (item_uuid, user_uuid) =>
        axios.get(`${Config.get('apiroot2')}item/catalog/${item_uuid}`, { params: { user_uuid } }),
    getSizes: (item_uuid) =>
        axios.get(`${Config.get('apiroot2')}item/${item_uuid}/availableSizes`, {
            headers: { token: getUserToken() }
        }),
    filters: () => axios.get(`${Config.get('apiroot2')}giftItem/filters?client=web`),
    giftItems: (filters) => axios.get(`${Config.get('apiroot2')}giftItem`, { params: filters }),
    getRequest: (request_uuid) =>
        axios.get(`${Config.get('apiroot2')}styleRequest/retrieve/${request_uuid}`, {
            headers: { token: getUserToken() }
        }),
    tags: (gender, type) =>
        axios.get(`${Config.get('apiroot2')}tag/active`, {
            params: { gender, type },
            headers: { token: getUserToken() }
        }),
    events: () =>
        axios.get(`${Config.get('apiroot2')}event`, { headers: { token: getUserToken() } })
};

export default Style;
