import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { setMixpanelStore } from 'store/common/actions';
import { toggleModal } from 'store/ui/actions';

import {
    nextStep,
    prevStep,
    prevStyle,
    resetOnboarding,
    selectGender,
    selectStyle,
    setStep
} from '../../redux/reducers/onboarding_legacy/actions';
import OnBoarding from './OnBoarding';

const mapStateToProps = ({
    onboarding_legacy: { steps, current, gender, bodyTypes, style, brands, styles, styleIndex },
    common: { mixpanelStore }
}) => ({
    steps,
    current,
    gender,
    bodyTypes,
    style,
    brands,
    styles,
    styleIndex,
    mixpanelStore
});

const mapDispatchToProps = (dispatch) => ({
    selectGender: (gender) => dispatch(selectGender(gender)),
    selectStyle: (style, answer, index) => dispatch(selectStyle(style, answer, index)),
    nextStep: () => dispatch(nextStep()),
    prevStep: () => dispatch(prevStep()),
    setStep: (step) => dispatch(setStep(step)),
    prevStyle: (style) => dispatch(prevStyle(style)),
    resetOnboarding: () => dispatch(resetOnboarding()),
    toggleModal: (data) => toggleModal(dispatch, data),
    setMixpanelStore: (data) => setMixpanelStore(dispatch, data)
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(OnBoarding));
