import '../style.scss';

import React, { useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';

export default ({ onChange, checked = false, value = '', className = '', label }) => {
    const [check, setCheck] = useState(false);

    useEffect(() => {
        setCheck(checked);
    }, [checked]);

    const onChecked = (e) => {
        setCheck(e.target.checked);
        onChange({ text: value });
    };

    return (
        <Form.Group>
            <input
                type="checkbox"
                id={`checkbox-${value}`}
                className={`checkbox ${className}`}
                onChange={onChecked}
                value={value}
                checked={check}
            />
            {label && (
                <label className="checkbox-label" htmlFor={`checkbox-${value}`}>
                    {label}
                </label>
            )}
        </Form.Group>
    );
};
