import './style.scss';

import { useIsMobile } from 'hooks';
import React from 'react';
import { Button, Col, Container, Image, Row } from 'react-bootstrap';
import { Link, useHistory } from 'react-router-dom';

import { shopping } from '../../content';
import { Formatter } from '../../services';

export default ({ loading, item, removeFromCart, addToCloset }) => {
    const history = useHistory();
    const isMobile = useIsMobile();
    const priceFormatter = (price) => Formatter.price(price);

    const goToItem = () => history.push(`/shopping-list/item/${item.originalItemUnique}`);

    return (
        <Container className="cart-item">
            <Row>
                <Col xs={6} md={2} className="image-col">
                    <Image src={item.picture} onClick={goToItem} />
                </Col>
                <Col xs={6}>
                    <p className="brand" onClick={() => (isMobile ? goToItem() : null)}>
                        {item.brand_name}
                    </p>
                    {item.name && (
                        <p
                            className="name d-none d-sm-block"
                            onClick={() => (isMobile ? goToItem() : null)}
                        >
                            {item.name}
                        </p>
                    )}
                    <div className="prices" onClick={goToItem}>
                        {item.retail_price && (
                            <p
                                className={`price${
                                    parseInt(item.sale_price) < parseInt(item.retail_price)
                                        ? ' line-through'
                                        : ''
                                }`}
                            >
                                {priceFormatter(item.retail_price)}
                            </p>
                        )}
                        {item.sale_price &&
                            parseInt(item.sale_price) < parseInt(item.retail_price) && (
                                <>
                                    <p className="price">{priceFormatter(item.sale_price)}</p>
                                    <p className="price discount">
                                        {shopping.discount.replace(
                                            '%price%',
                                            priceFormatter(
                                                parseInt(item.retail_price) -
                                                    parseInt(item.sale_price)
                                            )
                                        )}
                                    </p>
                                </>
                            )}
                    </div>
                    <Button
                        className="d-block d-md-none"
                        as={Link}
                        variant="dark"
                        to={`/shopping-list/item/${item.originalItemUnique}`}
                    >
                        {shopping.shop}
                    </Button>
                    <p
                        className={`link bold ${item.is_in_closet ? 'in-closet' : ''}`}
                        onClick={() => addToCloset(item)}
                    >
                        {item.is_in_closet ? shopping.inCloset : shopping.addCloset}
                    </p>
                </Col>
                <Col className="buttons d-none d-md-flex">
                    <Button
                        as={Link}
                        variant="dark"
                        to={`/shopping-list/item/${item.originalItemUnique}`}
                    >
                        {shopping.shop}
                    </Button>
                </Col>
                <div className="close" onClick={() => !loading && removeFromCart(item)} />
            </Row>
        </Container>
    );
};
