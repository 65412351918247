import './style.scss';

import React, { useEffect } from 'react';
import { Container } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';

import { MobileSideCollapse, Page, Payment } from '../../components';
import { GoalsHeader } from './';

const GoalsCheckout = ({ stylist, plan, goal, mobileCollapse, clearModel }) => {
    const history = useHistory();
    useEffect(() => {
        return () => {
            clearModel();
        };
    }, []);

    const onBack = () => {
        history.goBack();
    };

    return (
        <Page footer={false} header={false} fixedHeader={false}>
            <Container className={`goals-checkout ${mobileCollapse ? 'side-collapse-open' : ''}`}>
                <MobileSideCollapse />
                <GoalsHeader onBack={onBack} />
                {plan && <Payment />}
            </Container>
        </Page>
    );
};

export default GoalsCheckout;
