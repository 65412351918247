import './style.scss';

import React from 'react';
import { Col, Container, Image, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { trackEvent, isMatchedStylist, MIXPANEL_EVENTS } from 'services/mixpanel';
import { feed } from '../../content';

const FeedMatch = ({ match, additionalData }) => {
    return (
        <Container className="feed-match" fluid>
            <Row>
                <Col>
                    <Image src={match.profile_picture} />
                </Col>
            </Row>
            <Row>
                <Col>
                    <p className="name">{match.name}</p>
                    <p className="location">{match.location}</p>
                    {match.match_percentage && (
                        <p className="percentage">
                            {feed.matches.percentage.replace(
                                '%percentage%',
                                match.match_percentage
                            )}
                        </p>
                    )}
                    <Link
                        to={{
                            pathname: `/stylist/${match.uuid}/profile`,
                            state: { from: window.location.pathname }
                        }}
                        onClick={() => {
                            trackEvent({
                                name: MIXPANEL_EVENTS.MEET_STYLIST,
                                properties: {
                                    ...additionalData?.mixpanelStore?.onboarding,
                                    'Stylist name': match.first_name.concat(' ', match.last_name),
                                    'Stylist UUID': match.uuid,
                                    'Is stylist match': isMatchedStylist(
                                        additionalData.matches,
                                        match.uuid
                                    ),
                                    'Booking source': 'feed'
                                }
                            });
                        }}
                    >
                        {feed.matches.meet.replace('%stylist%', match.first_name)}
                    </Link>
                </Col>
            </Row>
        </Container>
    );
};
export default FeedMatch;
