import React, { useEffect } from 'react';
import { Button, Col, Row } from 'react-bootstrap';

import { RadioButton, StickyButton } from '../../../components';
import Style from '../../../services/Style';
import {
    trackEvent,
    registerProperties,
    MIXPANEL_EVENTS,
    MIXPANEL_PROPERTIES
} from 'services/mixpanel';

const BodyType = ({
    gender,
    setBodyTypeList,
    data: { question, button },
    bodyTypeList,
    bodyTypes,
    bodyTypesNames,
    selectBodyType,
    nextStep
}) => {
    useEffect(() => {
        getTypes();
        trackEvent({
            name: MIXPANEL_EVENTS.ONBOARDING_BODY_TYPE_VIEWS,
            properties: {}
        });
    }, []);

    const getTypes = async () => {
        const { data } = await Style.getBodyTypes(gender);
        setBodyTypeList(data);
    };

    return (
        <Row className="body-type">
            <Col className="content">
                <p className="question d-none d-lg-flex">{question.web}</p>
                <p className="question d-flex d-lg-none">{question.mobile}</p>
                <div className="types">
                    {bodyTypeList.map((type) => (
                        <RadioButton
                            key={type.uuid}
                            id={`bodyTypeCheckbox_${type.uuid}`}
                            value={type.uuid}
                            isChecked={bodyTypes[type.uuid] === 1}
                            onClick={() => selectBodyType(type)}
                            label={type.name}
                        />
                    ))}
                </div>
                <Button
                    variant="warning"
                    id="skip"
                    className="d-none d-sm-flex"
                    onClick={() => {
                        if (Object.keys(bodyTypes).length) {
                            trackEvent({
                                name: MIXPANEL_EVENTS.ONBOARDING_BODY_TYPE_SELECTION,
                                properties: { [MIXPANEL_PROPERTIES.BODY_TYPE]: bodyTypesNames }
                            });
                            registerProperties({ [MIXPANEL_PROPERTIES.BODY_TYPE]: bodyTypesNames });
                        }
                        return nextStep();
                    }}
                >
                    {Object.keys(bodyTypes).length ? button.next : button.skip}
                </Button>
                <StickyButton
                    className="d-flex d-sm-none"
                    id="skip"
                    onClick={nextStep}
                    text={Object.keys(bodyTypes).length ? button.next : button.skip}
                    type="warning"
                />
            </Col>
        </Row>
    );
};

export default BodyType;
