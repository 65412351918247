import './style.scss';

import { faHeart as faHeartEmpty } from '@fortawesome/free-regular-svg-icons';
import { faHeart as faHeartFull } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';

import { favorites } from '../../content';

const FavoriteButton = ({
    expandable = false,
    isFavorite = false,
    filled = false,
    onClick,
    user,
    toggleModal
}) => {
    return (
        <div
            className={`favorite-button ${isFavorite ? 'on' : 'off'} ${
                expandable ? 'expandable' : ''
            } ${filled ? 'filled' : ''}`}
            onClick={() => (user ? onClick() : toggleModal({ type: 'Signup' }))}
        >
            <FontAwesomeIcon
                icon={isFavorite ? faHeartFull : filled ? faHeartFull : faHeartEmpty}
            />
            {expandable && <p>{isFavorite ? favorites.added : favorites.add}</p>}
        </div>
    );
};

export default FavoriteButton;
