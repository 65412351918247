import { useIsMobile } from 'hooks';
import React, { useMemo } from 'react';
import { Button, Col, Container, Nav, Navbar, Row } from 'react-bootstrap';
import { Link, useHistory } from 'react-router-dom';
import { MIXPANEL_EVENTS, trackEvent } from 'services/mixpanel';
import { sentryException } from 'services/SentryLogging';
import { getUserToken } from 'services/utils/token-utils';
import { useCommonStore } from 'store/common/reducer';

import { ProfilePhoto } from '../../../components';
import { stylist as texts } from '../../../content';
import { Cookies, Formatter, Tracking } from '../../../services';
import { useInboxStore } from 'routes/Inbox/store/reducer';
import { hasSubscription } from 'services/utils/user-utils';

const BookBar = ({
    stylist,
    user,
    toggleModal,
    bookStylist,
    loading,
    location,
    onContactStylist
}) => {
    const history = useHistory();
    const isMobile = useIsMobile();
    const isActiveStylist =
        loading || (stylist.uuid && stylist.is_approved === '1' && stylist.is_active === true);
    const isUserEqualToStylist = user?.user_uuid === stylist.uuid;
    const showBookStylistButton = stylist && isActiveStylist && !isUserEqualToStylist;
    const showContactStylistButton = stylist && !isActiveStylist && !isUserEqualToStylist;
    const showGiftButton = isActiveStylist || isUserEqualToStylist;
    const mixpanelStore = useCommonStore((store) => store.mixpanelStore);

    const messagesByStylistId = useInboxStore((store) =>
        store.messages.reduce((obj, message) => ({ ...obj, [message.stylist.uuid]: message }), {})
    );

    const hasActiveSubscription =
        stylist.uuid in messagesByStylistId && messagesByStylistId[stylist.uuid].isSubscription;

    const onButtonClick = async () => {
        if (user) {
            if (user.user_uuid === stylist.uuid) {
                if (isMobile) {
                    window.open(
                        'https://apps.apple.com/us/app/styling-app-for-wishi-stylists/id1244526037'
                    );
                } else {
                    window.location = '/app/stylistProfile/settings/steps/basicInfo';
                }
            } else {
                if (stylist.has_active_session) {
                    history.push(`/chat/${stylist.uuid}/${user.user_uuid}`);
                } else {
                    Tracking.facebook('trackCustom', 'selectStylist', {
                        id: stylist.uuid,
                        content_ids: [stylist.uuid],
                        content_type: 'product'
                    });
                    Tracking.google({
                        type: 'event',
                        event: 'Funnel',
                        data: { event_category: 'selectStylist' }
                    });
                    Tracking.google({
                        type: 'event',
                        event: 'conversion',
                        data: { send_to: 'AW-870964131/1rVICOWwnKcBEKO3p58D' }
                    });
                    Tracking.tag({ event: 'ws.selectStylist' });
                    try {
                        await Tracking.report(
                            {
                                event_type: 'clicked_book_CTA',
                                user_uuid: user.user_uuid,
                                stylist_uuid: stylist.uuid
                            },
                            getUserToken()
                        );
                    } catch (e) {
                        sentryException(e, "Couldn't process tracking request");
                    }

                    Cookies.set('stylistInfo', {
                        book_stylist_out_of_profile: true,
                        first_name: stylist.first_name,
                        last_name: stylist.last_name,
                        picture_small: stylist.picture_small,
                        price: stylist.price,
                        price_subscription: stylist.price_subscription,
                        user_uuid: stylist.user_uuid ? stylist.user_uuid : stylist.uuid,
                        reviews_count: stylist.reviews_count,
                        instagram_followers: stylist.instagram_followers,
                        instagram_photos: stylist.instagram_photos,
                        clients: stylist.clients,
                        style_tags: stylist.style_tags,
                        top_outfits_count: stylist.top_outfits_count,
                        city: stylist.city,
                        average_response_time: stylist.average_response_time
                    });
                    bookStylist(stylist);
                    trackEvent({
                        name: MIXPANEL_EVENTS.BOOK_STYLIST,
                        properties: {
                            ...mixpanelStore.onboarding
                        }
                    });
                    history.push('/goals');
                }
            }
        } else {
            Cookies.set('stylistInfo', {
                book_stylist_out_of_profile: true,
                first_name: stylist.first_name,
                last_name: stylist.last_name,
                picture_small: stylist.picture_small,
                price: stylist.price,
                price_subscription: stylist.price_subscription,
                user_uuid: stylist.user_uuid ? stylist.user_uuid : stylist.uuid,
                reviews_count: stylist.reviews_count,
                instagram_followers: stylist.instagram_followers,
                instagram_photos: stylist.instagram_photos,
                clients: stylist.clients,
                style_tags: stylist.style_tags,
                top_outfits_count: stylist.top_outfits_count,
                city: stylist.city,
                average_response_time: stylist.average_response_time
            });
            bookStylist(stylist);
            trackEvent({
                name: MIXPANEL_EVENTS.BOOK_STYLIST,
                properties: {
                    ...mixpanelStore.onboarding
                }
            });
            toggleModal({
                type:
                    Formatter.queryString(location.search).user_rmk === 'true'
                        ? 'Signin'
                        : 'Signup',
                url: '/goals'
            });
        }
    };

    return (
        <Navbar className="book-bar" fixed="bottom" expand={false}>
            <Container className="no-padding">
                <Nav className="d-none d-md-flex">
                    <Container>
                        <Row>
                            <Col className="profile-image">
                                <ProfilePhoto user={stylist} />
                            </Col>
                            {stylist && (
                                <Col className="stylist-details">
                                    <div className="name">{stylist.name}</div>
                                    <div className="response-time">
                                        {' '}
                                        {texts.response.replace(
                                            '%time%',
                                            stylist.average_response_time
                                        )}
                                    </div>
                                </Col>
                            )}
                        </Row>
                    </Container>
                </Nav>
                <Nav className="justify-content-end booking-btn-container">
                    {showBookStylistButton && (
                        <Button variant="warning" id="book" onClick={onButtonClick}>
                            {stylist.has_active_session || hasActiveSubscription ? (
                                <span>{texts.session}</span>
                            ) : (
                                <span>
                                    {texts.book} {stylist.first_name}
                                </span>
                            )}
                        </Button>
                    )}

                    {showContactStylistButton && (
                        <Button variant="warning" id="contact" onClick={onContactStylist}>
                            {texts.contact.replace('%stylist%', stylist.first_name)}
                        </Button>
                    )}

                    <div className="d-block d-md-none or">{texts.or}</div>

                    {showGiftButton && (
                        <Link to="/gift-cards/checkout" className="gift-card-btn" id="gift-card">
                            {texts.gift}
                        </Link>
                    )}
                </Nav>
            </Container>
        </Navbar>
    );
};

export default BookBar;
