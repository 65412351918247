import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';

import { RadioButton } from '../../../components';
import { trackEvent, registerProperties, MIXPANEL_EVENTS } from 'services/mixpanel';

const Gender = ({ data: { question, answers }, gender, selectGender, updateUser }) => {
    trackEvent({ name: MIXPANEL_EVENTS.ONBOARDING_GENDER_VIEWS, properties: {} });
    return (
        <Row className="gender">
            <Col className="content">
                <p className="question">{question}</p>

                <Container>
                    <Row>
                        {answers.map(({ value, label }) => (
                            <RadioButton
                                key={value}
                                id="genderCheckbox"
                                value={value}
                                isChecked={gender === value}
                                onClick={(e) => {
                                    registerProperties({ Gender: e.target.id });
                                    trackEvent({
                                        name: MIXPANEL_EVENTS.ONBOARDING_GENDER_SELECTION,
                                        properties: { Gender: e.target.id }
                                    });
                                    selectGender(e.target.id);
                                    updateUser({ gender: e.target.id });
                                }}
                                label={label}
                            />
                        ))}
                    </Row>
                </Container>
            </Col>
        </Row>
    );
};

export default Gender;
