import _ from 'lodash';

import { Session } from '../../../services';

export const SET_STEP = 'SET_STEP';

export const SET_GENDER = 'SET_GENDER';

export const SET_STYLES_LIST = 'SET_STYLES_LIST';
export const SET_STYLES = 'SET_STYLES';
export const NEXT_STYLE = 'NEXT_STYLE';
export const PREV_STYLE = 'PREV_STYLE';

export const SET_BRANDS_LIST = 'SET_BRANDS_LIST';
export const SET_BRANDS = 'SET_BRANDS';

export const SET_BODY_TYPES_LIST = 'SET_BODY_TYPES_LIST';
export const SET_BODY_TYPES = 'SET_BODY_TYPES';

export const RESET_ONBOARDING = 'RESET_ONBOARDING';

export const setStep = (step) => ({
    type: SET_STEP,
    payload: { step }
});

export const setGender = (gender) => ({
    type: SET_GENDER,
    payload: { gender }
});

export const setStylesList = (stylesList) => ({
    type: SET_STYLES_LIST,
    payload: { stylesList }
});

export const setStyles = (styles) => ({
    type: SET_STYLES,
    payload: { styles }
});

export const nextStyle = (current) => ({
    type: NEXT_STYLE,
    payload: { current }
});

export const prevStyle = (current) => ({
    type: PREV_STYLE,
    payload: { current }
});

export const setBrandsList = (brands) => ({
    type: SET_BRANDS_LIST,
    payload: { brands }
});

export const setBrands = (brands) => ({
    type: SET_BRANDS,
    payload: { brands }
});

export const setBodyTypeList = (bodyTypeList) => ({
    type: SET_BODY_TYPES_LIST,
    payload: { bodyTypeList }
});

export const setBodyTypes = (bodyTypes) => ({
    type: SET_BODY_TYPES,
    payload: { bodyTypes }
});

export const resetOnboarding = () => ({
    type: RESET_ONBOARDING
});

export const nextStep = () => {
    return async (dispatch, getState) => {
        const {
            onboarding: { current, gender, steps }
        } = getState();
        const step =
            current < steps.length
                ? gender === 'MALE' && current === 1
                    ? current + 2
                    : current + 1
                : current;

        Session.set('onboarding', { step });
        dispatch(setStep(step));
    };
};

export const prevStep = () => {
    return async (dispatch, getState) => {
        const {
            onboarding: { current, gender }
        } = getState();
        const step =
            current > 1
                ? gender === 'MALE' && current === 3
                    ? current - 2
                    : current - 1
                : current;

        Session.set('onboarding', { step });

        dispatch(setStep(step));
    };
};

export const selectGender = (gender) => {
    return async (dispatch) => {
        dispatch(setGender(gender.toLowerCase()));
        Session.set('onboarding', {
            gender,
            styles: []
        });

        dispatch(nextStep());
    };
};

export const selectBodyType = (bodyType) => {
    return async (dispatch, getState) => {
        let _bodyTypes = [...getState().onboarding.bodyTypes];
        const bodyTypeExist = _bodyTypes.find((item) => item.tag_uuid === bodyType.uuid);

        if (bodyTypeExist) {
            _bodyTypes = _bodyTypes.filter((item) => item.tag_uuid !== bodyType.uuid);
        } else {
            _bodyTypes.push({
                tag_uuid: bodyType.uuid,
                is_set: 1,
                name: bodyType.name
            });
        }
        dispatch(setBodyTypes(_bodyTypes));

        Session.set('onboarding', {
            bodyTypes: _bodyTypes
        });
    };
};

export const selectStyle = (style) => {
    return async (dispatch, getState) => {
        const {
            onboarding: { stylesList, styles, styleIndex }
        } = getState();
        let _styles = [...styles];
        const styleExistIndex = _.findIndex(styles, (stl) => stl.name === style.name);

        if (styleExistIndex >= 0) {
            _styles[styleExistIndex] = style;
        } else {
            _styles.push(style);
        }
        dispatch(setStyles(_styles));

        Session.set('onboarding', {
            styles: _styles
        });

        if (styleIndex < stylesList.length - 1) {
            dispatch(nextStyle(styleIndex));
        } else {
            dispatch(nextStep());
        }
    };
};

export const selectBrand = (brand) => {
    return async (dispatch, getState) => {
        let _brands = [...getState().onboarding.brands];
        const brandExist = _brands.find((item) => item.uuid === brand.uuid);

        if (brandExist) {
            _brands = _brands.filter((item) => item.uuid !== brand.uuid);
        } else {
            _brands.push(brand);
        }
        dispatch(setBrands(_brands));
        Session.set('onboarding', {
            brands: _brands
        });
    };
};
