import './style.scss';

import React, { useEffect, useState } from 'react';
import { Button, Card, Col, Container, Row } from 'react-bootstrap';

import { Loader, ProfilePhoto } from '../../../components';
import { settings as texts } from '../../../content.json';
import { Formatter, Session as SessionSrv, User } from '../../../services';
import Breadcrumbs from './Breadcrumbs';
import { SystemUser, SessionInfo } from 'types/user';
import { registerProperties } from 'services/mixpanel';
import { MIXPANEL_PROPERTIES } from 'services/mixpanel';
import { sentryException } from 'services/SentryLogging';

interface IMembership {
    user: SystemUser;
}

interface ISession {
    session: SessionInfo;
    active: boolean;
    periodEnd: string;
    onClick: () => void;
}

const Membership: React.FC<IMembership> = ({ user }) => {
    const [loading, setLoading] = useState(false);
    const [sessions, setSessions] = useState<SessionInfo[]>([]);

    useEffect(() => {
        if (user) getSessions();
    }, [user]);

    const getSessions = async () => {
        try {
            setLoading(true);
            const { data } = await User.sessions(user.user_uuid);
            setSessions(data.items);
            setLoading(false);
        } catch (e: any) {
            setLoading(false);
            sentryException(e as Error, 'Cannot retrieve sessions data');
        }
    };

    const onButtonClick = async (session: SessionInfo, index: number) => {
        try {
            const sessionAction =
                isActive(session.status) && !session.cancel_at_period_end ? 'cancel' : 'reactivate';
            setLoading(true);
            const { data } = await SessionSrv[sessionAction](session.uuid);
            const items = [...sessions];
            items[index] = data.data;
            registerProperties({
                [MIXPANEL_PROPERTIES.ACTIVE_SUBSCRIPTION]: sessionAction === 'cancel' ? 'No' : 'Yes'
            });
            setSessions(items);
            setLoading(false);
        } catch (e: any) {
            setLoading(false);
            sentryException(e as Error, "Couldn't complete session action");
        }
    };

    const isActive = (status: string) => status === 'active' || status === 'trialing';

    const extractPeriodEnd = (periodEnd: string) => {
        const date = periodEnd.split(' ')[0];
        return date.split('-')[1] + ' /' + date.split('-')[2];
    };

    return (
        <Container className="settings-membership" fluid>
            {loading && <Loader />}
            <Row>
                <Col className="header">
                    <Breadcrumbs />
                    <label>{texts.membership.title}</label>
                    {!loading && user && (
                        <p>
                            {!sessions.length
                                ? texts.membership.empty.replace('%name%', user.first_name || '')
                                : texts.membership.description.replace(
                                      '%name%',
                                      user.first_name || ''
                                  )}
                        </p>
                    )}
                </Col>
            </Row>
            <Row>
                {sessions.map((session, index) => (
                    <Col key={session.uuid} xs={12} md={4} className="session">
                        <Session
                            session={session}
                            active={isActive(session.status)}
                            onClick={() => onButtonClick(session, index)}
                            periodEnd={extractPeriodEnd(session.period_end)}
                        />
                    </Col>
                ))}
            </Row>
        </Container>
    );
};

const Session: React.FC<ISession> = ({ session, active = true, periodEnd = '', onClick }) => (
    <Card>
        {!active && !session.cancel_at_period_end && (
            <div className="inactive">{texts.membership.inactive}</div>
        )}
        {session.cancel_at_period_end && (
            <div className="inactive">{texts.membership.periodEnd + periodEnd}</div>
        )}
        <Card.Header>
            <ProfilePhoto user={session.stylist} />
            <p>{session.stylist.name}</p>
        </Card.Header>
        <Card.Body>
            <Container className="session-info" fluid>
                <Row>
                    <Col>{texts.membership.plan}</Col>
                    <Col>{session.plan.name}</Col>
                </Row>
                <Row>
                    <Col>{texts.membership.payment}</Col>
                    <Col>{session.plan.amount || 'N/A'}</Col>
                </Row>
                <Row>
                    <Col>{texts.membership.date}</Col>
                    <Col>{Formatter.time(session.last_paid_at, 'MM/DD/YYYY')}</Col>
                </Row>
                <Row>
                    <Col>{texts.membership.method}</Col>
                    <Col>
                        {session.status === 'trialing'
                            ? texts.membership.trialing
                            : session.payment_method.last4
                            ? `*${session.payment_method.last4}`
                            : 'N/A'}
                    </Col>
                </Row>
            </Container>
        </Card.Body>
        <Card.Footer>
            <Button
                variant={active && !session.cancel_at_period_end ? 'dark' : 'warning'}
                onClick={onClick}
            >
                {
                    texts.membership[
                        active && !session.cancel_at_period_end ? 'deactivate' : 'reactivate'
                    ]
                }
            </Button>
        </Card.Footer>
        {session.status === 'trialing' && <div className="trial">{texts.membership.trial}</div>}
    </Card>
);

export default Membership;
